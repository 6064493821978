import React, { useCallback, useEffect, useState, memo, useRef, useMemo } from 'react';
import cls from './ModuleStep.module.scss';
import { add, closeSearch, closeWhite, editBlack, nextStepDownArrow, processImg, reset2, roundPath, step } from '../../../assets/images/icons';
import { duplicateIcon, importXLSIco } from '../../../assets/images/new-icons';
import { connect } from 'react-redux';
import { Spinner, po, PermissionWrapper, RenderYesNoSwitcher, ContainerWrapper, ModalR } from '../../common';
import { addNewFieldToComponent, assignComponent, createComponent, deleteComponent, getComponentById, updateComponent, updateFieldToComponent } from '../../../services/components.service';
import { updateProcessStepByJson } from '../../../services/processes.service';
import { createProduct } from '../../../services/products.service';
import { getCompanyDetails, setCompanyOptions } from '../../../services/company.service';
import { store } from '../../../reduxStore/store';
import { handleChangeProcessModule, setProcessModule, setLastUpdate, setSelectedProcess, setAllComponents, setCart, setMessageBar, setUsersArray } from '../../../reduxStore/actions/flowActions';
import { first, take } from 'rxjs/operators';
import { processTaskAction, useProcessTaskContext } from '../../../context/processTask.context';
import { WidthProvider, Responsive } from 'react-grid-layout';
import StaticAccordionModule, { StaticAccordionCreator, StaticAccordionPayments } from './StaticAccordionModule';
import ModuleLists from './ModuleLists';
import AccordionModule from '../ModulesComp/AccordionModule';
import AccordionProduct from '../ModulesComp/AccordionProduct';
import CustomTemplateModule, { RenderCustomComponentDetailView } from '../ModulesComp/CustomTemplateModule';
import Button from '../../common/UI/Button/Button';
import MessageContainer from '../ProcessesComps/MessageContainer/MessageContainer';
import ReactToPrint from 'react-to-print';
import SelectAndMultiselect from '../ProcessesComps/SelectAndMultiselect/SelectAndMultiselect';
import GeneralCard from '../../common/GeneralCard/GeneralCard';
import ProcessStepsChart from '../BlueprintsComps/ProcessesTab/ProcessStepsChart';
import CustomTemplateModuleList from './CustomTemplateModuleList';
import { APPEND_TO_DATA, CLEAR_TO_SET_DATA, app_max, computeFormula, generateNewUUID, isUserAuthorized } from '../../../shared/utility';
import { TheProcessContext } from '../../../context/processStep.context';
import { isMobile } from 'react-device-detect';
import { MultiSelectWithCheckboxesAndNestedItems_v2 } from '../../common/UI/Select/MultiSelectWithCheckboxesAndNestedItems';
import { getAlComponentsByModuleId } from '../../../services/module.service';

import * as R from 'ramda';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import moment, { now } from 'moment';
import { utils, writeFile } from 'xlsx';
import CheckoutPage from '../CheckoutPage/CheckoutPage';
import { useNavigate } from 'react-router-dom';
import { purchase } from '../../../services/payments.service';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getFilteredUsers, updateUserOptionsById } from '../../../services/user.service';
import VideoConference from '../../common/VideoConference/VideoConference';

function ModuleStep(props) {
	const { navigateTo, begunModule, allModules, companyData, components, isEditing, lastUpdates, companyUsersArray,
		products, onSetSelectedProcess, children, authenticatedUser, companyPositionsArray, cart, blueprints, paymentID, companyProcesses } = props;

	const [currentStepIndex] = useState(1);
	const [isNextStepVisible, setIsNextStepVisible] = useState(false);
	const [isMandatoryError, setIsMandatoryError] = useState(false);
	const [message, setMessage] = useState('');
	const [nextButtonClicked, setNextButtonClicked] = useState(false);
	const [taskProcessContext, setTaskProcessContext] = useProcessTaskContext();
	const [isOpen, setIsOpen] = useState(false);
	const [isUpdating, setIsUpdating] = useState(begunModule?.processModule?.isUpdating == true ? true : false);
	const [selectedComponent, setSelectedComponent] = useState(begunModule?.processModule?.selectedComponent ?? {});
	const [isCompleted, setIsCompleted] = useState(selectedComponent?.is_complete ?? true);
	const [users, setUsers] = useState([]);
	const [positions, setPositions] = useState([]);
	const [forPrinting, setForPrinting] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showProcess, setShowProcess] = useState(false);
	const [showDeleteComponent, setShowDeleteComponent] = useState(false);
	const [showEmailModal, setShowEmailModal] = useState(false);
	const [dependenciesFound, setDependenciesFound] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [displaySave, setDisplaySave] = useState(false);
	const [showReports, setShowReports] = useState(false);
	const [searchedArray, setSearchedArray] = useState([]);
	const [filteredArray, setFilteredArray] = useState([]);
	const [searchFilters, setSearchFilters] = useState([]);
	const [headers, setHeaders] = useState({ list: [], operator: 'AND' });
	const [usersArray, setUsersArr] = useState([]);
	const [emailList, setEmailList] = useState([]);
	const [editMode, setEditMode] = useState(false);
	const [showPayments, setShowPayments] = useState(false);
	const [restore, setRestore] = useState(false);

	const navigate = useNavigate();

	const currentStep = begunModule?.processModule?.steps?.length > 0 ? begunModule?.processModule?.steps[begunModule.processModule.stepIndex] : {};
	const pathwaySteps = begunModule?.processModule?.steps?.length > 0 ? begunModule.processModule.steps[begunModule.processModule.stepIndex]?.pathway_steps : [];
	const pathwayProcesses = begunModule?.processModule?.steps?.length > 0 ? begunModule.processModule.steps[begunModule.processModule.stepIndex]?.pathway_processes : [];

	let componentRef = useRef();

	useEffect(() => {
		let arr = companyUsersArray
			.filter(user => user.departments.length == 0)
			.map(user => {
				return { label: `${user.first_name} ${user.last_name}`, value: user.id, department: 'General', user: user };
			});
		companyUsersArray
			.filter(user => user.departments.length > 0)
			.filter(user => {
				user.departments.filter(department => {
					arr = [...arr, { label: `${user.first_name} ${user.last_name}`, value: user.id, department: department?.name, user: user }];
				});
			});

		setUsersArr(arr);
	}, [companyUsersArray]);

	useEffect(() => {
		if (begunModule?.processModule?.steps?.length > 0 && !showReports) {
			setHeaders({ list: [], operator: 'AND' });

			navigateTo?.key !== undefined ? setSearchedArray(searchFunction(begunModule?.processModule?.module?.id, components, navigateTo.value, navigateTo.key)) : setSearchedArray(components.filter(c => c.module_id === begunModule?.processModule?.module?.id));

			let procedure = { id: '' };
			let componentBlueprints = [];
			let selectedFields = [];

			const updateCmp = (comp) => {
				comp.component_fields.map(cf => {
					if (comp.procedure_id !== process?.id) {
						procedure = companyProcesses.find(proc => proc.id === comp.procedure_id);
						if (procedure?.steps) {
							componentBlueprints = R.pipe(R.pluck('component_blueprints'), R.flatten)(procedure?.steps);
							selectedFields = R.pipe(R.pluck('selected_fields'), R.flatten)(componentBlueprints);
						}
					}
					const sf = selectedFields.find(sf => sf.procedure_step_blueprint_field_id === cf.procedure_step_blueprint_field_id)
					if (sf) {
						cf.data_type_name = sf.data_type_name;
						cf.data_type = sf.data_type;
						cf.field_name = sf.field_name;
						cf.options = sf.options;
						cf.component_blueprint_id = componentBlueprints.find(cb => cb.selected_fields.filter(sf => sf.procedure_step_blueprint_field_id === cf.procedure_step_blueprint_field_id).length > 0)?.id
					}
				})
			}
			R.map(updateCmp, components.filter(co => (co?.component_fields ?? []).filter(cf => cf?.field_name === undefined).length > 0));
		}
	}, [begunModule?.processModule?.steps, components]);

	useEffect(() => {
		if (paymentID) {
			const field = selectedComponent.component_fields?.find(sf => sf.data_type_name === 'Payments');
			if (field) {
				const found = selectedComponent.component_fields?.find(sf => sf.field_name === field.options.paymentField);
				if (found) {
					setLoading(true);
					purchase({
						user_id: authenticatedUser.id,
						id: selectedComponent.id,
						total: +found?.value ?? 0,
						product: begunModule?.processModule?.module?.name,
						notes: paymentID,
						quantity: 1,
					})
						.pipe(first())
						.subscribe({
							next: () => {
								setLoading(false);
								setDisplaySave(begunModule?.processModule?.module?.options?.message?.length > 0);
							},
						});
				}
			}
		}
	}, [paymentID]);

	useEffect(() => {
		if (begunModule?.processModule?.steps?.length > 0 && !showReports && (navigateTo.key !== undefined || navigateTo.key !== '')) {
			setSearchedArray(searchFunction(begunModule?.processModule?.module?.id, components, navigateTo.value));
		}
	}, [navigateTo]);


	useEffect(() => {
		if (localStorage.getItem('transaction') && !isOpen) {

			if (localStorage.getItem('transaction') === selectedComponent.id) {

				setRestore(true);
			}
		}
	}, [selectedComponent, isOpen]);

	const checkIfAreMandatoryErrored = useCallback(() => {
		let areMandatoryErrors = false;
		if (
			currentStep.component_blueprints
				.filter(cb => {
					if (cb.options === null) {
						return true;
					}
					return cb.options?.allowMultipleInputs === false || cb.options?.allowMultipleInputs === undefined;
				})
				.some(
					el =>
						true ===
						el.selected_fields
							?.filter(sf => sf.data_type_name !== 'Profile' && sf.data_type_name !== 'ID' && sf.data_type_name !== 'Info' && sf.data_type_name !== 'Notes' && sf.data_type_name !== 'URL' && sf.data_type_name !== 'Emails' && sf.data_type_name !== 'Template' && sf.data_type_name !== 'Global' && sf.data_type_name !== 'Chart')
							.some(elem => elem.mandatory === true && elem.visible === true && (!elem.value || elem?.value?.length < 1))
				)
		) {
			areMandatoryErrors = true;
		}

		if (areMandatoryErrors === true) {
			setIsMandatoryError(true);
			return true;
		} else {
			setMessage('');
			setIsMandatoryError(false);
			return false;
		}
	}, [currentStep]);

	const clickedNextButton = id => {
		setNextButtonClicked(true);
		if (checkIfAreMandatoryErrored() && isUpdating === false) {
			checkErrors();
		} else goToNextStep(id);
	};

	const clickedAddButton = id => {
		taskProcessContext.components = [];
		taskProcessContext.items = [];
		taskProcessContext.currentStep = {};
		taskProcessContext.newStepComponents = [];

		begunModule.processModule.steps.filter(step => step.component_blueprints.filter(cb => cb.selected_fields.filter(field => (field.value = undefined))));
		begunModule.processModule.isUpdating = false;
		setIsUpdating(false);
		setIsOpen(false);
		setNextButtonClicked(true);
		goToNextStep(id);

		companyData.start = false;
		getCompanyDetails()
			.pipe(first())
			.subscribe({
				next: () => { },
			});
	};

	const clickedEditButton = id => {
		taskProcessContext.components = [];
		taskProcessContext.items = [];
		taskProcessContext.currentStep = {};
		taskProcessContext.newStepComponents = [];

		begunModule.processModule.steps.filter(step => step.component_blueprints.filter(cb => cb.selected_fields.filter(field => (field.value = undefined))));
		begunModule.processModule.isUpdating = false;
		setIsUpdating(false);
		setIsOpen(false);
		setNextButtonClicked(true);
		goToNextStep(id);

		companyData.start = true;
		setEditMode(true);
	};

	const goToNextStep = (id, back = false) => {
		setNextButtonClicked(false);
		setIsMandatoryError(false);
		setIsNextStepVisible(false);

		let step = {};
		if (id === undefined) {
			step = begunModule.processModule.steps[begunModule.processModule.stepIndex + 1];
			if (step !== undefined) {
				setTaskProcessContext({ action: processTaskAction.setCurrentStep, value: step });
				store.dispatch(
					setProcessModule({
						...begunModule.processModule,
						stepIndex: begunModule.processModule.stepIndex + 1,
						back_id: back ? undefined : taskProcessContext?.currentStep?.id,
					})
				);
			} else {
				store.dispatch(setMessageBar({ show: true, message: 'Misconfigured next step, please contact your administrator', color: 'red', bottom: true }));
				return;
			}
		} else {
			step = begunModule.processModule.steps.find(step => step.id === id);

			if (step == undefined) {
				step = begunModule.processModule.steps[begunModule.processModule.stepIndex + 1];

				store.dispatch(
					setProcessModule({
						...begunModule.processModule,
						stepIndex: begunModule.processModule.stepIndex + 1,
						back_id: back ? undefined : taskProcessContext?.currentStep?.id,
					})
				);
			} else {
				const st = begunModule.processModule.steps[step?.rank - 1];

				if (st !== undefined) {
					store.dispatch(
						setProcessModule({
							...begunModule.processModule,
							stepIndex: step?.rank - 1,
							back_id: back ? undefined : taskProcessContext?.currentStep?.id,
						})
					);
				} else {
					store.dispatch(setMessageBar({ show: true, message: 'Misconfigured next step, please contact your administrator', color: 'red', bottom: true }));
					return;
				}
			}
			setTaskProcessContext({ action: processTaskAction.setCurrentStep, value: step });

		}

		const newStepComponents = taskProcessContext.newStepComponents.filter(c => {
			if (c.options === null) {
				return true;
			}
			if (c.allowMultipleInputs === undefined) {
				return true;
			}

			return c.options.allowMultipleInputs === false;
		});

		setTaskProcessContext({
			action: processTaskAction.setComponents,
			value: [...taskProcessContext.components.filter(c1 => newStepComponents.every(c2 => c2.id !== c1.id)), ...R.reject(c => c.selected_fields.filter(field => field.value === '').length > 0, newStepComponents)],
		});

		setTaskProcessContext({
			action: processTaskAction.setModuleNewStepComponents,
			value: step.component_blueprints.filter(cb => cb.selected_fields.filter(sf => sf.data_type_name === 'Status' || sf.data_type_name === 'ID' || sf.data_type_name === 'Value').length > 0),
		});
	};
	const closeDetailView = () => {
		setTaskProcessContext({
			action: processTaskAction.setModuleItems,
			value: [],
		});

		setTaskProcessContext({
			action: processTaskAction.setComponents,
			value: [],
		});

		setTaskProcessContext({
			action: processTaskAction.addModuleComponents,
			value: [],
		});

		setTaskProcessContext({
			action: processTaskAction.setModuleNewStepComponents,
			value: [],
		});
		clickedAddButton(begunModule.processModule.steps[0].id);
	};

	const saveModuleOutputs = () => {
		if (checkIfAreMandatoryErrored()) {
			setMessage('There are mandatory fields to be completed!');
			window.scrollTo({ top: 0, behavior: 'smooth' });
			setIsNextStepVisible(false);
			return;
		}

		const newStepComponents = taskProcessContext.newStepComponents.filter(c => {
			if (c.options === null) {
				return true;
			}
			return c.options?.allowMultipleInputs === false || c.options?.allowMultipleInputs === undefined;
		});

		const components_to_process = [...taskProcessContext.components.filter(c1 => newStepComponents.every(c2 => c2.id !== c1.id)), ...R.reject(c => c.selected_fields.filter(field => field.value === '').length > 0, newStepComponents)];
		let updateCompany = [];
		let selected_fields = [];
		let componentV = '';
		let email_recipients = '';
		let trigger_field_based_email = false;
		let found_email;
		let status = isUpdating ? selectedComponent?.status : 'None';
		let assigned_to;
		const previous_status = selectedComponent?.status;

		const comObj = {};

		let new_group = isUpdating ? (R.apply(Math.max, R.pluck('group', R.uniqWith(R.eqProps('group'))(selectedComponent?.component_fields ?? []))) ?? -1) + 1 : 0;

		components_to_process.map((x, index) => {
			let group = index;

			const f1 = x.selected_fields.filter(sf => sf.data_type_name === 'Record' || sf.data_type_name === 'Filters')?.find(sf => +sf?.group >= 0);
			if (f1 && isUpdating) {
				group = f1.group;
			} else if (isUpdating) {
				group = new_group;
				new_group++;
			}
			/* Make sure components are retrieved first */
			x.selected_fields
				.filter(sf => sf.data_type_name === 'Record' || sf.data_type_name === 'Filters')
				.map((y, idx) => {
					if (!(y.value === null || y.value === undefined || y.options?.skip == true)) {
						selected_fields = [...selected_fields, { ...y, index: idx, group: group }];
						componentV = y.value;
						comObj[y.field_name] = y.value;
						const found = components.find(com => com.component_fields.filter(cf => cf.component_blueprint_id === y?.options?.component_id && cf?.value === componentV).length > 0)?.component_fields.find(cf => cf.data_type_name === 'Email Recipient')?.value;
						if (found) {
							found_email = found;
						}
					}
				});

			const f2 = x.selected_fields.filter(sf => !(sf.data_type_name === 'Record' || sf.data_type_name === 'Filters'))?.find(sf => +sf?.group >= 0);
			if (f2 && isUpdating) {
				group = f2.group;
			} else if (isUpdating) {
				group = new_group;
				new_group++;
			}
			x.selected_fields
				.filter(sf => !(sf.data_type_name === 'Record' || sf.data_type_name === 'Filters'))
				.map((y, idx) => {
					if (!(y.value === null || y.value === undefined || y.options?.skip == true)) {
						comObj[y.field_name] = y.value;
						if (y?.data_type_name === 'ID') {
							if (!begunModule?.processModule?.isUpdating) {
								selected_fields = [...selected_fields, { ...y, index: idx, group: group, value: `${y?.options.id}${companyData?.misc_info === null ? 1 : companyData?.misc_info[y?.field_name] ?? 1}` }];
								updateCompany = [...updateCompany, `${y.field_name}`];
							} else {
								selected_fields = [...selected_fields, { ...y, index: idx, group: group }];
							}
						} else if (y?.data_type_name === 'Value') {
							selected_fields = [...selected_fields, { ...y, index: idx, value: y?.options?.value, group: group }];
						} else if (y?.data_type_name === 'Email Recipient') {
							selected_fields = [...selected_fields, { ...y, index: idx, value: y.value.replace(/ /g, ''), group: group }];
						} else if (y.data_type_name === 'Product Lookup') {
							selected_fields = [...selected_fields];
						} else if (y.data_type_name === 'Status' && y.value === 'Draft' && isCompleted == true) {
							trigger_field_based_email = true;
							selected_fields = [...selected_fields, { ...y, index: idx, value: 'New', group: group }];
							status = 'New';
						} else if (y.data_type_name === 'Status') {
							selected_fields = [...selected_fields, { ...y, index: idx, value: y?.value, group: group }];
							status = y?.value;
						} else if (y.data_type_name === 'Formula') {
							const value = begunModule?.processModule?.isUpdating
								? computeFormula(
									y?.options,
									selectedComponent.component_fields.filter(x => y.options?.list.includes(x.field_name)),
									components_to_process,
									taskProcessContext.items
								)
								: y.value;
							selected_fields = [...selected_fields, { ...y, index: idx, value: value.toString(), group: group }];
						} else if (y.data_type !== 'output') {
							if (y.data_type_name === 'People') {
								if (y.options?.email === true) {
									const em = usersArray.find(usr => usr.label === y?.value)?.user?.email;
									email_recipients = em ? email_recipients.concat(email_recipients.length > 0 ? `,${em}` : em) : email_recipients;
									y.email = undefined;
								}
								if (y.options?.assign === true) {
									assigned_to = usersArray.find(usr => usr.label === y?.value)?.user?.id;
									if (isUpdating)
										selectedComponent.assigned_to = { id: assigned_to };
								}
							}
							if (y?.options?.actionToPerform2 === 'email' && y?.options?.valueToMatch2?.split(',')?.includes(y?.value)) {
								if (found_email) {
									trigger_field_based_email = true;
									email_recipients = email_recipients.concat(email_recipients.length > 0 ? `,${found_email}` : found_email);
								}
							}
							selected_fields = [...selected_fields, { ...y, index: idx, group: group }];
						}
					} else if (y?.data_type_name === 'Status') {
						if (isCompleted == true) {
							selected_fields = [...selected_fields, { ...y, index: idx, value: 'New', group: group }];
							status = 'New';
						} else {
							selected_fields = [...selected_fields, { ...y, index: idx, value: 'Draft', group: group }];
							status = 'Draft';
						}

						comObj[y.field_name] = y.value;
					} else if (y?.data_type_name === 'Action Button') {
						selected_fields = [...selected_fields];
						comObj[y.field_name] = y.value;
						if (y?.options?.action === 'status') {
							status = y.value;
						} else if (y.options?.action === 'user' || y.options?.action === 'creator') {
							selectedComponent.assigned_to = y?.value;
						}
					}
				});
		});

		let title = '';

		if (begunModule.processModule?.module?.options?.title?.length > 0) {
			begunModule.processModule?.module?.options?.title?.map(label => {
				if (label !== 'Info') {
					title = title === '' ? `${selected_fields.find(sf => sf.field_name === label)?.value ?? ''}` : `${title ?? ''}  ${selected_fields.find(sf => sf.field_name === label)?.value ?? ''}`;
				} else {
					const step = begunModule.processModule.steps.find(step => step.component_blueprints.filter(cb => cb.selected_fields.filter(sf => sf.data_type_name === 'Info')?.length > 0)?.length > 0);

					if (step !== undefined) {
						const infoField = step.component_blueprints?.find(cb => cb.selected_fields.filter(sf => sf.data_type_name === 'Info')?.length > 0).selected_fields?.find(sf => sf.data_type_name === 'Info');
						const found = components.find(c => c.component_fields.filter(cf => cf.component_blueprint_id === infoField.options.component_id && (cf.value === comObj[infoField.field_name] || cf.value === comObj[infoField.options?.key]))?.length > 0);
						if (found) {
							if (infoField?.options?.list?.length > 0) {
								title = title === '' ? `${found.component_fields.find(cf => cf.field_name === infoField?.options?.list[0])?.value ?? ''}` : `${title ?? ''}  ${found.component_fields.find(cf => cf.field_name === infoField?.options?.list[0])?.value ?? ''}`;
							}
						}

					}

				}
			});
			if (begunModule.processModule?.module?.options?.title.find(t => t === 'UUID')) {
				title = `${isUpdating ? selectedComponent.id : generateNewUUID()}`;
			}
		} else {
			title = isUpdating ? selectedComponent.id : generateNewUUID();
		}

		let target;
		let info;
		let template;
		let options = {};

		if (begunModule.processModule?.module?.options?.emailOutputs === true) {
			let step = begunModule.processModule.steps.find(step => step.component_blueprints.filter(cb => cb.selected_fields.filter(sf => sf.data_type_name === 'Info' && sf.options.email == true)?.length > 0)?.length > 0);
			if (step !== undefined) {
				const infoField = step.component_blueprints?.find(cb => cb.selected_fields.filter(sf => sf.data_type_name === 'Info' && sf?.options?.email == true)?.length > 0).selected_fields?.find(sf => sf.data_type_name === 'Info' && sf?.options?.email === true);
				const found = components.find(c => c.component_fields.filter(cf => cf.component_blueprint_id === infoField.options.component_id && cf.value === componentV)?.length > 0);
				if (found) {
					info = infoField.id;
					target = found.id;
				}
			}

			step = begunModule.processModule.steps.find(step => step.is_end === true && step.component_blueprints.filter(cb => cb.selected_fields.filter(sf => sf.data_type_name === 'Emails')?.length > 0)?.length > 0);
			if (step !== undefined) {
				const emailsField = step.component_blueprints?.find(cb => cb.selected_fields.filter(sf => sf.data_type_name === 'Emails')?.length > 0);
				emailsField.selected_fields
					.filter(sf => sf.data_type_name === 'Emails')
					.filter(field => {
						if (selected_fields.find(sf => sf.value === field.options.valueToMatch && sf.field_name === field.options.fieldToMatch)) {
							if (found_email) {
								template = field;
								trigger_field_based_email = true;
								if (field.options?.copyEmailRecip === true) {
									email_recipients = email_recipients.concat(email_recipients.length > 0 ? `,${found_email}` : found_email);
								}
							}
						}
					});
			}

			email_recipients = email_recipients.split(',') ?? [];

			if (emailList.length > 0 || email_recipients.length > 0) {
				if (isUpdating) {
					if (trigger_field_based_email === true) {
						email_recipients = [...emailList, ...email_recipients];
					} else {
						email_recipients = [...emailList];
					}
				} else {
					email_recipients = [...emailList, ...email_recipients];
				}
			}

			if (email_recipients.length > 0) {
				options = { emailTo: email_recipients };
			}

			if (info && target) {
				options = {
					...options,
					info: info,
					target: target,
				};
			}

			if (template?.id.length > 0) {
				options = {
					...options,
					template: template?.id,
				};
			}
		}

		let rewardsField;

		if (begunModule.processModule?.module?.options?.rewards === true && previous_status !== status) {
			const step = begunModule.processModule.steps.find(step => step.component_blueprints.filter(cb => cb.selected_fields.filter(sf => sf.data_type_name === 'Rewards')?.length > 0)?.length > 0);
			if (step !== undefined) {
				rewardsField = step.component_blueprints?.find(cb => cb.selected_fields.filter(sf => sf.data_type_name === 'Rewards')?.length > 0).selected_fields?.find(sf => sf.data_type_name === 'Rewards');
				if (rewardsField.options.status === status) {
					authenticatedUser.options.rewards = +(authenticatedUser?.options?.rewards ?? 0) + +(rewardsField?.options?.points);
				}
			}
		}

		if (begunModule.processModule?.module?.options?.outputTo === 'product' && !isUpdating) {
			if (taskProcessContext?.newProduct?.name && taskProcessContext?.newProduct?.product_code) {
				createProduct(taskProcessContext?.newProduct?.name, taskProcessContext?.newProduct?.product_code).subscribe({
					error: error => { },
					next: () => {
						setLoading(true);
						store.dispatch(setLastUpdate({ ...lastUpdates, products: 0 }));
					},
					complete: () => { },
				});
			}
		}
		if (selected_fields?.length > 0) {
			setLoading(true);
			if (isUpdating) {
				selectedComponent.options = options;
				selectedComponent.status = status;
				updateComponent({
					...selectedComponent,
					selected_fields: [...selected_fields],
					component_items: taskProcessContext.items
				},
					selectedComponent.id,
					users,
					title,
					isCompleted,
					positions,
				).subscribe({
					error: error => {
						store.dispatch(setMessageBar({ show: true, message: `Errors found`, color: 'red' }));
					},
					next: (_data) => {

						if (begunModule.processModule?.module?.options?.single) {
							localStorage.setItem(begunModule.processModule?.module.id, JSON.stringify(_data?.data));
							goToNextStep(begunModule.processModule.steps[0]?.id)
							return;
						}

						const payments = selected_fields.find(sf => sf.data_type_name === 'Payments');
						const total = selected_fields.find(sf => sf.field_name === payments?.options?.paymentField);
						if (total && (payments?.value === 'Credit Card' || payments?.value === 'Bank Transfers' || payments?.value === 'E-wallet' || payments?.value === 'Wallet')
							&& status === companyData.misc_info?.lock && authenticatedUser.id === selectedComponent?.assigned_to?.id && previous_status !== status) {

							const credit = (+total?.value) - ((+total?.value) * (+(companyData?.misc_info?.rate ?? 0.2)));
							purchase({
								user_id: authenticatedUser.id,
								id: generateNewUUID(),
								total: (credit),
								product: `User credit`,
								notes: `${title}`,
								quantity: 1,
							})
								.pipe(first())
								.subscribe({
									next: () => {
										updateUserOptionsById(authenticatedUser, { ...authenticatedUser?.options, credit: +(+(authenticatedUser?.options?.credit ?? 0) + (credit)) })
											.pipe(first())
											.subscribe({
												next: data => {
													authenticatedUser.options = data.options;
													closeDetailView();
												},
											});
									},
								});

						} else {
							if (rewardsField) {
								updateUserOptionsById(authenticatedUser, authenticatedUser?.options)
									.pipe(first())
									.subscribe({
										next: () => {

											closeDetailView();
										},
										error: () => store.dispatch(setMessageBar({ show: true, message: `Errors found`, color: 'red' }))
									});
							} else {
								closeDetailView();
							}
						}

					},
					complete: () => {
						setLoading(false);
					},
				});
			} else {
				createComponent(
					{
						id: components_to_process[0].id,
						selected_fields: [...selected_fields],
						component_items: taskProcessContext.items,
						options: options,
						status: status,
						assigned_to: assigned_to
					},
					'',
					begunModule.processModule.module_id,
					users,
					title,
					isCompleted,
					positions,
				).subscribe({
					next: _data => {

						if (begunModule.processModule?.module?.options?.single) {
							localStorage.setItem(begunModule.processModule?.module.id, JSON.stringify(_data));
							goToNextStep(begunModule.processModule.steps[0]?.id);
							return;
						}

						if (updateCompany.length > 0) {
							updateCompanyData(updateCompany);
						}
						begunModule.processModule.selectedComponent = _data;
						if (selected_fields.filter(sf => (sf?.value === 'Credit Card' || sf?.value === 'Bank Transfers' || sf?.value === 'E-wallet') && sf.data_type_name === 'Payments').length > 0) {
							setShowPayments(true);
						} else if (selected_fields.filter(sf => (sf?.value === 'Wallet') && sf.data_type_name === 'Payments').length > 0) {
							purchase({
								user_id: authenticatedUser.id,
								id: generateNewUUID(),
								total: -(taskProcessContext.total),
								product: `User payments`,
								notes: `${title}`,
								quantity: 1,
							})
								.pipe(first())
								.subscribe({
									next: () => {
										updateUserOptionsById(authenticatedUser, { ...authenticatedUser?.options, credit: +(+(authenticatedUser?.options?.credit ?? 0) - (+taskProcessContext.total)) })
											.pipe(first())
											.subscribe({
												next: data => {
													authenticatedUser.options = data.options;
													setDisplaySave(begunModule?.processModule?.module?.options?.message?.length > 0 && _data.is_complete == true);
													store.dispatch(setCart({}));
													closeDetailView();
												},
											});
									},
								});
						} else {
							setDisplaySave(begunModule?.processModule?.module?.options?.message?.length > 0 && _data.is_complete == true);
							store.dispatch(setCart({}));
							closeDetailView();
						}
					},
					error: data => {
						if (data?.data) {
							store.dispatch(setMessageBar({ show: true, message: `Errors found`, color: 'red' }));
							if (updateCompany !== null) {
								updateCompanyData(updateCompany);
							}
							setDisplaySave(begunModule?.processModule?.module?.options?.message?.length > 0 && data?.data.is_complete == true);
							store.dispatch(setCart({}));
							closeDetailView();

						}
					},
					complete: () => {
						setLoading(false);
					},
				});
			}
		} else {
			store.dispatch(setMessageBar({ show: true, message: `There are no items to be saved`, color: 'red' }));
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	};

	const updateCompanyData = keys => {
		if (keys?.length > 0) {
			const options = companyData.misc_info ?? {};
			keys.filter(key => {
				options[key] = +(options[key] ?? 1) + 1;
			})

			setLoading(true);
			setCompanyOptions({ misc_info: options })
				.pipe(first())
				.subscribe({});
		}
	};

	const checkErrors = () => {
		setMessage('There are mandatory fields to be completed!');
		window.scrollTo({ top: 0, behavior: 'smooth' });
		setIsNextStepVisible(false);
	};

	const changeProcess = process => {
		store.dispatch(handleChangeProcessModule(process));
		setIsNextStepVisible(false);
	};

	const searchFunction = (mid, array = [], filter, key = undefined, ids = []) => {
		const found = array
			.filter(component => component.module_id === mid)
			.filter(comp =>
				ids.length > 0
					? ids.includes(comp.id)
					: filter === ''
						? comp
						: comp.title?.toUpperCase().includes(filter?.toUpperCase()) ||
						comp.component_fields.filter(f =>
							f.data_type_name === 'Record'
								? components
									.find(c => c.component_fields.filter(cf => cf.field_name === f.options.output && cf.component_blueprint_id === f.options.component_id && cf.value === f?.value)?.length > 0)
									?.component_fields.find(cf => f.options?.names.includes(cf.field_name))
									.value?.toUpperCase().includes(filter?.toUpperCase())
								: key !== undefined
									? (f.data_type === 'date' ? moment(new Date(f?.value))?.format('DD MMM YYYY').includes(filter) : f?.value?.includes(filter)) && f.field_name === key
									: f.data_type === 'date'
										? moment(new Date(f?.value))?.format('DD MMM YYYY').includes(filter)
										: f?.value?.toUpperCase().includes(filter?.toUpperCase())
						)?.length > 0
			);
		return found;
	};

	const listingObject = (options, array = searchedArray) => {
		let list = R.sortWith([R.descend(R.prop('created_date'))])(array).map(cmp => {
			let object = {};
			options.list.map(column => {
				const x = cmp.component_fields.find(field => field.field_name === column);
				if (x != undefined) {
					if (x.data_type_name === 'People') {
						object = { ...object, [column]: x.value };
					} else if (x.data_type_name === 'Date') {
						const _date = new Date(x?.value?.split(',')[0]);
						object = { ...object, [column]: moment(_date).format('DD MMM YYYY') };
					} else if (x.data_type_name === 'Record' || x.data_type_name === 'Component') {
						object = { ...object, [column]: isMobile ? `${x?.value.slice(1, 20)}` : `${x?.value}` };
						components
							.find(c => c.component_fields.filter(cf => cf.field_name === x.options.output && cf.component_blueprint_id === x.options.component_id && cf.value === x?.value)?.length > 0)
							?.component_fields.filter(f => {
								object = { ...object, [f.field_name]: isMobile ? f.value.slice(1, 20) : f.value };
							});
					} else if (x.data_type_name === 'Filters') {
						object = { ...object, [column]: isMobile ? `${x?.value.slice(1, 20)}` : `${x?.value}` };
						components
							.find(c => c.component_fields.filter(cf => cf.field_name === x.options.output && cf.component_blueprint_id === x.options.component_id && cf.value === x?.value)?.length > 0)
							?.component_fields.filter(f => {
								object = { ...object, [f.field_name]: isMobile ? f.value.slice(1, 20) : f.value };
							});
					} else if (x.data_type_name === 'Status') {
						if (cmp.is_complete == false) {
							object = { ...object, [column]: 'Draft' };
						} else {
							object = { ...object, [column]: `${x?.value}` };
						}
					} else {
						object = { ...object, [column]: isMobile ? x?.value?.slice(1, 20).split(',')[0] : x?.value?.split(',')[0] };
					}
				}
			});

			if (cmp?.component_items?.length > 0) {
				object = {
					...object,
					['Total Qty']: cmp.component_items.reduce((a, o) => {
						return a + +o.quantity;
					}, 0),
				};
			}
			if (cmp?.shared_positions?.length > 0) {
				object = { ...object, Shares: cmp?.shared_positions.map(position => companyPositionsArray.find(pos => pos.value === position.id)?.label).toString() };
			}
			const created_date = new Date(cmp.created_date);
			object = {
				...object,
				id: cmp.id,
				['Created By']: `${cmp?.created_by?.first_name} ${cmp?.created_by?.last_name}`,
				['Created Date']: moment(created_date).format('DD MMM YYYY'),
				Title: cmp.title,
				items: cmp.component_items,
				[begunModule.processModule.module.name]: cmp.title,
			};

			options?.reports
				?.filter(report => report.data_type_name === 'Formula')
				.map(report => {
					const value = computeFormula(
						report.options,
						report.options.list.map(l => {
							return { value: object[l] ?? 0 };
						}),
						components.filter(c => c.module_id === begunModule?.processModule?.module?.id),
						cmp?.component_items
					);
					object = { ...object, [report.field_name]: `${value}` };
				});

			return object;
		});

		options?.reports
			?.filter(report => report.data_type_name === 'List Filter')
			.map(report => {
				list = list.filter(li => li[report.options?.field] === report.options?.value);
			});

		const report = options?.reports?.find(report => report.data_type_name === 'Transaction List');
		if (report) {
			let list_with_items = [];
			list.map(li => {
				li.items.filter(it => {
					const obj = { ...li };
					Object.keys(report.options['map']).map(ls => {
						obj[report.options['map'][ls]] = it[ls];
					});
					list_with_items = [...list_with_items, obj];
				});
				delete li['items'];
			});

			list = list_with_items;
		}

		return list;
	};

	const addItemButton = () => (
		<Button
			light
			style={{ display: begunModule?.processModule?.module?.options?.readOnly != true || authenticatedUser.user_type === 'manager' || authenticatedUser.user_type === 'admin' ? '' : 'none' }}
			clicked={() => {
				clickedAddButton();
				setSelectedComponent({});
			}}
			icon={<img src={add} />}
		/>
	);

	const editButton = () => (
		<PermissionWrapper permissions={[po.blueprints.process.delete]}>
			<Button
				light
				style={{ display: begunModule?.processModule?.module?.options?.readOnly != true || authenticatedUser.user_type === 'manager' || authenticatedUser.user_type === 'admin' ? '' : 'none' }}
				clicked={() => {
					clickedEditButton();
					setSelectedComponent({});
				}}
				icon={<img src={editBlack} />}
			/>
		</PermissionWrapper>
	);

	const dependenciesButton = () => (
		<Button
			clear
			clicked={() => {
				setLoading(true);
				begunModule.processModule.module?.options?.supporting?.filter((support, index) => {
					setLoading(true);
					getAlComponentsByModuleId({ id: support, name: `${allModules?.find(mod => mod.id === support)?.name}${index}` ?? `${module?.name}${index}` }, APPEND_TO_DATA, app_max, 1)
						.pipe(take(1))
						.subscribe({
							next: (data) => {
								if (begunModule.processModule.module?.options?.supporting?.length == index + 1) {
									setLoading(false);
									goToNextStep(begunModule.processModule.steps[begunModule.processModule.stepIndex - 1]?.id);
								}

							},
							error: () => setLoading(false)
						})
				});
			}}
			icon={<img src={reset2} />}
			loading={loading}
		/>
	);

	const refreshButton = () => (
		<Button
			light
			clicked={() => {
				setLoading(true);
				const module = allModules.find(m => m.id === begunModule.processModule.module_id);
				lastUpdates.componentsByModule[module.name] = 0;
				getAlComponentsByModuleId(module, APPEND_TO_DATA, app_max, 1)
					.pipe(take(1))
					.subscribe({
						next: data => {
							const components_data = data?.data ? data?.data : data;

							store.dispatch(setAllComponents(R.sortBy(R.prop('created_date'), [...components.filter(c => c.module_id !== begunModule.processModule.module_id), ...components_data]), CLEAR_TO_SET_DATA, blueprints.map(bp => bp.fields).flat(1)));

							lastUpdates.componentsByModule[module.name] = now();
							module.lastUpdate = now();
							module.count = data?.total ?? 0;
							setLoading(false);

							begunModule.processModule.module?.options?.supporting?.filter((support, index) => {
								setLoading(true);
								getAlComponentsByModuleId({ id: support, name: `${allModules?.find(mod => mod.id === support)?.name}${index}` ?? `${module?.name}${index}` }, APPEND_TO_DATA, app_max, 1)
									.pipe(take(1))
									.subscribe({
										next: (data) => {

											if (begunModule.processModule.module?.options?.supporting?.length == index + 1) {
												setLoading(false);
											}

										},
										error: () => setLoading(false)
									})
							});
						},
						complete: () => { },
						error: () => setLoading(false),
					});
			}}
			icon={<img src={reset2} />}
			loading={loading}
		/>
	);

	const searchButton = () => (
		<div className={cls.searchContainer}>
			<FontAwesomeIcon icon={faSearch} style={{ width: '25px', height: '25px', padding: '5px' }} />
			<input
				placeholder="Search"
				className={cls.searchInput}
				onChange={val => {
					if (val.nativeEvent.inputType === 'deleteContentBackward') {
						setSearchedArray(searchFunction(begunModule?.processModule?.module?.id, components, val.target.value));
					} else if (val.target.value?.length > 0) {
						setSearchedArray(searchFunction(begunModule?.processModule?.module?.id, searchedArray, val.target.value));
					} else if (val.target.value.length == 0) {
						setSearchedArray(components.filter(c => c.module_id === begunModule?.processModule?.module?.id));
					}
				}}
			/>
		</div>
	);

	const getUsedStatuses = () => {
		return [...new Set(filteredArray.filter(item => item.Status !== undefined).map(it => it.Status))].map(a => {
			return { label: a, value: a };
		});
	};

	const applyFilters = (filters, array = filteredArray) => {
		let found = JSON.parse(JSON.stringify(array));

		filters.filter((sf, i) => {
			if (sf.type === 'status') {
				if (headers.operator === 'OR') {
					found = [...(i == 0 ? [] : found), ...array.filter(item => item.Status === sf.value)];
				} else {
					found = found.filter(item => item.Status === sf.value);
				}
			} else if (sf.type === 'user') {
				if (headers.operator === 'OR') {
					found = [...(i == 0 ? [] : found), ...array.filter(item => item['Created By'] === sf.label)];
				} else {
					found = found.filter(item => item['Created By'] === sf.label);
				}
			} else if (sf.type === 'fields') {
				if (headers.operator === 'OR') {
					found = [...(i == 0 ? [] : found), ...array.filter(item => item[sf.filter] === sf.value)];
				} else {
					found = found.filter(item => item[sf.filter] === sf.value);
				}
			}
		});
		setFilteredArray(found);
	};
	const filtersButton = () => (
		<div>
			<div style={{ display: 'flex' }}>
				{searchFilters.length > 0 && (
					<Button
						title={'Show all'}
						clicked={() => {
							setSearchedArray(components.filter(c => c.module_id === begunModule?.processModule?.module?.id));
							setHeaders({
								...headers,
								filter: undefined,
							});
							setFilteredArray(
								listingObject({
									...headers,
									filter: undefined,
								})
							);
						}}
						style={{ height: '30px', width: '140px' }}
					/>
				)}

				{searchFilters.map((sf, index) => (
					<Button
						className={cls.customButton}
						icon={<img src={closeWhite} style={{ height: '16px', width: '16px' }} />}
						clicked={() => {
							setSearchFilters(searchFilters.filter(filter => filter.value !== sf.value));
							applyFilters(
								searchFilters.filter(filter => filter.value !== sf.value),
								listingObject({
									...headers,
									filter: undefined,
								})
							);
						}}
						key={index}
						style={{ height: '30px', width: 'inherit' }}
					>
						{`${index == 0 ? '' : sf.operator} ${sf.label}`}
					</Button>
				))}

				{searchFilters.length > 0 && (
					<Button
						title={'Apply'}
						style={{ height: '30px', width: '160px' }}
						clicked={() => {
							applyFilters(
								searchFilters,
								listingObject({
									...headers,
									filter: undefined,
								})
							);
						}}
						light
					/>
				)}
			</div>
			{showReports && (
				<div style={{ display: 'flex' }}>
					{operatorButton()}
					{getUsedStatuses().length > 0 && (
						<SelectAndMultiselect
							className={cls.dropdownSelectComponent}
							items={getUsedStatuses()}
							onChange={value => {
								if (!searchFilters.map(fil => fil.label).includes(value)) {
									setSearchFilters([...searchFilters, { label: value, value: value, type: 'status', operator: headers.operator }]);
								}
							}}
							placeholder={`Status`}
						/>
					)}

					{showReports && (
						<SelectAndMultiselect
							className={cls.dropdownSelectComponent}
							items={headers.list.map(head => {
								return { label: head, value: head };
							})}
							onChange={value => {
								setHeaders({ ...headers, filter: undefined });
								setTimeout(() => setHeaders({ ...headers, filter: value }), 10);
							}}
							placeholder={`Filter by fields`}
						/>
					)}

					{showReports == true && headers?.filter !== undefined && (
						<SelectAndMultiselect
							className={cls.dropdownSelectComponentL}
							items={[...new Set(listingObject(headers).map(l => l[headers.filter]))].map(lo => {
								return { label: lo, value: lo };
							})}
							onChange={value => {
								if (!searchFilters.map(fil => fil.value).includes(value)) {
									setSearchFilters([...searchFilters, { label: `${headers.filter}: ${value}`, value: value, type: 'fields', filter: headers.filter, operator: headers.operator }]);
								}
							}}
							placeholder={`Field value`}
						/>
					)}

					{showReports == true && (
						<SelectAndMultiselect
							className={cls.dropdownSelectComponent}
							items={companyUsersArray.map(user => {
								return { label: `${user.first_name} ${user.last_name}`, value: user.id };
							})}
							onChange={value => {
								if (!searchFilters.map(fil => fil.label).includes(value)) {
									const user = companyUsersArray.find(user => user.id === value);
									setSearchFilters([...searchFilters, { label: `${user.first_name} ${user.last_name}`, value: value, type: 'user', operator: headers.operator }]);
								}
							}}
							placeholder={`Created by`}
						/>
					)}
				</div>
			)}
		</div>
	);

	const operatorButton = () => (
		<div style={{ display: '' }}>
			<SelectAndMultiselect
				value={headers.operator ?? 'AND'}
				className={cls.dropdownSelectOperation}
				items={[
					{ label: 'AND', value: 'AND' },
					{ label: 'OR', value: 'OR' },
				]}
				onChange={value => {
					setHeaders({ ...headers, operator: value });
				}}
			/>
		</div>
	);

	const reportsButton = () => (
		<div style={{ alignSelf: 'flex-end', display: currentStep.component_blueprints.filter(c => c.options?.asReport == true).length > 0 ? 'flex' : 'none' }}>
			<Button
				light
				clicked={() => {
					if (showReports) {
						setHeaders({ list: [], operator: 'AND' });
						setSearchedArray(components.filter(c => c.module_id === begunModule?.processModule?.module?.id));
					} else {
						setSearchedArray([]);
					}
					setSearchFilters([]);
					setShowReports(!showReports);
				}}
				style={showReports ? { height: '36px' } : {}}
				icon={<img src={showReports ? closeSearch : duplicateIcon} />}
			/>

			{showReports == true && (
				<>
					<SelectAndMultiselect
						className={cls.dropdownSelectReport}
						items={currentStep.component_blueprints
							.filter(c => c.options?.asReport == true && (c.options?.showOnly === undefined || c.options?.showOnly.length == 0 || authenticatedUser.positions.filter(pos => c.options?.showOnly?.includes(pos.id)).length > 0))
							.map(cb => {
								return { label: cb?.name, value: cb.id, blueprint: cb };
							})}
						visible={true}
						onChange={value => {
							setSearchedArray(components.filter(c => c.module_id === begunModule?.processModule?.module?.id));
							let hlist = [];

							R.sortWith([R.ascend(R.prop('index'))])(currentStep.component_blueprints.find(cb => cb.id === value).selected_fields.filter(sf => sf.visible == true)).map(sf => {
								hlist = [...hlist, sf.field_name];
								if (sf.data_type_name === 'Info') {
									sf.options.list.map(ls => (hlist = [...hlist, ls]));
								} else if (sf.data_type_name === 'Transaction List') {
									Object.values(sf.options['map']).map(ls => (hlist = [...hlist, ls]));
								}
							});

							setHeaders({
								list: [...new Set(hlist)],
								title: currentStep.component_blueprints.find(cb => cb.id === value)?.name,
								reports: R.sortWith([R.descend(R.prop('index'))])(currentStep.component_blueprints.find(cb => cb.id === value).selected_fields).map(sf => {
									return { field_name: sf.field_name, data_type_name: sf.data_type_name, options: sf.options };
								}),
								operator: 'AND',
							});

							setFilteredArray(
								listingObject(
									{
										list: [...new Set(hlist)],
										title: currentStep.component_blueprints.find(cb => cb.id === value)?.name,
										reports: R.sortWith([R.descend(R.prop('index'))])(currentStep.component_blueprints.find(cb => cb.id === value).selected_fields).map(sf => {
											return { field_name: sf.field_name, data_type_name: sf.data_type_name, options: sf.options };
										}),
										operator: 'AND',
									},
									components.filter(c => c.module_id === begunModule?.processModule?.module?.id)
								)
							);
						}}
						placeholder={`Reports`}
					/>
				</>
			)}
		</div>
	);

	const exportButton = (contents = [], header = Object.keys(contents[0]), title = begunModule.processModule.module?.name) => (
		<Button
			light
			title={headers?.title && 'Export as file'}
			clicked={() => {
				const head = showReports ? header : begunModule.processModule.module?.options?.isMaster == true ? [...header, 'Shares', 'Title'] : header;
				const wb = utils.book_new();
				const ws = utils.json_to_sheet([]);
				utils.sheet_add_aoa(ws, [head]);
				utils.sheet_add_json(
					ws,
					contents.map(c => Object.assign({}, ...head.map(key => ({ [key]: c[key] })))),
					{ origin: 'A2', skipHeader: true }
				);
				utils.book_append_sheet(wb, ws, `${begunModule.processModule.module?.name}`);
				writeFile(wb, `${title}${now()}.xlsx`);
			}}
			cicon={<img src={importXLSIco} />}
		>
			<img src={importXLSIco} />
		</Button>
	);

	const status = selectedComponent?.component_fields?.find(cf => cf.data_type_name === 'Status')?.value;

	let authorized = [];

	begunModule?.processModule?.steps?.filter(step => step?.component_blueprints.filter(cb => cb.options?.showOnly?.length > 0))
		.filter(com => {
			com.component_blueprints.filter(cb => cb.options?.showOnly?.length > 0).filter(cb => {
				cb.options.showOnly.filter(show => {
					authorized = [...authorized, show];
				})
			})
		})
	const signatory =
		begunModule?.processModule?.steps?.length > 0
			? begunModule?.processModule?.steps
				?.find(step => step?.component_blueprints.filter(cb => cb.selected_fields.filter(sf => sf.data_type_name === 'Freehand').length > 0).length > 0)
				?.component_blueprints.find(cb => cb.selected_fields.filter(sf => sf.data_type_name === 'Freehand')?.length > 0)?.options?.showOnly
			: undefined;

	const authorized_for_action =
		begunModule?.processModule?.steps?.length > 0
			? begunModule?.processModule?.steps
				?.find(step => step?.component_blueprints.filter(cb => cb.selected_fields.filter(sf => sf.data_type_name === 'Action Button').length > 0).length > 0)
				?.component_blueprints.find(cb => cb.selected_fields.filter(sf => sf.data_type_name === 'Action Button')?.length > 0)?.options?.showOnly
			: undefined;

	const actionButtons = () => (
		<div className={cls.menuAccordionButtons}>
			{begunModule.processModule.stepIndex > 0 && (
				<Button
					title={"Cancel"}
					light
					//style={{ width: '80px' }}
					clicked={() => {
						setSearchedArray(components.filter(c => c.module_id === begunModule?.processModule?.module?.id));
						goToNextStep(begunModule.processModule.steps[0].id);
						setEditMode(false);
					}}
				/>
			)}
			{begunModule.processModule.stepIndex > 0 && (
				<Button
					title={"Back"}
					light
					style={{ width: '80px' }}
					clicked={() => {
						if (begunModule.processModule.stepIndex == 1) {
							setSearchedArray(components.filter(c => c.module_id === begunModule?.processModule?.module?.id));
						}

						if (currentStep?.options?.deleteOnBack == true) {
							currentStep.component_blueprints.filter(component => component.selected_fields.filter(sf => sf.data_type !== 'output').map(f => {
								f.value = undefined;
							}));
						}

						goToNextStep(begunModule.processModule.steps[begunModule.processModule.stepIndex - 1]?.id);
					}}
				/>
			)}
			{currentStep?.is_end === true ? (
				<Button
					disabled={companyData?.start === false}
					title={isUpdating ? "Update" : isCompleted ? begunModule?.processModule?.module?.options?.save ?? "Save" : "Save"}
					loading={loading}
					style={{ width: '160px', marginLeft: '0px' }}
					clicked={() =>
						editMode ? setMessage('Saving is not allowed in Edit Mode') : begunModule?.processModule?.module?.options?.emailOutputs == true && begunModule?.processModule?.module?.options?.selectiveEmail == true && isCompleted ? setShowEmailModal(true) : saveModuleOutputs()
					}
				></Button>
			) : (
				<Button
					disabled={companyData?.start === false}
					title={"Next"}
					style={{ width: '80px' }}
					clicked={() => {
						const decision = currentStep.component_blueprints
							.find(cb => cb.selected_fields.filter(sf => (sf.data_type_name === 'Dropdown') && sf.options?.jump1 && sf.options?.type === 'decision').length > 0)
							?.selected_fields?.find(sf => (sf.data_type_name === 'Dropdown') && sf.options?.jump1);

						const decision_array = currentStep.component_blueprints
							.find(cb => cb.selected_fields.filter(sf => (sf.data_type_name === 'Value Button') && sf.options?.jump1).length > 0)
							?.selected_fields?.filter(sf => (sf.data_type_name === 'Value Button')  && sf.options?.jump1);

						if (decision?.options?.jump1 && decision?.options?.jump2) {
							if (decision?.options?.list.indexOf(decision?.value) == 0) {
								clickedNextButton(companyProcesses.find(pro => pro.id === decision?.options?.process)?.steps?.find(step => step.id === decision?.options.jump1)?.id);
							} else if (decision?.options?.list.indexOf(decision?.value) == 1) {
								clickedNextButton(companyProcesses.find(pro => pro.id === decision?.options?.process)?.steps?.find(step => step.id === decision?.options.jump2)?.id);
							} else {
								store.dispatch(setMessageBar({ show: true, message: `Please select a value for ${decision?.field_name}`, color: 'red', bottom: false }));
							}
						} else if (decision_array?.length > 0) {
							const found = decision_array.filter(dec => dec.value !== undefined); 
							if (found.length == 1) {
								clickedNextButton(companyProcesses.find(pro => pro.id === found[0]?.options?.process)?.steps?.find(step => step.id === found[0]?.options.jump1)?.id);
							} else {
								store.dispatch(setMessageBar({ show: true, message: `A selection is required`, color: 'red', bottom: false }));
								window.scrollTo({ top: 0, behavior: 'smooth' });
							}
							
						} else if (currentStep?.next_steps.length > 1 || pathwaySteps.length > 1 || pathwayProcesses.length > 1) {
							setIsNextStepVisible(!isNextStepVisible);
						} else if (currentStep.next_steps.length === 1) {
							clickedNextButton(currentStep?.next_steps[0]?.id);
						} else {
							clickedNextButton(begunModule.processModule.steps[begunModule.processModule.steps.findIndex(step => step.id === currentStep.id) + 1]?.id);
						}
					}}
				/>
			)}
			{isNextStepVisible && (
				<div className={cls.goToNextStep}>
					{currentStep?.next_steps.map((step, idx) => {
						return (
							<div key={idx} onClick={() => clickedNextButton(step.id)} className={cls.nextStepsContainer}>
								<div className={cls.nextStepImageContainer}>
									<img alt="" src={nextStepDownArrow} />
								</div>
								<p>{step?.name}</p>
							</div>
						);
					})}
					{pathwaySteps?.map((step, idx) => {
						return (
							<div key={idx} onClick={() => clickedNextButton(step.id)} className={cls.nextStepsContainer}>
								<div className={cls.nextStepImageContainer}>
									<img alt="" src={roundPath} />
								</div>
								<p>{step?.name}</p>
							</div>
						);
					})}
					{pathwayProcesses?.map((proc, idx) => {
						return (
							<div key={idx} onClick={() => changeProcess(proc)} className={cls.nextStepsContainer}>
								<div className={cls.nextStepImageContainer}>
									<img alt="" src={processImg} />
								</div>
								<p>{proc?.name}</p>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);

	//console.log('MODULE STEP taskProcessContext', taskProcessContext);
	//console.log('MODULE STEP begunModule', begunModule, components);
	//console.log('MODULE STEP CurrentStep', currentStep);
	//console.log('MODULE STEP selectedComponent', selectedComponent);
	//console.log('action step', begunModule?.processModule?.steps.find(st => st.component_blueprints.filter(cb => cb.selected_fields.filter(sf => sf.data_type_name === 'Action Button').length > 0).length > 0))

	return (
		<div className={cls.processStepHost} style={{ backgroundColor: isMobile ? '' : currentStep?.options?.popup === true ? 'var(--app-color-white-lightest)' : '' }}>
			<div className={cls.centeredCardMargin} app-variant-is-mobile={isMobile ? 'true' : 'false'}>
				{currentStep?.rank == 1 && (
					<GeneralCard
						title={begunModule?.processModule?.module?.name}
						subtitle={isMobile ? '' : begunModule?.processModule?.description}
						customStyle={{ width: isMobile ? '100%' : '94%', minWidth: isMobile ? '100px' : '94%' }}
						cardClicked={() => {
							onSetSelectedProcess(begunModule?.processModule);
							setShowProcess(!showProcess);
						}}
					/>
				)}
			</div>

			{showProcess && currentStep?.rank == 1 && (
				<div className={cls.centeredCardMargin} app-variant-is-mobile={isMobile ? 'true' : 'false'}>
					<GeneralCard description={'Steps:'} customStyle={{ width: isMobile ? '100%' : '94%', minWidth: isMobile ? '100px' : '94%' }}>
						<TheProcessContext>
							<ProcessStepsChart />
						</TheProcessContext>
					</GeneralCard>
				</div>
			)}

			{children}

			<div className={cls.centeredCardMargin} app-variant-is-mobile={isMobile ? 'true' : 'false'}>
				{currentStepIndex === 1 && currentStep?.component_blueprints === undefined ? (
					<div className={cls.centeredSpinner}>
						<Spinner size={80} type={'ThreeDots'} />
					</div>
				) : currentStep?.component_blueprints?.filter(cb => cb.options?.asModuleList == true).length > 0 ? (
					currentStep?.component_blueprints
						?.filter(cb => cb.options.asModuleList == true && (authenticatedUser.positions.filter(pos => cb.options?.showOnly?.includes(pos.id)).length > 0 || cb.options?.showOnly?.length == 0 || cb.options?.showOnly === undefined))
						.map((com, index) => {
							return (
								<ModuleLists
									key={index}
									className={cls.listContainer}
									isReport={showReports}
									app-variant-is-mobile={isMobile ? 'true' : 'false'}
									title={showReports ? 'Reports' : currentStep?.name}
									columns={
										components.filter(c => c.module_id === begunModule?.processModule?.module?.id)?.filter(com => com.component_fields?.length === 0).length > 0 ?
											[begunModule.processModule.module.name] :
											isMobile
												? [begunModule.processModule.module.name]
												: [
													...new Set(
														(com.selected_fields.filter(sf => sf.visible == true && sf.data_type_name === 'Info').map(sf => {
															return sf.options?.list;
														}).flat()).concat(
															showReports
																? headers.list
																: R.sortWith([R.ascend(R.prop('index'))])(com.selected_fields.filter(sf => sf.visible == true && sf.data_type_name !== 'Infor')).map(sf => {
																	return sf.field_name;
																})
														)
													),
												]
									}
									list={
										showReports
											? filteredArray
											: listingObject({
												list: (com.selected_fields
													.filter(sf => sf.data_type_name === 'Info')
													.map(sf => {

														return sf?.options?.key ?? sf.field_name;

													})).concat(com.selected_fields
														.filter(sf => sf.visible == true)
														.map(sf => {
															return sf.field_name;
														})
													),
											})
									}
									addClicked={() => {
										clickedAddButton();
										setSelectedComponent({});
									}}
									onListItemClick={item => {
										if (showReports) return;
										const comp = components.find(c => c.id === item.id);

										if (comp.component_fields?.length === 0) {

											setLoading(true);
											getComponentById(comp.id)
												.pipe(first())
												.subscribe({
													next: data => {
														setSelectedComponent(data);
														setIsCompleted(data.is_complete);
														setIsOpen(true);
														setForPrinting(false);
														setLoading(false);
													},
													error: () => {

													}
												});
											return;
										}
										setSelectedComponent(comp);
										setIsCompleted(comp.is_complete);
										
										setForPrinting(false);
										
										if (comp?.assigned_to?.id && !companyUsersArray.find(usr=> usr.id ===comp?.assigned_to?.id )){
											
											getFilteredUsers(companyUsersArray.find(usr => usr.id === comp.created_by?.id) ? [comp?.assigned_to?.id] : [comp?.assigned_to?.id, comp?.created_by?.id])
											.pipe(first())
											.subscribe({
												next: data => {
													store.dispatch(setUsersArray(companyUsersArray.concat(data)));
													setIsOpen(true);
													
												},
												
											});
										} else {
											setIsOpen(true);
										}
									}}
								>
									{filtersButton()}
									<div className={cls.actionsContainer}>
										{!headers?.title && showReports && (
											<label style={{ padding: '20px 0px 20px 5px', fontSize: '12px' }}>{headers.operator === 'OR' ? 'Selecting OR will select all instances of selected value' : 'Selecting AND will only include instances of selected value'}</label>
										)}
										{showReports ? headers?.title && exportButton(filteredArray, headers.list, headers.title) : searchButton()}
										{showReports && reportsButton()}
										<div style={{ display: showReports ? 'none' : 'flex', alignSelf: 'flex-end' }}>
											{!(companyData.status === 'trial_ended' || companyData.status === 'inactive') && addItemButton()}
											{!isMobile && reportsButton()}
											{!isMobile &&
												exportButton(
													listingObject({ list: com.selected_fields.filter(sf => sf.visible == true).map(sf => sf.field_name) }),
													com.selected_fields.filter(sf => sf.visible == true).map(sf => sf.field_name)
												)}
											{refreshButton()}
										</div>
									</div>
								</ModuleLists>
							);
						})
				) : (
					<div className={cls.listContainer} app-variant-is-mobile={isMobile ? 'true' : 'false'} style={{ boxShadow: isMobile ? '' : currentStep?.options?.popup == true ? '0 var(--app-color-white-lightest)' : '' }}>
						<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
							<p className={cls.stepTitleSmaller}>{currentStep?.name}</p>
							{dependenciesButton()}
						</div>
						<br />
						<label className={cls.stepGuidanceSmaller} >{currentStep?.guidance}</label>
						{message && <MessageContainer message={message} onClick={() => setMessage('')} />}
						{/* (isMandatoryError || editMode) &&  */}
						<ModalR blured={false} scrollable={false} isOpen={isMobile ? false : currentStep?.options?.popup ?? false} shadowedContainerStyle={{ width: isMobile ? '100%' : '70%', minWidth: '50%' }}>
							<RenderComponents
								nextButtonClicked={nextButtonClicked}
								step={currentStep}
								setIsMandatoryError={setIsMandatoryError}
								isMandatoryError={isMandatoryError}
								begunModule={begunModule}
								companyData={companyData}
								blueprints={blueprints}
								components={components}
								products={products}
								authenticatedUser={authenticatedUser}
								isEditing={isEditing}
								companyUsersArray={companyUsersArray}
								usersArray={usersArray}
								setUsers={setUsers}
								setPositions={setPositions}
								setIsCompleted={setIsCompleted}
								selectedComponent={selectedComponent}
								goToNextStep={goToNextStep}
								companyPositionsArray={companyPositionsArray}
								cart={cart}
								allModules={allModules}
								editMode={editMode}
							>
								<div className={cls.menuRightButtons}></div>
								{actionButtons()}
							</RenderComponents>
						</ModalR>
						{(currentStep?.options?.popup !== true || isMobile) && (
							<RenderComponents
								nextButtonClicked={nextButtonClicked}
								step={currentStep}
								setIsMandatoryError={setIsMandatoryError}
								isMandatoryError={isMandatoryError}
								begunModule={begunModule}
								companyData={companyData}
								blueprints={blueprints}
								components={components}
								products={products}
								authenticatedUser={authenticatedUser}
								isEditing={isEditing}
								companyUsersArray={companyUsersArray}
								usersArray={usersArray}
								setUsers={setUsers}
								setPositions={setPositions}
								setIsCompleted={setIsCompleted}
								selectedComponent={selectedComponent}
								goToNextStep={goToNextStep}
								companyPositionsArray={companyPositionsArray}
								cart={cart}
								allModules={allModules}
								editMode={editMode}
							>
								<div className={cls.menuRightButtons}></div>
								{actionButtons()}
							</RenderComponents>
						)}
					</div>
				)}
				{restore && begunModule.processModule.stepIndex > 0 && <ModalR isOpen={restore} onClose={() => setRestore(false)} backdropColor={true} blured={true} scrollable={true} shadowedContainerStyle={{ width: isMobile ? '100%' : '30%' }}>
					<div style={{ padding: '30px' }}>
						<p className={cls.deleteText}>{`There were unsaved data for ${selectedComponent.title}. Do you want to restore them?`}</p>
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<Button
								title={'Yes, restore'}
								clicked={() => {

									store.dispatch(setMessageBar({ show: true, message: `Reverting to saved data from the cache. Press Save to permanently save these records. Cancel to revert back`, color: 'red', spinner: false, bottom: true }));
									taskProcessContext.items = JSON.parse(localStorage.getItem('items'))
									taskProcessContext.newStepComponents = JSON.parse(localStorage.getItem('newStepComponents'));

									localStorage.removeItem('transaction');
									setRestore(false);
								}}

							/>
							<Button title={'No, use the current data as is'} light clicked={() => {
								localStorage.removeItem('transaction');

								setRestore(false);
							}} />
						</div>
					</div>
				</ModalR>}

				{showDeleteComponent && (
					<ModalR isOpen={deleteComponent} onClose={() => { }} backdropColor={true} blured={true} scrollable={true} shadowedContainerStyle={{ width: isMobile ? '100%' : '20%' }}>
						<div>
							<p className={cls.deleteText}>{`Are you sure you want to delete ${selectedComponent.title}?`}</p>
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<Button
									title={'Delete'}
									clicked={() => {
										setDeleteLoading(true);
										deleteComponent(selectedComponent.id)
											.pipe(first())
											.subscribe({
												next: () => {
													setDeleteLoading(false);
													setIsOpen(false);
													setShowDeleteComponent(false);
												},
												complete: () => { },
												error: () => {
													setDeleteLoading(false);
													setShowDeleteComponent(false);
												},
											});
									}}
									loading={deleteLoading}
								/>
								<Button title={'No'} light clicked={() => setShowDeleteComponent(false)} />
							</div>
						</div>
					</ModalR>
				)}

				{dependenciesFound && (
					<ModalR isOpen={dependenciesFound} onClose={() => { }} backdropColor={true} blured={false} scrollable={true} shadowedContainerStyle={{ width: isMobile ? '100%' : '20%' }}>
						<div>
							<p className={cls.deleteText}>{`Cannot delete ${selectedComponent.title}. Some transactions and master data are using this item`}</p>
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<Button title={'Close'} light clicked={() => setDependenciesFound(false)} />
							</div>
						</div>
					</ModalR>
				)}
			</div>

			{showPayments && <ModalR isOpen={showPayments} onClose={() => { }} backdropColor={true} blured={false} scrollable={true} shadowedContainerStyle={{ width: isMobile ? '100%' : '80%' }}>
				<CheckoutPage
					hasCloseIcon
					label={begunModule?.processModule?.module?.name}
					withBack={false}
					paymentPath={'begin-module'}
					onClose={() => setShowPayments(false)}
					companyData={companyData}
					subscriptionInfo={{
						user_id: authenticatedUser.id,
						ref: taskProcessContext.id,
						plan_price: taskProcessContext?.plan ?? 0,
						currency: taskProcessContext?.currency ?? 'PHP',
						plan: `${begunModule?.processModule?.module?.name},${taskProcessContext.id}`,
						total: +taskProcessContext?.total ?? 0,
						billable_users: [authenticatedUser],
						duration: 0,
						dlabel: taskProcessContext.id,
					}}
					onError={error => {
						setMessage(error);
						setIsMandatoryError(true);
						setShowPayments(false);

						store.dispatch(setMessageBar({ show: true, message: 'Transaction was created but the selected payment mode encountered an issue. Please select another payment option. You may also manually attach your proof of payment', color: 'red', bottom: true }));
						store.dispatch(setCart({}));
						closeDetailView();
					}}
				/>
			</ModalR>}

			{showEmailModal && <ModalR isOpen={showEmailModal} onClose={() => setShowEmailModal(false)} backdropColor={true} blured={true} scrollable={true} shadowedContainerStyle={{ width: isMobile ? '100%' : '15%' }}>
				<div>
					<ContainerWrapper ungrouped title={authenticatedUser.user_type === 'nonmanager' ? 'Type an email address to send copy of this transaction (optional)' : 'Select users to email this transaction (Optional)'} columned withAckButton={false}>
						{authenticatedUser.user_type === 'nonmanager' ? (
							<input
								placeholder="Use comma, no space, for multiple email addresses"
								className={cls.emailInput}
								onChange={val => {
									if (new RegExp(/^([a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*)(([, ]+[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])\.([a-z0-9]([a-z0-9-]*[a-z0-9]))*)?)*$/).test(val.target.value)) {
										setEmailList(val.target.value.split(','));
									}
								}}
							/>
						) : (
							<MultiSelectWithCheckboxesAndNestedItems_v2
								id={'Users'}
								tagEmplacement={'inlined'}
								searchable={false}
								items={usersArray.filter(usr => usr.department === authenticatedUser.departments[0]?.name)}
								groupedBy={'department'}
								groupname={'department'}
								triggerType={'chevron'}
								initialSelectedValues={[]}
								className={cls.peoplePositionsSelect}
								inputClassName={cls.peoplePositionsSelectInput}
								molclassname={cls.peoplePositionsSelectMenuOverlay}
								moliclassname={cls.peoplePositionsSelectMenuOverlayItem}
								onChange={value => {
									if (value.length > 0) {
										setEmailList(value?.map(i => i?.user.email));
									} else {
										setEmailList([]);
									}
								}}
							/>
						)}
					</ContainerWrapper>

					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<Button title={'Back'} light clicked={() => setShowEmailModal(false)} />
						<Button
							disabled={companyData?.start === false}
							title={isUpdating ? 'Update' : isCompleted ? begunModule?.processModule?.module?.options?.save ?? 'Save' : 'Save'}
							loading={loading}
							style={{ width: '160px', marginLeft: '0px' }}
							clicked={() => {
								setShowEmailModal(false);
								saveModuleOutputs();
							}}
						></Button>
					</div>
				</div>
			</ModalR>}

			{isOpen && <ModalR
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				hasCloseButton={isMobile ? true : selectedComponent?.component_fields?.length == 0 ? true : false}
				backdropColor={true}
				blured={false}
				scrollable={true}
				shadowedContainerStyle={{ width: isMobile ? '100%' : forPrinting ? '100%' : '50%', height: 'auto', minWidth: isMobile ? '100%' : '1000px' }}
			>
				<div className={cls.detailContainers} app-variant-is-mobile={isMobile ? 'true' : 'false'} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
					<RenderComponentDetailView
						ref={componentRef}
						begunModule={begunModule}
						allModules={allModules}
						companyData={companyData}
						components={components}
						selectedComponent={selectedComponent}
						companyUsersArray={companyUsersArray}
						print={forPrinting}
						step={step}
						companyPositionsArray={companyPositionsArray}
					/>
					{selectedComponent?.component_fields?.length > 0 && (
						<div className={cls.emulatedFlexGap} style={{ width: isMobile ? '100%' : '' }}>
							<Button
								title={'Close'}
								style={{ width: isMobile ? '90%' : '100px' }}
								clicked={() => {
									setIsOpen(false);
									setForPrinting(false);
								}}
							/>

							{selectedComponent?.component_fields?.length > 0 && (
								<>
									{/* Action Buttons */}

									{isUserAuthorized(status, authorized_for_action, selectedComponent, authenticatedUser, begunModule, companyData) &&
										begunModule?.processModule?.steps
											?.find(st => st.component_blueprints.filter(cb => cb.selected_fields.filter(sf => sf.data_type_name === 'Action Button').length > 0).length > 0)
											?.component_blueprints.find(cb => cb.selected_fields.filter(sf => sf.data_type_name === 'Action Button').length > 0)
											?.selected_fields.filter(sf => sf.data_type_name === 'Action Button')
											.map((sf, index) => {
												if (sf.options.action === 'status') {
													let status_array =
														companyData?.misc_info?.status?.length > 0
															? companyData?.misc_info?.status
															: [
																{ label: 'Ongoing', value: 'Ongoing' },
																{ label: 'Pending', value: 'Pending' },
																{ label: 'Awaiting Payment', value: 'Awaiting Payment' },
																{ label: 'Awaiting Fulfillment', value: 'Awaiting Fulfillment' },
																{ label: 'Awaiting Shipment', value: 'Awaiting Shipment' },
																{ label: 'Awaiting Pickup', value: 'Awaiting Pickup' },
																{ label: 'Partially Shipped', value: 'Partially Shipped' },
																{ label: 'Completed', value: 'Completed' },
																{ label: 'Shipped', value: 'Shipped' },
																{ label: 'Cancelled', value: 'Cancelled' },
																{ label: 'Declined', value: 'Declined' },
																{ label: 'Refunded', value: 'Refunded' },
																{ label: 'Disputed', value: 'Disputed' },
																{ label: 'Done', value: 'Done' },
																{ label: 'Closed (Will lock Edit)', value: 'Closed' },
															];

													if (companyData?.misc_info?.rules?.length > 0) {
														companyData?.misc_info?.rules
															.filter(rule => rule.type === 'status')
															.filter(rule => {
																if (rule.valueCondition === sf.value) {
																	if (rule.allow === '0') {
																		status_array = status_array.filter(status => rule.values.includes(status.value));
																	} else {
																		status_array = status_array.filter(status => !rule.values.includes(status.value));
																	}
																}
															});
													}

													return (
														<div key={index} style={{ width: '100%', display: sf.options.action === 'user' && selectedComponent.component_fields?.find(cf => cf.procedure_step_blueprint_field_id === sf.procedure_step_blueprint_field_id)?.value ? 'none' : '' }}>
															<SelectAndMultiselect
																titleProps={{ fontSize: '16px', color: 'var(--app-color-gray-light)' }}
																className={cls.multiselectComponent}
																key={index}
																visible={sf.visible}
																mandatory={sf.mandatory}
																value={sf.value ?? ''}
																items={status_array}
																error={sf.errors}
																onChange={selection => {
																	if (begunModule.processModule?.module?.options?.credits && (selection === companyData.options?.lock || selection === 'Closed')) {
																		const paymentF = begunModule?.processModule?.steps
																			?.find(st => st.component_blueprints.filter(cb => cb.selected_fields.filter(sf => sf.data_type_name === 'Payments').length > 0).length > 0)
																			?.component_blueprints.find(cb => cb.selected_fields.filter(sf => sf.data_type_name === 'Payments').length > 0)
																			?.selected_fields.find(sf => sf.data_type_name === 'Payments');

																		const found = taskProcessContext.newStepComponents
																			.concat(taskProcessContext.components)
																			.find(cmp => cmp.selected_fields.filter(sf => sf.field_name === paymentF?.options?.paymentField).length > 0)
																			?.selected_fields?.find(sf => sf.field_name === paymentF?.options?.paymentField);

																		let credit = 0;

																		if (begunModule.processModule?.selectedComponent.payments?.stripe_id) {
																			if (+(found?.value ?? 0) > 0) {
																				credit = +(found?.value ?? 0);
																			} else {
																				store.dispatch(setMessageBar({ show: true, message: `No amount found`, color: 'red', spinner: false, bottom: true }));
																				return;
																			}
																		} else {
																			setIsOpen(false);
																			begunModule.processModule.showDetail = false;
																			store.dispatch(setMessageBar({ show: true, message: `Check support payment docs`, color: 'red', spinner: false, bottom: true }));
																			return;
																		}

																		if (paymentF?.value !== 'Cash') {
																			purchase({
																				user_id: authenticatedUser.id,
																				id: generateNewUUID(),
																				total: credit,
																				product: `${sf.field_name}-${begunModule.processModule?.selectedComponent.payments?.stripe_id}`,
																				notes: authenticatedUser.id,
																				quantity: 1,
																			})
																				.pipe(first())
																				.subscribe({
																					next: () => {
																						updateUserOptionsById(authenticatedUser, { ...authenticatedUser?.options, credit: +(+(authenticatedUser?.options?.credit ?? 0) + credit) })
																							.pipe(first())
																							.subscribe({
																								next: data => (authenticatedUser.options = data.options),
																							});
																					},
																				});
																		}
																	}

																	companyData.start = true;
																	setLoading(true);
																	addNewFieldToComponent({
																		component_id: selectedComponent.id,
																		index: 0,
																		group: 99,
																		value: selection,
																		procedure_step_blueprint_field_id: sf.procedure_step_blueprint_field_id,
																	}).subscribe({
																		next: data => {
																			setLoading(false);
																			setIsOpen(false);
																			store.dispatch(
																				setProcessModule({
																					...begunModule.processModule,
																					showDetail: false,
																				})
																			);
																		},
																		error: () => { },
																		complete: () => { },
																	});
																}}
																fieldIcon={sf.icon}
																fieldLabel={sf.field_name}
																placeholder={'Select status...'}
															/>
														</div>
													);
												}

												return (
													<Button
														key={index}
														light
														title={sf.field_name}
														loading={loading}
														clicked={() => {
															if (sf.options.action === 'user' || sf.options.action === 'creator') {
																const targetUser = sf.options.action === 'user' ? authenticatedUser : companyUsersArray.find(user => user.id === selectedComponent.created_by?.id);
																if (begunModule.processModule?.module?.options?.credits) {
																	const paymentF = begunModule?.processModule?.steps
																		?.find(st => st.component_blueprints.filter(cb => cb.selected_fields.filter(sf => sf.data_type_name === 'Payments').length > 0).length > 0)
																		?.component_blueprints.find(cb => cb.selected_fields.filter(sf => sf.data_type_name === 'Payments').length > 0)
																		?.selected_fields.find(sf => sf.data_type_name === 'Payments');

																	const found = taskProcessContext.newStepComponents
																		.concat(taskProcessContext.components)
																		.find(cmp => cmp.selected_fields.filter(sf => sf.field_name === paymentF?.options?.paymentField).length > 0)
																		?.selected_fields?.find(sf => sf.field_name === paymentF?.options?.paymentField);

																	let credit = 0;

																	if (targetUser?.options?.credit === undefined || targetUser?.options?.credit === 0) {
																		store.dispatch(setMessageBar({ show: true, message: `You need to setup funds for your wallet`, color: 'red', spinner: false, bottom: true }));
																		begunModule.processModule.showDetail = false;
																		setIsOpen(false);
																		return;
																	} else if (+(found?.value ?? 0) > 0 && paymentF?.value === 'Cash') {
																		if ((+targetUser?.options?.credit ?? 0) < +found?.value) {
																			store.dispatch(setMessageBar({ show: true, message: `Insufficient funds in wallet`, color: 'red', spinner: false, bottom: true }));
																			begunModule.processModule.showDetail = false;
																			setIsOpen(false);
																			return;
																		}
																		credit = -(found?.value ?? 0);
																	} else if (!begunModule.processModule?.selectedComponent?.payments?.stripe_id) {
																		setIsOpen(false);
																		begunModule.processModule.showDetail = false;
																		store.dispatch(setMessageBar({ show: true, message: `Check support payment docs`, color: 'red', spinner: false, bottom: true }));
																		return;
																	}

																	if (paymentF?.value === 'Cash') {
																		updateUserOptionsById(targetUser, { ...targetUser?.options, credit: +(+(targetUser?.options?.credit ?? 0) + credit) })
																			.pipe(first())
																			.subscribe({
																				next: data => {
																					targetUser.options = data.options;
																					purchase({
																						user_id: authenticatedUser.id,
																						id: generateNewUUID(),
																						total: credit,
																						product: `${sf.field_name}-${paymentF?.value}`,
																						notes: targetUser.id,
																						quantity: 1,
																					})
																						.pipe(first())
																						.subscribe({
																							error: () => store.dispatch(setMessageBar({ show: true, message: `Purchase error encountered`, color: 'red', spinner: false, bottom: true })),
																						});
																				},
																			});
																	}
																}

																setLoading(true);
																addNewFieldToComponent({
																	component_id: selectedComponent.id,
																	index: 0,
																	group: 99,
																	value: targetUser.id,
																	procedure_step_blueprint_field_id: sf.procedure_step_blueprint_field_id,
																}).subscribe({
																	next: data => {
																		assignComponent(selectedComponent.id, targetUser.id).subscribe({
																			next: data => {
																				setLoading(false);
																				setIsOpen(false);
																				store.dispatch(
																					setProcessModule({
																						...begunModule.processModule,
																						showDetail: false,
																					})
																				);
																			},
																		});
																	},
																	error: () => { },
																	complete: () => { },
																});
															} else if (sf.options.action === 'status') {
																setLoading(false);
															} else if (sf.options.action === 'date') {
																setLoading(false);
															} else if (sf.options.action === 'value') {
																setLoading(false);
															} else {
																setLoading(false);
															}
														}}
														style={{
															backgroundColor: 'green',
															color: 'white',
															width: isMobile ? '90%' : '100px',
															display: sf.options.action === 'user' && selectedComponent.component_fields?.find(cf => cf.procedure_step_blueprint_field_id === sf.procedure_step_blueprint_field_id)?.value ? 'none' : '',
														}}
													/>
												);
											})}
								</>
							)}


							{!forPrinting && (
								<Button
									title={'Print'}
									style={{ width: '100px', display: isMobile ? 'none' : '' }}
									light
									clicked={() => {
										setForPrinting(true);
									}}
								/>
							)}
							{forPrinting && <ReactToPrint trigger={() => <button className={cls.singleButton}>Print Now</button>} content={() => componentRef.current} />}

							{((status === undefined && (authorized?.filter(a => authenticatedUser.positions.map(pos => pos.id).indexOf(a) > -1).length > 0 ||
								signatory?.filter(a => authenticatedUser.positions.map(pos => pos.id).indexOf(a) > -1).length > 0))
								||
								((status === undefined || status === 'New' || status === 'Show') && selectedComponent?.created_by?.id === authenticatedUser?.id)
								||
								(status !== undefined &&
									!(status === 'Hide' || status === 'Closed' || status === companyData?.misc_info?.lock) &&
									(authorized?.filter(a => authenticatedUser.positions.map(pos => pos.id).indexOf(a) > -1).length > 0 ||
										signatory?.filter(a => authenticatedUser.positions.map(pos => pos.id).indexOf(a) > -1).length > 0) &&
									(begunModule?.processModule?.module?.options?.strict != true || selectedComponent?.created_by?.id !== authenticatedUser?.id))
								||
								(selectedComponent?.is_complete === false && selectedComponent?.created_by?.id === authenticatedUser?.id)) && (
									<Button
										title={'Edit'}
										style={{
											backgroundColor: isMobile ? 'var(--app-color-blue)' : '',
											color: isMobile ? 'white' : '',
											width: isMobile ? '90%' : '100px',
											display:
												companyData.status === 'trial_ended' || companyData.status === 'inactive'
													? 'none'
													: selectedComponent.component_fields?.filter(cf => cf.data_type_name === 'Action Button' && cf.options.action === 'user').length === 0
														? ''
														: selectedComponent.component_fields?.filter(cf => cf.data_type_name === 'Action Button' && cf.options.action === 'user' && cf.value === undefined).length === 0
															? selectedComponent.component_fields?.filter(cf => cf.data_type_name === 'Action Button' && cf.options.action === 'user' && cf.value === authenticatedUser?.id).length > 0
																? ''
																: 'none'
															: 'none',
										}}
										light
										clicked={() => {

											setIsOpen(false);
											setIsUpdating(true);
											begunModule.processModule.isUpdating = true;

											//clear all values first
											begunModule?.processModule?.steps?.length > 0 && begunModule?.processModule?.steps?.filter(step => step?.component_blueprints?.filter(cb => cb.selected_fields.filter(field => (field.value = undefined))));
											let component_array = [];
											R.uniqWith(R.eqProps('group'))(selectedComponent?.component_fields ?? []).map(f => {
												//component_array = [...component_array, { id: f.component_blueprint_id, temp_id: f.id, selected_fields: R.filter(R.propEq('group', f.group))(selectedComponent.component_fields) }];
												component_array = [...component_array, { id: f.component_blueprint_id, temp_id: f.id, selected_fields: R.filter(R.propEq('group', f.group))(selectedComponent.component_fields) }];
											});

											begunModule.processModule.steps.filter(step => {
												step.component_blueprints
													.filter(cb => (cb?.options?.asDashboard == false || !cb?.options?.asDashboard) && (cb?.options?.asReport == false || !cb?.options?.asReport) && (cb?.options?.asModuleList == false || !cb?.options?.asModuleList))
													.filter(cb => {
														cb.selected_fields.filter(sf => {
															const field_found = selectedComponent.component_fields.find(cf => cf.field_name === sf.field_name);
															if (field_found != undefined) {
																sf.value = field_found.value;
																sf.group = field_found.group;
															} else if (sf.data_type_name === 'Record') {
																const label_found = selectedComponent.component_fields.find(cf => cf.field_name === sf.options.name);
																if (label_found != undefined) {
																	sf.value = label_found.value;
																	sf.value = label_found.group;
																}
															}
														});
													});
											});

											taskProcessContext.components = component_array;
											taskProcessContext.items = JSON.parse(JSON.stringify(selectedComponent.component_items));
											taskProcessContext.newStepComponents = [];
											companyData.start = true;
											goToNextStep();
										}}
									/>
								)}
							{begunModule.processModule.module?.options.copyOutputs === true && selectedComponent?.created_by?.id === authenticatedUser.id && (
								<Button
									title={'Duplicate'}
									style={{ width: isMobile ? '90%' : '', backgroundColor: isMobile ? 'var(--app-color-blue)' : '', color: isMobile ? 'white' : '' }}
									light
									clicked={() => {
										setIsOpen(false);
										clickedAddButton();
										let component_array = [];
										R.uniqWith(R.eqProps('group'))(selectedComponent?.component_fields ?? []).map(f => {
											component_array = [...component_array, { id: f.component_blueprint_id, temp_id: f.id, selected_fields: R.filter(R.propEq('group', f.group))(selectedComponent.component_fields) }];
										});

										begunModule.processModule.steps.filter(step => {
											step.component_blueprints.filter(cb => {
												cb.selected_fields.filter(sf => {
													const field_found = selectedComponent.component_fields.find(cf => cf.procedure_step_blueprint_field_id === sf.procedure_step_blueprint_field_id);
													if (field_found != undefined) {
														if (sf.data_type_name === 'ID' || sf.data_type_name === 'Status') {
															sf.value = undefined;
														} else {
															sf.value = field_found.value;
														}
													} else if (sf.data_type_name === 'Record') {
														const label_found = selectedComponent.component_fields.find(cf => cf.field_name === sf.options.name);
														if (label_found != undefined) {
															sf.value = label_found.value;
														}
													}
												});
											});
										});
										setTaskProcessContext({
											action: processTaskAction.addModuleComponents,
											value: component_array,
										});
										setTaskProcessContext({
											action: processTaskAction.setModuleItems,
											value: selectedComponent.component_items,
										});
										setIsUpdating(false);
										begunModule.processModule.isUpdating = false;

										setSelectedComponent({});
									}}
								/>
							)}
							{authenticatedUser?.is_admin === true && (
								<Button
									title={'Delete'}
									style={{ width: isMobile ? '90%' : '100px', backgroundColor: isMobile ? 'red' : '', color: isMobile ? 'white' : '' }}
									light
									clicked={() => {
										if (begunModule?.processModule?.module?.options?.isMaster === true) {
											const masterDataFound = components
												.filter(co => co.module_id !== selectedComponent.module_id)
												.filter(
													it =>
														allModules
															.filter(mod => mod?.options?.isMaster === true)
															.map(mod => mod.id)
															.indexOf(it.module_id) != -1
												);
											const transactionsArray = components.filter(
												it =>
													allModules
														.filter(mod => mod?.options?.isMaster != true)
														.map(mod => mod.id)
														.indexOf(it.module_id) != -1
											);

											const found =
												masterDataFound.filter(component => component.component_fields.filter(cf => selectedComponent.component_fields.map(f => f.value).indexOf(cf.value) != -1).length > 0).length > 0 ||
												transactionsArray.filter(component => component.component_items.filter(item => selectedComponent.component_fields.map(cf => cf.value).indexOf(item.item_code) != -1).length > 0).length > 0 ||
												transactionsArray.filter(component => component.component_fields.filter(item => item.data_type !== 'output' && selectedComponent.component_fields.map(cf => cf.value).indexOf(item.value) != -1).length > 0).length > 0;

											setDependenciesFound(found);
											setShowDeleteComponent(!found);
										} else {
											setShowDeleteComponent(true);
										}
									}}
								/>
							)}
						</div>
					)}

				</div>
			</ModalR>}

			{displaySave && <ModalR isOpen={displaySave} hasCloseButton onClose={() => (paymentID ? navigate('/') : setDisplaySave(false))} backdropColor={true} blured={false} scrollable={true} shadowedContainerStyle={{ width: isMobile ? '100%' : '30%', height: 'auto' }}>
				<div style={{ textAlign: 'center', padding: '20px' }}>
					<p className={cls.modalTitle}>{begunModule.processModule.module?.name}</p>
					<p className={cls.modalSubTitle}>{begunModule?.processModule?.module?.options?.message ?? 'Transaction saved'}</p>
				</div>
			</ModalR>}
		</div>
	);
}


const RenderComponents = memo(props => {
	const {
		step,
		isMandatoryError,
		nextButtonClicked,
		setIsMandatoryError,
		begunModule,
		companyData,
		components,
		isEditing,
		companyUsersArray,
		setUsers,
		setPositions = () => { },
		products,
		selectedComponent,
		goToNextStep,
		authenticatedUser,
		setIsCompleted,
		companyPositionsArray,
		cart,
		blueprints,
		allModules,
		usersArray,
		editMode,
	} = props;
	const ResponsiveReactGridLayout = WidthProvider(Responsive);
	const [completed] = useState(selectedComponent?.is_complete ?? true);
	let updatedLayout = [];

	const defaultProps = {
		margin: [0, 0],
		allowOverlap: false,
		containerPadding: [0, 0, 0, 0],
		className: 'layout',
		rowHeight: 100,
		width: 1000,
		cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
		onLayoutChange: function () { },
	};

	const onLayoutChange = (layout, layouts) => {
		updatedLayout = layouts.lg;
		defaultProps.onLayoutChange(layout, layouts);
	};

	const editLayout = () => {
		updatedLayout = state.layouts.lg.map(l => {
			return { ...l, static: false };
		});
		setState({
			...state,
			layouts: {
				lg: updatedLayout,
			},
			editing: !state.editing,
		});
	};

	const saveNewLayout = () => {
		const newL = updatedLayout.map(l => {
			return { ...l, static: true };
		});

		updateProcessStepByJson(
			{
				options: {
					...step.options,
					layout: newL,
				},
			},
			step.id,
			true
		)
			.pipe(first())
			.subscribe({
				next: _data => {
					setState({
						...state,
						layouts: {
							lg: newL,
						},
						editing: !state.editing,
					});
				},
				error: error => {
					setState({
						...state,
						layouts: { lg: step.options.layout },
						editing: !state.editing,
					});
				},
			});
	};

	const pathwaySteps = () => {
		const processesArray = step.pathway_steps.map(step => {
			return { label: `${step.name}`, value: step.id };
		});
		const usersArray = companyUsersArray.map(user => {
			return { label: `${user.first_name} ${user.last_name}`, value: user.id };
		});
		return (
			<div className={cls.emptyElementsSection} style={{ width: '60%', padding: '20px 20px 20px 20px', height: `${usersArray.length * usersArray.length > 4 ? 70 : 200}px` }}>
				<SelectAndMultiselect
					visible={true}
					mandatory={false}
					items={processesArray}
					onChange={value => {
						goToNextStep(value);
					}}
					placeholder={`Continue to this step...`}
				/>
			</div>
		);
	};
	const pathwayProcesses = () => {
		const processesArray = step.pathway_processes.map(proc => {
			return { label: `${proc.name}`, value: proc.id };
		});
		const usersArray = companyUsersArray.map(user => {
			return { label: `${user.first_name} ${user.last_name}`, value: user.id };
		});
		return (
			<div className={cls.emptyElementsSection} style={{ width: '60%', padding: '20px 20px 20px 20px', height: `${usersArray.length * usersArray.length > 4 ? 70 : 200}px` }}>
				<SelectAndMultiselect visible={true} mandatory={false} items={processesArray} onChange={() => { }} placeholder={`Continue to this process...`} />
			</div>
		);
	};

	const saveAsDraft = () => {
		return (
			<div className={cls.emptyElementsSection} style={{ width: '260px', padding: '0px 0px 0px 20px' }}>
				<RenderYesNoSwitcher
					{...{
						id: 'draft',
						title: 'Save as draft?',
						ungrouped: true,
						withAckButton: false,
					}}
					style={{ margin: '20px 2.5% 10px 5px', width: 'auto', display: '' }}
					value={!completed}
					toggleSelected={value => {
						setIsCompleted(!value);
					}}
				/>
			</div>
		);
	};

	const shareToPositions = () => {
		return (
			<ContainerWrapper ungrouped title={'Select positions to share this item:'} columned withAckButton={false}>
				<MultiSelectWithCheckboxesAndNestedItems_v2
					style={{ width: '50%' }}
					id={'Positions'}
					tagEmplacement={'inlined'}
					searchable={false}
					items={companyPositionsArray}
					groupedBy={'department'}
					groupname={'department'}
					triggerType={'chevron'}
					initialSelectedValues={selectedComponent?.shared_positions?.map(d => {
						return companyPositionsArray.find(item => item.value === d.id);
					})}
					className={cls.peoplePositionsSelect}
					inputClassName={cls.peoplePositionsSelectInput}
					molclassname={cls.peoplePositionsSelectMenuOverlay}
					moliclassname={cls.peoplePositionsSelectMenuOverlayItem}
					onChange={value => {
						if (value.length > 0) {
							setPositions(value?.map(i => i?.value));
						} else {
							setPositions([]);
						}
					}}
				/>
			</ContainerWrapper>
		);
	};
	const shareToUsers = () => {
		return (
			<ContainerWrapper ungrouped title={'Select users to share this item:'} columned withAckButton={false}>
				<MultiSelectWithCheckboxesAndNestedItems_v2
					style={{ width: '50%' }}
					id={'Users'}
					tagEmplacement={'inlined'}
					searchable={false}
					items={usersArray}
					groupedBy={'department'}
					groupname={'department'}
					triggerType={'chevron'}
					initialSelectedValues={selectedComponent?.shared_users?.map(user => {
						return usersArray.find(u => u.value === user.id);
					})}
					className={cls.peoplePositionsSelect}
					inputClassName={cls.peoplePositionsSelectInput}
					molclassname={cls.peoplePositionsSelectMenuOverlay}
					moliclassname={cls.peoplePositionsSelectMenuOverlayItem}
					onChange={value => {
						if (value.length > 0) {
							setUsers(value?.map(i => i?.value));
						} else {
							setUsers([]);
						}
					}}
				/>
			</ContainerWrapper>
		);
	};
	const newProduct = () => <AccordionProduct nextButtonClicked={nextButtonClicked} isMandatoryError={isMandatoryError} setIsMandatoryError={setIsMandatoryError} component={{}} />;

	const drawComponents =
		step?.component_blueprints?.length > 0 ? (
			step?.component_blueprints.map((component_blueprint, index) => {
				const template = component_blueprint.selected_fields.find(sf => sf.data_type_name === 'Template' && sf.visible == true);
				const video = component_blueprint.selected_fields.find(sf => sf.data_type_name === 'Video' && sf.visible == true);
				if (template) {
					return (
						<div key={index}>
							<CustomTemplateModule
								templateName={template.field_name}
								nextButtonClicked={nextButtonClicked}
								isMandatoryError={isMandatoryError}
								blueprintIndex={index}
								setIsMandatoryError={setIsMandatoryError}
								component={component_blueprint}
								companyData={companyData}
								components={components}
								products={products}
								selectedComponent={selectedComponent}

								component_blueprint={component_blueprint}
								editMode={editMode}
								blueprints={blueprints}
								allModules={allModules}
								authenticatedUser={authenticatedUser}
								companyUsersArray={companyUsersArray}
								companyPositionsArray={companyPositionsArray}
								begunModule={begunModule}
							/>
						</div>
					);
				} else if (component_blueprint.selected_fields.filter(sf => sf.visible == true).length > 0) {
					return (
						<div key={index} style={{ margin: '30px 0 0 0' }}>
							<AccordionModule
								editMode={editMode}
								nextButtonClicked={nextButtonClicked}
								isMandatoryError={isMandatoryError}
								blueprintIndex={index}
								setIsMandatoryError={setIsMandatoryError}
								blueprints={blueprints}
								component={component_blueprint}
								companyData={companyData}
								components={components}
								allModules={allModules}
								products={products}
								authenticatedUser={authenticatedUser}
								selectedComponent={selectedComponent}
								companyUsersArray={companyUsersArray}
								companyPositionsArray={companyPositionsArray}
								begunModule={begunModule}
							/>
							{selectedComponent?.id && video?.value && (moment(video?.value) <= now() && now() < (moment(video?.value).add('minutes', 10))) &&

								<AccordionModule
									editMode={editMode}
									nextButtonClicked={nextButtonClicked}
									isMandatoryError={isMandatoryError}
									blueprintIndex={index}
									setIsMandatoryError={setIsMandatoryError}
									blueprints={blueprints}
									component={{ ...component_blueprint, name: 'Video Call', selected_fields: [{ ...video, options: { ...video.options, start: true } }] }}
									companyData={companyData}
									components={components}
									allModules={allModules}
									products={products}
									authenticatedUser={authenticatedUser}
									selectedComponent={selectedComponent}
									companyUsersArray={companyUsersArray}
									companyPositionsArray={companyPositionsArray}
									begunModule={begunModule}
									shouldDisplay={true}
									isEnabled={true}
								/>



							}


						</div>
					);
				} else {
					return <div key={index} className={cls.emptyElementsSection} />;
				}
			})
		) : (
			<div className={cls.emptyElementsSection} />
		);

	const [state, setState] = useState({
		currentBreakpoint: 'lg',
		compactType: 'vertical',
		mounted: false,
		layouts: { lg: step.options.layout },
		editing: isEditing,
	});

	return (
		<div>
			<PermissionWrapper permissions={[po.processes.create]}>
				<button onClick={editLayout} hidden={!state.editing}>
					Edit Layout
				</button>
				<button onClick={saveNewLayout} hidden={!state.editing}>
					Save Layout
				</button>
			</PermissionWrapper>

			{drawComponents}
			{begunModule.processModule.module?.options.outputTo === 'product' && step?.is_end && newProduct()}
			{begunModule.processModule.module?.options.forSpecificUsers && step?.is_end && shareToUsers()}
			{begunModule.processModule.module?.options.forSpecificUsers && step?.is_end && shareToPositions()}
			{begunModule.processModule.module?.options.forSpecificUsers && step?.is_end && <div style={{ height: '200px' }} />}
			{step?.is_end && (selectedComponent?.is_complete === undefined || selectedComponent?.is_complete == false) && saveAsDraft()}
			{step?.pathway_steps?.length > 0 && pathwaySteps()}
			{step?.pathway_processes?.length > 0 && pathwayProcesses()}
			{props.children}
		</div>
	);
});

export const ActionButtons = props => {
	const { selectedComponent, authenticatedUser, authorized, forPrinting, setForPrinting, setShowDeleteComponent, setDependenciesFound, allModules, components, begunModule, setSelectedComponent,
		setIsUpdating, setTaskProcessContext, clickedAddButton, setIsOpen, goToNextStep, companyData, taskProcessContext, componentRef, setLoading, companyUsersArray,
		loading, authorized_for_action } = props
	return selectedComponent?.component_fields?.length > 0 && (
		<div className={cls.emulatedFlexGap} style={{ width: isMobile ? '100%' : '' }}>
			<Button
				title={'Close'}
				style={{ width: isMobile ? '90%' : '100px' }}
				clicked={() => {
					setIsOpen(false);
					setForPrinting(false);
				}}
			/>

			{selectedComponent?.component_fields?.length > 0 && (
				<>
					{/* Action Buttons */}

					{isUserAuthorized(status, authorized_for_action, selectedComponent, authenticatedUser, begunModule, companyData) &&
						begunModule?.processModule?.steps
							?.find(st => st.component_blueprints.filter(cb => cb.selected_fields.filter(sf => sf.data_type_name === 'Action Button').length > 0).length > 0)
							?.component_blueprints.find(cb => cb.selected_fields.filter(sf => sf.data_type_name === 'Action Button').length > 0)
							?.selected_fields.filter(sf => sf.data_type_name === 'Action Button')
							.map((sf, index) => {
								if (sf.options.action === 'status') {
									let status_array =
										companyData?.misc_info?.status?.length > 0
											? companyData?.misc_info?.status
											: [
												{ label: 'Ongoing', value: 'Ongoing' },
												{ label: 'Pending', value: 'Pending' },
												{ label: 'Awaiting Payment', value: 'Awaiting Payment' },
												{ label: 'Awaiting Fulfillment', value: 'Awaiting Fulfillment' },
												{ label: 'Awaiting Shipment', value: 'Awaiting Shipment' },
												{ label: 'Awaiting Pickup', value: 'Awaiting Pickup' },
												{ label: 'Partially Shipped', value: 'Partially Shipped' },
												{ label: 'Completed', value: 'Completed' },
												{ label: 'Shipped', value: 'Shipped' },
												{ label: 'Cancelled', value: 'Cancelled' },
												{ label: 'Declined', value: 'Declined' },
												{ label: 'Refunded', value: 'Refunded' },
												{ label: 'Disputed', value: 'Disputed' },
												{ label: 'Done', value: 'Done' },
												{ label: 'Closed (Will lock Edit)', value: 'Closed' },
											];

									if (companyData?.misc_info?.rules?.length > 0) {
										companyData?.misc_info?.rules
											.filter(rule => rule.type === 'status')
											.filter(rule => {
												if (rule.valueCondition === sf.value) {
													if (rule.allow === '0') {
														status_array = status_array.filter(status => rule.values.includes(status.value));
													} else {
														status_array = status_array.filter(status => !rule.values.includes(status.value));
													}
												}
											});
									}

									return (
										<div key={index} style={{ width: '100%', display: sf.options.action === 'user' && selectedComponent.component_fields?.find(cf => cf.procedure_step_blueprint_field_id === sf.procedure_step_blueprint_field_id)?.value ? 'none' : '' }}>
											<SelectAndMultiselect
												titleProps={{ fontSize: '16px', color: 'var(--app-color-gray-light)' }}
												className={cls.multiselectComponent}
												key={index}
												visible={sf.visible}
												mandatory={sf.mandatory}
												value={sf.value ?? ''}
												items={status_array}
												error={sf.errors}
												onChange={selection => {
													if (begunModule.processModule?.module?.options?.credits && (selection === companyData.options?.lock || selection === 'Closed')) {
														const paymentF = begunModule?.processModule?.steps
															?.find(st => st.component_blueprints.filter(cb => cb.selected_fields.filter(sf => sf.data_type_name === 'Payments').length > 0).length > 0)
															?.component_blueprints.find(cb => cb.selected_fields.filter(sf => sf.data_type_name === 'Payments').length > 0)
															?.selected_fields.find(sf => sf.data_type_name === 'Payments');

														const found = taskProcessContext.newStepComponents
															.concat(taskProcessContext.components)
															.find(cmp => cmp.selected_fields.filter(sf => sf.field_name === paymentF?.options?.paymentField).length > 0)
															?.selected_fields?.find(sf => sf.field_name === paymentF?.options?.paymentField);

														let credit = 0;

														if (begunModule.processModule?.selectedComponent.payments?.stripe_id) {
															if (+(found?.value ?? 0) > 0) {
																credit = +(found?.value ?? 0);
															} else {
																store.dispatch(setMessageBar({ show: true, message: `No amount found`, color: 'red', spinner: false, bottom: true }));
																return;
															}
														} else {
															setIsOpen(false);
															begunModule.processModule.showDetail = false;
															store.dispatch(setMessageBar({ show: true, message: `Check support payment docs`, color: 'red', spinner: false, bottom: true }));
															return;
														}

														if (paymentF?.value !== 'Cash') {
															purchase({
																user_id: authenticatedUser.id,
																id: generateNewUUID(),
																total: credit,
																product: `${sf.field_name}-${begunModule.processModule?.selectedComponent.payments?.stripe_id}`,
																notes: authenticatedUser.id,
																quantity: 1,
															})
																.pipe(first())
																.subscribe({
																	next: () => {
																		updateUserOptionsById(authenticatedUser, { ...authenticatedUser?.options, credit: +(+(authenticatedUser?.options?.credit ?? 0) + credit) })
																			.pipe(first())
																			.subscribe({
																				next: data => (authenticatedUser.options = data.options),
																			});
																	},
																});
														}
													}

													companyData.start = true;
													setLoading(true);
													addNewFieldToComponent({
														component_id: selectedComponent.id,
														index: 0,
														group: 99,
														value: selection,
														procedure_step_blueprint_field_id: sf.procedure_step_blueprint_field_id,
													}).subscribe({
														next: data => {
															setLoading(false);
															setIsOpen(false);
															store.dispatch(
																setProcessModule({
																	...begunModule.processModule,
																	showDetail: false,
																})
															);
														},
														error: () => { },
														complete: () => { },
													});
												}}
												fieldIcon={sf.icon}
												fieldLabel={sf.field_name}
												placeholder={'Select status...'}
											/>
										</div>
									);
								}

								return (
									<Button
										key={index}
										light
										title={sf.field_name}
										loading={loading}
										clicked={() => {
											if (sf.options.action === 'user' || sf.options.action === 'creator') {
												const targetUser = sf.options.action === 'user' ? authenticatedUser : companyUsersArray.find(user => user.id === selectedComponent.created_by?.id);
												if (begunModule.processModule?.module?.options?.credits) {
													const paymentF = begunModule?.processModule?.steps
														?.find(st => st.component_blueprints.filter(cb => cb.selected_fields.filter(sf => sf.data_type_name === 'Payments').length > 0).length > 0)
														?.component_blueprints.find(cb => cb.selected_fields.filter(sf => sf.data_type_name === 'Payments').length > 0)
														?.selected_fields.find(sf => sf.data_type_name === 'Payments');

													const found = taskProcessContext.newStepComponents
														.concat(taskProcessContext.components)
														.find(cmp => cmp.selected_fields.filter(sf => sf.field_name === paymentF?.options?.paymentField).length > 0)
														?.selected_fields?.find(sf => sf.field_name === paymentF?.options?.paymentField);

													let credit = 0;

													if (targetUser?.options?.credit === undefined || targetUser?.options?.credit === 0) {
														store.dispatch(setMessageBar({ show: true, message: `You need to setup funds for your wallet`, color: 'red', spinner: false, bottom: true }));
														begunModule.processModule.showDetail = false;
														setIsOpen(false);
														return;
													} else if (+(found?.value ?? 0) > 0 && paymentF?.value === 'Cash') {
														if ((+targetUser?.options?.credit ?? 0) < +found?.value) {
															store.dispatch(setMessageBar({ show: true, message: `Insufficient funds in wallet`, color: 'red', spinner: false, bottom: true }));
															begunModule.processModule.showDetail = false;
															setIsOpen(false);
															return;
														}
														credit = -(found?.value ?? 0);
													} else if (!begunModule.processModule?.selectedComponent?.payments?.stripe_id) {
														setIsOpen(false);
														begunModule.processModule.showDetail = false;
														store.dispatch(setMessageBar({ show: true, message: `Check support payment docs`, color: 'red', spinner: false, bottom: true }));
														return;
													}

													if (paymentF?.value === 'Cash') {
														updateUserOptionsById(targetUser, { ...targetUser?.options, credit: +(+(targetUser?.options?.credit ?? 0) + credit) })
															.pipe(first())
															.subscribe({
																next: data => {
																	targetUser.options = data.options;
																	purchase({
																		user_id: authenticatedUser.id,
																		id: generateNewUUID(),
																		total: credit,
																		product: `${sf.field_name}-${paymentF?.value}`,
																		notes: targetUser.id,
																		quantity: 1,
																	})
																		.pipe(first())
																		.subscribe({
																			error: () => store.dispatch(setMessageBar({ show: true, message: `Purchase error encountered`, color: 'red', spinner: false, bottom: true })),
																		});
																},
															});
													}
												}

												setLoading(true);
												addNewFieldToComponent({
													component_id: selectedComponent.id,
													index: 0,
													group: 99,
													value: targetUser.id,
													procedure_step_blueprint_field_id: sf.procedure_step_blueprint_field_id,
												}).subscribe({
													next: data => {
														assignComponent(selectedComponent.id, targetUser.id).subscribe({
															next: data => {
																setLoading(false);
																setIsOpen(false);
																store.dispatch(
																	setProcessModule({
																		...begunModule.processModule,
																		showDetail: false,
																	})
																);
															},
														});
													},
													error: () => { },
													complete: () => { },
												});
											} else if (sf.options.action === 'status') {
												setLoading(false);
											} else if (sf.options.action === 'date') {
												setLoading(false);
											} else if (sf.options.action === 'value') {
												setLoading(false);
											} else {
												setLoading(false);
											}
										}}
										style={{
											backgroundColor: 'green',
											color: 'white',
											width: isMobile ? '90%' : '100px',
											display: sf.options.action === 'user' && selectedComponent.component_fields?.find(cf => cf.procedure_step_blueprint_field_id === sf.procedure_step_blueprint_field_id)?.value ? 'none' : '',
										}}
									/>
								);
							})}
				</>
			)}


			{!forPrinting && (
				<Button
					title={'Print'}
					style={{ width: '100px', display: isMobile ? 'none' : '' }}
					light
					clicked={() => {
						setForPrinting(true);
					}}
				/>
			)}
			{forPrinting && <ReactToPrint trigger={() => <button className={cls.singleButton}>Print Now</button>} content={() => componentRef.current} />}

			{((status === undefined && authorized?.filter(a => authenticatedUser.positions.map(pos => pos.id).indexOf(a) > -1).length > 0) ||
				((status === undefined || status === 'New' || status === 'Show') && selectedComponent?.created_by?.id === authenticatedUser?.id) ||
				(status !== undefined &&
					!(status === 'Hide' || status === 'Closed' || status === companyData?.misc_info?.lock) &&
					authorized?.filter(a => authenticatedUser.positions.map(pos => pos.id).indexOf(a) > -1).length > 0 &&
					(begunModule?.processModule?.module?.options?.strict != true || selectedComponent?.created_by?.id !== authenticatedUser?.id)) ||
				(selectedComponent?.is_complete === false && selectedComponent?.created_by?.id === authenticatedUser?.id)) && (
					<Button
						title={'Edit'}
						style={{
							backgroundColor: isMobile ? 'var(--app-color-blue)' : '',
							color: isMobile ? 'white' : '',
							width: isMobile ? '90%' : '100px',
							display:
								companyData.status === 'trial_ended' || companyData.status === 'inactive'
									? 'none'
									: selectedComponent.component_fields?.filter(cf => cf.data_type_name === 'Action Button' && cf.options.action === 'user').length === 0
										? ''
										: selectedComponent.component_fields?.filter(cf => cf.data_type_name === 'Action Button' && cf.options.action === 'user' && cf.value === undefined).length === 0
											? selectedComponent.component_fields?.filter(cf => cf.data_type_name === 'Action Button' && cf.options.action === 'user' && cf.value === authenticatedUser?.id).length > 0
												? ''
												: 'none'
											: 'none',
						}}
						light
						clicked={() => {
							setIsOpen(false);
							setIsUpdating(true);
							begunModule.processModule.isUpdating = true;

							//clear all values first
							begunModule?.processModule?.steps?.length > 0 && begunModule?.processModule?.steps?.filter(step => step?.component_blueprints?.filter(cb => cb.selected_fields.filter(field => (field.value = undefined))));
							let component_array = [];
							R.uniqWith(R.eqProps('group'))(selectedComponent?.component_fields ?? []).map(f => {
								//component_array = [...component_array, { id: f.component_blueprint_id, temp_id: f.id, selected_fields: R.filter(R.propEq('group', f.group))(selectedComponent.component_fields) }];
								component_array = [...component_array, { id: f.component_blueprint_id, temp_id: f.id, selected_fields: R.filter(R.propEq('group', f.group))(selectedComponent.component_fields) }];
							});

							begunModule.processModule.steps.filter(step => {
								step.component_blueprints
									.filter(cb => (cb?.options?.asDashboard == false || !cb?.options?.asDashboard) && (cb?.options?.asReport == false || !cb?.options?.asReport) && (cb?.options?.asModuleList == false || !cb?.options?.asModuleList))
									.filter(cb => {
										cb.selected_fields.filter(sf => {
											const field_found = selectedComponent.component_fields.find(cf => cf.field_name === sf.field_name);
											if (field_found != undefined) {
												sf.value = field_found.value;
												sf.group = field_found.group;
											} else if (sf.data_type_name === 'Record') {
												const label_found = selectedComponent.component_fields.find(cf => cf.field_name === sf.options.name);
												if (label_found != undefined) {
													sf.value = label_found.value;
													sf.value = label_found.group;
												}
											}
										});
									});
							});

							if (localStorage.getItem('transaction')) {

								if (localStorage.getItem('transaction') === selectedComponent.id) {
									store.dispatch(setMessageBar({ show: true, message: `This transaction was previously not saved, reverting to saved record`, color: 'red', spinner: false, bottom: true }));
									taskProcessContext.components = JSON.parse(localStorage.getItem('components'));
									taskProcessContext.items = JSON.parse(localStorage.getItem('items'))
									taskProcessContext.newStepComponents = JSON.parse(localStorage.getItem('newStepComponents'))
								} else {
									taskProcessContext.components = component_array;
									taskProcessContext.items = JSON.parse(JSON.stringify(selectedComponent.component_items));
									taskProcessContext.newStepComponents = [];
								}
							} else {
								taskProcessContext.components = component_array;
								taskProcessContext.items = JSON.parse(JSON.stringify(selectedComponent.component_items));
								taskProcessContext.newStepComponents = [];
							}

							// taskProcessContext.components = component_array;
							// taskProcessContext.items = JSON.parse(JSON.stringify(selectedComponent.component_items));
							// taskProcessContext.newStepComponents = [];
							companyData.start = true;
							goToNextStep();
						}}
					/>
				)}
			{begunModule.processModule.module?.options.copyOutputs === true && selectedComponent?.created_by?.id === authenticatedUser.id && (
				<Button
					title={'Duplicate'}
					style={{ width: isMobile ? '90%' : '100px', backgroundColor: isMobile ? 'var(--app-color-blue)' : '', color: isMobile ? 'white' : '' }}
					light
					clicked={() => {
						setIsOpen(false);
						clickedAddButton();
						let component_array = [];
						R.uniqWith(R.eqProps('group'))(selectedComponent?.component_fields ?? []).map(f => {
							component_array = [...component_array, { id: f.component_blueprint_id, temp_id: f.id, selected_fields: R.filter(R.propEq('group', f.group))(selectedComponent.component_fields) }];
						});

						begunModule.processModule.steps.filter(step => {
							step.component_blueprints.filter(cb => {
								cb.selected_fields.filter(sf => {
									const field_found = selectedComponent.component_fields.find(cf => cf.procedure_step_blueprint_field_id === sf.procedure_step_blueprint_field_id);
									if (field_found != undefined) {
										if (sf.data_type_name === 'ID' || sf.data_type_name === 'Status') {
											sf.value = undefined;
										} else {
											sf.value = field_found.value;
										}
									} else if (sf.data_type_name === 'Record') {
										const label_found = selectedComponent.component_fields.find(cf => cf.field_name === sf.options.name);
										if (label_found != undefined) {
											sf.value = label_found.value;
										}
									}
								});
							});
						});
						setTaskProcessContext({
							action: processTaskAction.addModuleComponents,
							value: component_array,
						});
						setTaskProcessContext({
							action: processTaskAction.setModuleItems,
							value: selectedComponent.component_items,
						});
						setIsUpdating(false);
						begunModule.processModule.isUpdating = false;

						setSelectedComponent({});
					}}
				/>
			)}
			{authenticatedUser?.is_admin === true && (
				<Button
					title={'Delete'}
					style={{ width: isMobile ? '90%' : '100px', backgroundColor: isMobile ? 'red' : '', color: isMobile ? 'white' : '' }}
					light
					clicked={() => {
						if (begunModule?.processModule?.module?.options?.isMaster === true) {
							const masterDataFound = components
								.filter(co => co.module_id !== selectedComponent.module_id)
								.filter(
									it =>
										allModules
											.filter(mod => mod?.options?.isMaster === true)
											.map(mod => mod.id)
											.indexOf(it.module_id) != -1
								);
							const transactionsArray = components.filter(
								it =>
									allModules
										.filter(mod => mod?.options?.isMaster != true)
										.map(mod => mod.id)
										.indexOf(it.module_id) != -1
							);

							const found =
								masterDataFound.filter(component => component.component_fields.filter(cf => selectedComponent.component_fields.map(f => f.value).indexOf(cf.value) != -1).length > 0).length > 0 ||
								transactionsArray.filter(component => component.component_items.filter(item => selectedComponent.component_fields.map(cf => cf.value).indexOf(item.item_code) != -1).length > 0).length > 0 ||
								transactionsArray.filter(component => component.component_fields.filter(item => item.data_type !== 'output' && selectedComponent.component_fields.map(cf => cf.value).indexOf(item.value) != -1).length > 0).length > 0;

							setDependenciesFound(found);
							setShowDeleteComponent(!found);
						} else {
							setShowDeleteComponent(true);
						}
					}}
				/>
			)}
		</div>
	)
}
export const RenderComponentDetailView = React.forwardRef((props, ref) => {
	const { begunModule, allModules, companyData, selectedComponent, companyUsersArray, companyPositionsArray, print, components = [], bypassCustom = false } = props;
	const [detailComponents, setDetailComponents] = useState([]);


	const transactionItemsComponents = () => {
		const step = begunModule?.processModule?.steps?.find(
			step => step.component_blueprints.filter(cb => cb.selected_fields?.filter(sf => sf.options?.mapToItem === 'item_code' || sf.data_type_name === 'Transactions Field' || sf.data_type_name === 'Template').length > 0).length > 0
		);
		const cbp =
			step?.component_blueprints?.find(cb => cb.selected_fields?.filter(sf => sf.options?.mapToItem === 'item_code').length > 0) ??
			step?.component_blueprints?.find(cb => cb.selected_fields?.filter(sf => sf.data_type_name === 'Transactions Field').length > 0) ??
			step?.component_blueprints?.find(cb => cb.selected_fields?.filter(sf => sf.data_type_name === 'Template').length > 0) ??
			undefined;
		const templateName = cbp ? cbp.selected_fields?.find(sf => sf.data_type_name === 'Template')?.field_name : '';
		const custom_template = companyData?.misc_info?.templatesData?.find(template => template.name === templateName);

		const columns = custom_template
			? custom_template?.tlist?.length > 0
				? custom_template?.tlist
				: custom_template.config?.filter(cfg => cfg.value === 'Text' || cfg.value === 'Number' || cfg.value === 'Connector')
			: cbp
				? cbp.selected_fields.filter(sf => sf.visible == true && sf.mandatory == true && !(sf.options?.mapToItem === '' || sf.options?.mapToItem == undefined || sf.options?.mapToItem == null))
				: [];
		return (
			selectedComponent?.component_items?.length > 0 && columns && (
				<div className={cls.accordionContainer} style={{ border: isMobile ? '0' : '', padding: isMobile ? '0' : '50px' }} key={1}>
					<p className={cls.cardTitle}>{'Items'}</p>
					<CustomTemplateModuleList
						className={cls.itemsTable}
						tableHostSimple={cls.tableHostSimple}
						tableStyle={{ minWidth: isMobile ? '100px' : '730px', width: isMobile ? '100%' : '' }}
						columns={
							columns
								? columns.map(cf => {
									return custom_template ? cf.label : cf.field_name;
								})
								: ['Product ID', 'Quantity', 'Price']
						}
						footer={
							columns ?

								selectedComponent?.component_items?.length > 0 ?
									(isNaN(+selectedComponent.component_items[0]?.quantity) || isNaN(+selectedComponent.component_items[0]?.value) || isNaN(+selectedComponent.component_items[0]?.alt_value)) ? undefined :
										columns.map(cf => {
											return custom_template
												? cf.key === 'item_code'
													? 'Totals'
													: `${R.reduce((a, b) => +a + +b[cf.key] ?? 0, 0, selectedComponent.component_items ?? [])}`
												: cf.options?.mapToItem === 'item_code'
													? 'Totals'
													: `${R.reduce((a, b) => +a + +b[cf.options?.mapToItem] ?? 0, 0, selectedComponent.component_items ?? [])}`;
										}) : undefined
								: ['Totals', `${R.reduce((a, b) => +a + +b.quantity ?? 0, 0, selectedComponent.component_items ?? [])}`, `${R.reduce((a, b) => +a + +b.value ?? 0, 0, selectedComponent.component_items ?? [])}`]
						}
						list={selectedComponent?.component_items?.map(item => {
							if (custom_template?.tlist?.length > 0) {
								let out = {};
								custom_template.tlist.filter(li => {
									out = { ...out, [li?.label]: item[li?.key] };
								});
								return out;
							}

							const code = item.item_code;
							const qty = item.quantity;
							const discount = item.alt_value ?? 0;
							const price = item.value ?? 0;
							const total = item.total ?? 0;

							return custom_template != undefined
								? {
									[custom_template.config.find(op => op.key === 'item_code')?.label ?? 'Item Code']: <div className={cls.templateInformation}>{code}</div>,
									[custom_template.config.find(op => op.key === 'value')?.label ?? 'Price']: <div className={cls.templateInformation}>{price}</div>,
									[custom_template.config.find(op => op.key === 'total')?.label ?? 'Total']: <div className={cls.templateInformation}>{total}</div>,
									[custom_template.config.find(op => op.key === 'quantity')?.label ?? 'Quantity']: <div className={cls.templateInformation}>{qty}</div>,
									[custom_template.config.find(op => op.key === 'alt_value')?.label ?? 'Discount']: <div className={cls.templateInformation}>{discount}</div>,
									id: item.id,
								}
								: columns?.find(col => col.options?.mapToItem === 'item_code' || col.options?.mapToItem === 'value' || col.options?.mapToItem === 'total' || col.options?.mapToItem === 'quantity' || col.options?.mapToItem === 'alt_value')
									? {
										[columns.find(col => col.options?.mapToItem === 'item_code')?.field_name ?? 'Product ID']: <div className={cls.templateInformation}>{code}</div>,
										[columns.find(col => col.options?.mapToItem === 'value')?.field_name ?? 'Price']: <div className={cls.templateInformation}>{price}</div>,
										[columns.find(col => col.options?.mapToItem === 'total')?.field_name ?? 'Total']: <div className={cls.templateInformation}>{total}</div>,
										[columns.find(col => col.options?.mapToItem === 'quantity')?.field_name ?? 'Quantity']: <div className={cls.templateInformation}>{qty}</div>,
										[columns.find(col => col.options?.mapToItem === 'alt_value')?.field_name ?? 'Discount']: <div className={cls.templateInformation}>{discount}</div>,
										[columns.find(col => col.options?.mapToItem === 'alt_value')?.field_name ?? 'Actual']: <div className={cls.templateInformation}>{discount}</div>,
										id: item.id,
									}
									: { ['Product ID']: code, ['Quantity']: qty, ['Price']: price };
						})}
					/>
				</div>
			)
		);
	};


	useEffect(() => {
		let component_array = [];

		R.uniqWith(R.eqProps('group'))(selectedComponent?.component_fields ?? []).map(f => {
			component_array = [...component_array, { id: f.component_blueprint_id, selected_fields: R.filter(R.propEq('group', f.group))(selectedComponent.component_fields) }];
		});

		if (begunModule?.processModule?.completeSteps?.length > 0) {

			let array = [<div key={0} style={{ display: props?.noHeader ? 'none' : '', height: 'auto' }}>

				{print && <img alt="lg" onError={(e) => e.target.src = 'https://i.quotev.com/b2gtjqawaaaa.jpg'} src={companyData?.logo ?? 'https://i.quotev.com/b2gtjqawaaaa.jpg'} />}
				{print && <p className={cls.detailLabel} style={{ fontSize: '26px' }}>{begunModule?.processModule?.module?.name}</p>}

			</div>, transactionItemsComponents()];

			begunModule.processModule.completeSteps.map(step => {
				step.component_blueprints
					.filter(cb => cb?.options?.asModuleList == false || cb?.options?.asModuleList == undefined)
					.map((cb, idx) => {
						const fields = selectedComponent?.component_fields?.filter(cf => cf.component_blueprint_id === cb.id) ?? [];
						const allEmptyValues = fields?.find(field => !(field.value === '' || field.value === undefined || field.value === null)) ? true : false;

						if (fields?.length > 0 && cb.selected_fields?.filter(sf => sf.visible == true).length > 0) {
							if (cb.options !== null) {
								if (cb.options.allowMultipleInputs === false || cb.options.allowMultipleInputs === undefined || cb.options.allowMultipleInputs === null) {
									array = [
										...array,
										<div key={array.length} style={{ display: allEmptyValues ? '' : 'none' }}>
											<StaticAccordionModule
												step={step}
												isInherited={false}
												nextButtonClicked={() => { }}
												isMandatoryError={false}
												stepId={step.id}
												blueprintIndex={idx}
												setIsMandatoryError={() => { }}
												component={{ ...cb, created_date: selectedComponent.created_date, values: component_array.find(c => c.id === cb.id)?.selected_fields ?? [] }}
												begunModule={begunModule}
												allModules={allModules}
												companyData={companyData}
												components={component_array}
												allComponents={components}
												companyUsersArray={companyUsersArray}
												selectedComponent={selectedComponent}
											/>
										</div>,
									];
								}
							} else {
								array = [
									...array,
									<div key={array.length}>
										<StaticAccordionModule
											step={step}
											isInherited={false}
											nextButtonClicked={() => { }}
											isMandatoryError={false}
											stepId={step.id}
											blueprintIndex={idx}
											setIsMandatoryError={() => { }}
											component={{ ...cb, created_date: selectedComponent.created_date, values: component_array.find(c => c.id === cb.id)?.selected_fields }}
											begunModule={begunModule}
											allModules={allModules}
											companyData={companyData}
											components={component_array}
											allComponents={components}
											companyUsersArray={companyUsersArray}
											selectedComponent={selectedComponent}
										/>
									</div>,
								];
							}
						}
					});
			});

			if (begunModule?.processModule?.completeSteps?.find(st => st.component_blueprints?.filter(cb => cb.selected_fields.filter(sf => sf.data_type_name === 'Template').length > 0).length > 0 && bypassCustom == false)) {

				setDetailComponents(<RenderCustomComponentDetailView
					ref={ref}
					print={print}
					begunModule={begunModule}
					allModules={allModules}
					companyData={companyData}
					components={components}
					selectedComponent={selectedComponent}
					companyUsersArray={companyUsersArray}
					companyPositionsArray={companyPositionsArray}
				>
					{array}
					<StaticAccordionPayments component={selectedComponent} />
					<StaticAccordionCreator component={selectedComponent} />
				</RenderCustomComponentDetailView>);

			} else {

				setDetailComponents([...array, <StaticAccordionPayments component={selectedComponent} key={array.length + 1} />, <StaticAccordionCreator component={selectedComponent} key={array.length + 2} />
				]);
			}
		}

	}, [selectedComponent, print]);


	const shares = (
		<div>
			{(selectedComponent?.shared_users?.length > 0 || selectedComponent?.shared_positions?.length > 0) && (
				<div className={cls.accordionContainer}>
					<p className={cls.cardTitle}>{'Shares'}</p>
					<div style={{ display: 'flex' }}>
						{selectedComponent?.shared_positions?.length > 0 && (
							<ModuleLists
								className={cls.sharesTable}
								columns={['Position']}
								list={selectedComponent?.shared_positions?.map(val => {
									const u = companyPositionsArray?.find(pos => pos.value === val.id);
									return { ['Position']: u?.label };
								})}
							/>
						)}

						{selectedComponent?.shared_users?.length > 0 && (
							<ModuleLists
								className={cls.sharesTable}
								columns={['User']}
								list={selectedComponent?.shared_users?.map(val => {
									const u = companyUsersArray?.find(usr => usr.id === val.id);
									return { ['User']: `${u?.first_name} ${u?.last_name}` };
								})}
							/>
						)}
					</div>
				</div>
			)}
		</div>
	);


	return (
		<div ref={ref} style={{ padding: print ? '50px 120px 50px 100px' : '', alignSelf: 'center', width: '100%' }}>
			{detailComponents}
			{shares}
		</div>
	);
});

const mapStateToProps = store => {
	const { begunModule, allModules, companyData, components, rememberPath, lastUpdates, companyUsersArray, products, transactions, companyProcesses, companyPositionsArray, cart, blueprints } = store.flowReducer;
	const { authenticatedUser } = store.authReducer;
	return {
		begunModule,
		allModules,
		companyData,
		components,
		rememberPath,
		lastUpdates,
		companyUsersArray,
		products,
		transactions,
		companyProcesses,
		authenticatedUser,
		companyPositionsArray,
		cart,
		blueprints,
	};
};
const mapDispatchToProps = dispatch => {
	return { onSetSelectedProcess: process => dispatch(setSelectedProcess(process)) };
};
export default connect(mapStateToProps, mapDispatchToProps)(ModuleStep);
