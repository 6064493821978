import classNames from 'classnames';
import moment from 'moment';
import { debounce } from 'lodash';
import 'rc-datetime-picker/dist/picker.css';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { first, take } from 'rxjs/operators';
import { Button, DatePickerComp, SelectD, SelectWithSearch, UnfocusableWrapper } from '..';
import { UploadIcon } from '../../../assets/images/icons';
import { setCompanyData, setMessageBar } from '../../../reduxStore/actions/flowActions';
import { getAllCompanies, getCompanyDetails, setCompanyLogo, updateCompanyDetailsById } from '../../../services/company.service';
import { industryFields, numberOfEmployees, uncachedUrl } from '../../../shared/utility';
import { DatePickerRC, po } from '../../common';
import cls from './CompanyDataUpdate.module.scss';
import { usePermissions } from '../../../hooks/usePermisions';
import { isMobile } from 'react-device-detect';

export const CompanyDataUpdate = props => {
	const { settingsContainerClass, datePickerRC, feValidation, bypassTriggerValidation, setElementsTouched, sendCompanyDetails } = props;
	const { companyData, companyPositionsArray } = useSelector(state => state.flowReducer);
	const { isOnboarding } = useSelector(store => store.modalsReducer);
	const dispatch = useDispatch();
	const [permis] = usePermissions();
	const onDrop = useCallback(
		acceptedFiles =>
			acceptedFiles.forEach(file => {
				setLogoError('');

				dispatch(setCompanyData({ logo: '' }));

				if (file?.type?.toString().indexOf('image/') > -1 && ['jpeg', 'jpg', 'png'].indexOf(file?.type?.toString().split('/')[1]) > -1) {
					let reader = new FileReader();
					reader.readAsDataURL(file);
					reader.onloadend = _e => {
						const formData = new FormData();
						formData.append('logo', file);
						setCompanyLogo(file)
							.pipe(first())
							.subscribe({
								next: _data => { },
								error: error => {
									setLogoError(error?.data);
								},
								complete: () => { },
							});
					};
				}
			}),
		[]
	);
	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
	const [logoError, setLogoError] = useState('');
	const [compantDetails_apiError, setCompanyDetails_ApiError] = useState('');

	useEffect(() => {
		getCompanyDetails()
			.pipe(first())
			.subscribe({});
	}, []);

	const [fieldsErrors, setFieldErrors] = useState({
		name: '',
		display_name: '',
		founding_date: '',
		employees_no: '',
		industry: '',
	});

	const settingFieldErors = useCallback(
		val => {
			!bypassTriggerValidation && setFieldErrors({ ...val });
		},
		[bypassTriggerValidation]
	);
	useEffect(() => {
		settingFieldErors({ ...feValidation });
	}, [feValidation]);

	const renderDropZone = useCallback(() => {
		return permis[po.company.profile.update] === true ? (
			<div className={cls.logoDragContainer} app-drag-active={isDragActive ? 'true' : 'false'} {...getRootProps()} style={{ border: companyData?.logo?.length > 2 ? '0' : 'solid silver 2px' }}>
				<input {...getInputProps()} className={cls.logoDragInput} accept="*,image/png, image/jpeg, image/jpg" />
				{isDragActive ? (
					<div className={cls.logoContentContainer}>
						<UploadIcon className={cls.logoUploadIcon} />
					</div>
				) : (
					<div className={cls.logoContentContainer}>
						{companyData?.logo?.length > 2 ? (
							<img alt="lg" src={uncachedUrl(companyData?.logo)} className={cls.logoUrlImg} />
						) : (
							<div className={cls.logoUploadPictureContainer}>
								<UploadIcon className={cls.logoUploadIcon} />
							</div>
						)}
					</div>
				)}
			</div>
		) : (
			<div className={cls.logoDragContainer} app-drag-active={'false'} style={{ border: '0' }}>
				<div className={cls.logoContentContainer}>{companyData?.logo?.length > 2 && <img alt="lg" src={uncachedUrl(companyData?.logo)} className={cls.logoUrlImg} />}</div>
			</div>
		);
	}, [companyData, permis]);

	const debounceCompany = useCallback(debounce(sendCompanyDetails ?? function () { }, 3000), []);

	const companyNameChangeHandler = e => {
		dispatch(setCompanyData({ name: e.target.value }));
		sendCompanyDetails && e.target.value.length > 4 && debounceCompany({ name: e.target.value });
	};
	const companyDisplayNameChangeHandler = e => {
		dispatch(setCompanyData({ display_name: e.target.value }));
		sendCompanyDetails && debounceCompany({ display_name: e.target.value });
	};
	const foundingDateChangeHandler = date => {
		dispatch(setCompanyData({ founding_date: moment(date).format('YYYY-MM-DD') }));
		setElementsTouched(c => ({ ...c, founding_date: true }));
		sendCompanyDetails && sendCompanyDetails({ founding_date: moment(date).format('YYYY-MM-DD') });
	};

	const employeesChangeHandler = e => {
		e?.value?.length > 0 && dispatch(setCompanyData({ employees_no: e.value }));
		setElementsTouched(c => ({ ...c, employees_no: numberOfEmployees.some(i => i.value === e.value) }));
		sendCompanyDetails && sendCompanyDetails({ employees_no: e.value });
	};

	const singleUserChangeHandler = e => {
		e?.value?.length > 0 && dispatch(setCompanyData({ employees_no: e.value }));
		setElementsTouched(c => ({ ...c, core_values: true }));
		sendCompanyDetails && sendCompanyDetails({ core_values: { ...companyData.core_values, singleUser: e.value } });
	};
	const industryChangeHandler = e => {
		dispatch(setCompanyData({ industry: e.target.value }));
		setElementsTouched(c => ({ ...c, industry: true }));
		sendCompanyDetails && sendCompanyDetails({ industry: e.target.value });
	};

	const companyMessagingHandler = e => {
		localStorage.setItem('showInfo', true);
		dispatch(setCompanyData({ company_standards: e }));
		setElementsTouched(c => ({ ...c, company_standards: true }));
		sendCompanyDetails && debounceCompany({ company_standards: e });
	};
	return (
		<div className={cls.host}>
			<LabeledContainer label={isOnboarding ? '' : 'Basic Information'}>
				<div className={cls.companyDataWrapper}>
					<LabeledField label={companyData?.logo?.length > 2 ? '' : 'Company Logo'}>
						{renderDropZone()}
						{logoError?.length > 0 && <p className={cls.logoErrorText}>{logoError}</p>}
					</LabeledField>
					<UnfocusableWrapper className={classNames(cls.companySettingsContainer, settingsContainerClass)}>
						<div style={{ width: isMobile ? '100%' : '60%', marginBottom: '10px', xdisplay: isOnboarding ? 'none' : '' }}>
							<LabeledField label={'Company Name'} error={fieldsErrors.name}>
								<input
									readOnly={permis[po.company.profile.update] === false}
									type="text"
									name="comapny-name"
									className={cls.inputField}
									placeholder="Company Name"
									value={companyData?.name ?? ''}
									onChange={val => companyNameChangeHandler(val)}
									onFocus={() => {
										setElementsTouched?.(c => ({ ...c, name: true }));
									}}
								/>
							</LabeledField>
						</div>
						<div style={{ width: isMobile ? '100%' : '60%', marginBottom: '10px' }}>
							<div style={{ flex: 1 }}>
								<LabeledField label={'Display Name'} error={fieldsErrors.display_name}>
									<input
										readOnly={permis[po.company.profile.update] === false}
										type="text"
										name="company-display-name"
										className={cls.inputField}
										placeholder="Company Name"
										value={companyData?.display_name ?? ''}
										onChange={val => companyDisplayNameChangeHandler(val)}
										onFocus={() => {
											setElementsTouched?.(c => ({ ...c, display_name: true }));
										}}
									/>
								</LabeledField>
							</div>
							<div style={{ width: isMobile ? '100%' : '60%', margin: '10px 0 10px 0' }}>
								<LabeledField label={'Flowhero Page URL'} error={fieldsErrors.name}>
									<input type="text" name="messages" disabled={true} className={cls.inputField} value={`https://adpgxsoftware.com/#/flowhero/home/${companyData.slug}`} />
								</LabeledField>
							</div>
							<div style={{ width: isMobile ? '100%' : '60%', margin: '10px 0 10px 0', display: isOnboarding ? 'none' : '' }}>
								<LabeledField label={'Founding Date'} error={fieldsErrors.founding_date}>
									{permis[po.company.profile.update] === true ? (
										datePickerRC ? (
											<DatePickerRC nonEditable={po.company.profile.update} value_date={companyData.founding_date} maxDate={moment()} onDateChange={foundingDateChangeHandler} containerClassName={cls.datePickerRCContainer} />
										) : (
											<DatePickerComp
												nonEditable={po.company.profile.update}
												value={moment(companyData.founding_date ?? moment()).format('Do of MMMM YYYY')}
												selected={new Date(moment(companyData?.founding_date ?? moment().format('YYYY-MM-DD'), 'YYYY-MM-DD'))}
												dateChangeHandler={foundingDateChangeHandler}
												inputContainerClassName={cls.foundingDateInputContainer}
												inputClassName={cls.inputFieldOverlay}
												inputStyle={{
													textAlign: companyData.founding_date?.length > 0 ? 'left' : 'center',
													border: 'none',
													height: 'auto',
												}}
												hasCalendarIcon
												placeholder={'Due Date'}
											/>
										)
									) : (
										<input readOnly={true} type="text" name="input-founding_date" className={cls.inputField} value={moment(companyData.founding_date ?? moment()).format('Do of MMMM YYYY') ?? ''} />
									)}
								</LabeledField>
							</div>
						</div>

						<div style={{ width: isMobile ? '100%' : '60%', marginBottom: '10px' }}>
							<div style={{ flex: 1 }}>
								<LabeledField label={'Company Size'} error={fieldsErrors.employees_no}>
									{permis[po.company.profile.update] === true ? (
										<SelectD
											title=""
											placeholder="No. of Employees"
											tmiClassName={cls.selectText}
											molclassname={cls.employeesMOL}
											moliclassname={cls.rowsField}
											className={cls.inputField}
											onChange={employeesChangeHandler}
											items={numberOfEmployees}
											defaultSelected={numberOfEmployees.find(i => i.value === companyData.employees_no)}
										/>
									) : (
										<input readOnly={true} type="text" name="input-number-of-employes" className={cls.inputField} value={numberOfEmployees.find(i => i.value === companyData.employees_no)?.label ?? ''} />
									)}
								</LabeledField>
							</div>
							<div style={{ flex: 1, display: isOnboarding ? 'none' : '' }}>
								<LabeledField label={'Industry'} error={fieldsErrors.industry}>
									{permis[po.company.profile.update] === true ? (
										<SelectWithSearch
											id={'industry-selection'}
											triggerType={'chevron'}
											itemType={'no-item-icons'}
											className={cls.inputField}
											inputClassName={cls.selectText}
											molclassname={cls.industryMOL}
											moliclassname={cls.industryMOLI}
											title=""
											placeholder={'Select industry...'}
											onChange={val => {
												industryChangeHandler({ target: { value: val.label } });
											}}
											defaultSelectedItem={industryFields?.find(i => i.label == companyData.industry)}
											items={industryFields}
										/>
									) : (
										<input readOnly={true} type="text" name="input-number-of-employes" className={cls.inputField} value={industryFields?.find(i => i.label == companyData.industry)?.label ?? ''} />
									)}
								</LabeledField>
							</div>
						</div>
						<div>{compantDetails_apiError?.length > 0 && <p className={cls.errorGeneralText}>{compantDetails_apiError} </p>}</div>
					</UnfocusableWrapper>
				</div>
			</LabeledContainer>

			<br />
			{permis[po.company.profile.update] === true && <LabeledContainer label={isOnboarding ? '' : 'Advanced Settings'}>
				<div className={cls.companyDataWrapper}>
					<UnfocusableWrapper className={classNames(cls.companySettingsContainer, settingsContainerClass)}>
						<div style={{ width: isMobile ? '100%' : '60%', marginBottom: '10px' }}>
							{companyData?.misc_info?.sauth && (
								<div style={{ flex: 1, display: isOnboarding ? 'none' : '', marginBottom: '10px' }}>
									<LabeledField label={'Position assigned to single-position users'} error={fieldsErrors.industry}>
										{permis[po.company.profile.update] === true && (
											<SelectWithSearch
												id={'industry-selection'}
												triggerType={'chevron'}
												itemType={'no-item-icons'}
												className={cls.inputField}
												inputClassName={cls.selectText}
												molclassname={cls.industryMOL}
												moliclassname={cls.industryMOLI}
												title=""
												placeholder={''}
												onChange={position => {
													singleUserChangeHandler(position);
												}}
												defaultSelectedItem={companyPositionsArray?.find(i => i.value == companyData?.core_values?.singleUser)}
												items={companyPositionsArray}
											/>
										)}
									</LabeledField>
								</div>
							)}
							<div style={{ marginTop: '0px', display: isOnboarding ? 'none' : permis[po.company.profile.update] === true ? '' : 'none' }}>
								<div style={{ marginTop: '1px', display: permis[po.company.profile.update] === true ? '' : 'none' }}>
									<div style={{ marginBottom: '10px' }}>
										<LabeledField label={'Welcome Message'} error={fieldsErrors.name}>
											<textarea
												type="text"
												name="messages"
												className={cls.inputField}
												placeholder="Default message shown after logging in"
												value={companyData?.company_standards?.welcome ?? ''}
												onChange={val => companyMessagingHandler({ ...companyData?.company_standards, welcome: val.target.value })}
												onFocus={() => {
													setElementsTouched?.(c => ({ ...c, company_standards: true }));
												}}
												style={{ height: '100px' }}
											/>
										</LabeledField>
									</div>

									<div style={{ marginBottom: '10px' }}>
										<LabeledField label={'Welcome Banner'} error={fieldsErrors.name}>
											<textarea
												type="text"
												name="messages"
												className={cls.inputField}
												placeholder={'Background image shown after logging in. Use .jpg, .png URLs'}
												value={companyData?.company_standards?.banner ?? ''}
												onChange={val => companyMessagingHandler({ ...companyData?.company_standards, banner: val.target.value })}
												onFocus={() => {
													setElementsTouched?.(c => ({ ...c, company_standards: true }));
												}}
												style={{ height: '100px' }}
											/>
										</LabeledField>
									</div>
									<div style={{ marginBottom: '10px' }}>
										<LabeledField label={'Home Page Banner'} error={fieldsErrors.name}>
											<textarea
												type="text"
												name="messages"
												className={cls.inputField}
												placeholder={'Background image for your home page. Use .jpg, .png URLs'}
												value={companyData?.company_standards?.hbanner ?? ''}
												onChange={val => companyMessagingHandler({ ...companyData?.company_standards, hbanner: val.target.value })}
												onFocus={() => {
													setElementsTouched?.(c => ({ ...c, company_standards: true }));
												}}
												style={{ height: '100px' }}
											/>
										</LabeledField>
									</div>
									<div style={{ marginBottom: '10px' }}>
										<LabeledField label={'Header message'} error={fieldsErrors.name}>
											<textarea
												type="text"
												name="messages"
												className={cls.inputField}
												placeholder="Primary text description used in your home page"
												value={companyData?.company_standards?.message ?? ''}
												onChange={val => companyMessagingHandler({ ...companyData?.company_standards, message: val.target.value })}
												onFocus={() => {
													setElementsTouched?.(c => ({ ...c, company_standards: true }));
												}}
												style={{ height: '200px' }}
											/>
										</LabeledField>
									</div>
									<div style={{ marginBottom: '10px' }}>
										<LabeledField label={'Header sub message'} error={fieldsErrors.name}>
											<textarea
												type="text"
												name="messages"
												className={cls.inputField}
												placeholder="Secondary text description used in your home page"
												value={companyData?.company_standards?.subMessage ?? ''}
												onChange={val => companyMessagingHandler({ ...companyData?.company_standards, subMessage: val.target.value })}
												onFocus={() => {
													setElementsTouched?.(c => ({ ...c, company_standards: true }));
												}}
												style={{ height: '200px' }}
											/>
										</LabeledField>
									</div>
								</div>
							</div>
						</div>
						<div></div>
						<div>{compantDetails_apiError?.length > 0 && <p className={cls.errorGeneralText}>{compantDetails_apiError} </p>}</div>
					</UnfocusableWrapper>
				</div>
			</LabeledContainer>}
		</div>
	);
};

const LabeledContainer = props => {
	const { label, children } = props;
	return (
		<div className={cls.labeledContainerHost}>
			<p className={cls.labeledContainerLabel}>{label}</p>
			{children}
		</div>
	);
};
const LabeledField = props => {
	const { label, error, children } = props;
	return (
		<div className={cls.labeledFieldHost}>
			<p className={cls.labeledFieldLabel}>{label}</p>
			{children}
			{error?.length > 0 && <p className={cls.labeledFieldError}>{error}</p>}
		</div>
	);
};

export const CompanyAdmin = props => {
	const { company, user } = props;
	const dispatch = useDispatch();
	const [date, setDate] = useState(new Date());

	//const [date, setDate] = useState(company?.trial_ends_at ? company?.trial_ends_at : new Date());
	const [companies, setCompanies] = useState([]);
	const [selectedCompany, setSelectedCompany] = useState(company);
	const [ruleType, setRuleType] = useState();
	const [template, setTemplate] = useState();
	const [loading, setLoading] = useState(false);
	const [companyStatusArray,] = useState([
		{ label: 'On Trial', value: 'on_trial' },
		{ label: 'Trial Ended', value: 'trial_ended' },
		{ label: 'Active', value: 'active' },
		{ label: 'Inactive', value: 'inactive' },
		{ label: 'New', value: 'onboarding' },
	]);
	const [statusArray, ] = useState([
		{ label: 'Ongoing', value: 'Ongoing' },
		{ label: 'Pending', value: 'Pending' },
		{ label: 'Awaiting Payment', value: 'Awaiting Payment' },
		{ label: 'Awaiting Fulfillment', value: 'Awaiting Fulfillment' },
		{ label: 'Awaiting Shipment', value: 'Awaiting Shipment' },
		{ label: 'Awaiting Pickup', value: 'Awaiting Pickup' },
		{ label: 'Partially Shipped', value: 'Partially Shipped' },
		{ label: 'Completed', value: 'Completed' },
		{ label: 'Shipped', value: 'Shipped' },
		{ label: 'Cancelled', value: 'Cancelled' },
		{ label: 'Declined', value: 'Declined' },
		{ label: 'Refunded', value: 'Refunded' },
		{ label: 'Disputed', value: 'Disputed' },
		{ label: 'Approved', value: 'Approved' },
		{ label: 'Accepted', value: 'Accepted' },
		{ label: 'Done', value: 'Done' },
		{ label: 'Closed (Will lock Edit)', value: 'Closed' },
	]);
	const rulesTypeArray = [{ label: 'Status', value: 'status' }];
	useEffect(() => {
		getAllCompanies()
			.pipe(take(1))
			.subscribe({
				next: data => {
					setCompanies(
						data
							.filter(co => co.id !== '-')
							.map(co => {
								return { label: co.name, value: co.id, company: co };
							})
					);
				},
				error: error => { },
				complete: () => { },
			});
	}, []);
console.log('date', date, selectedCompany)
	return (
		<div style={{ width: '30%', marginBottom: '500px', minWidth: '300px' }}>
			{user.user_type === 'superadmin' && (
				<LabeledField label={'Select Company'}>
					<SelectWithSearch
						id={'company-selection'}
						triggerType={'chevron'}
						itemType={'no-item-icons'}
						className={cls.inputField}
						inputClassName={cls.selectText}
						molclassname={cls.industryMOL}
						moliclassname={cls.industryMOLI}
						onChange={val => {
							setSelectedCompany(undefined);
							setTimeout(() => {
								setSelectedCompany(val.company);
								setDate(val.company.trial_ends_at)
							}
							);
						}}
						items={companies}
					/>
				</LabeledField>
			)}
			<br />
			<LabeledField label={'ID:'}>
				<input type="text" className={cls.inputField} value={selectedCompany?.id} disabled />
			</LabeledField>
			{selectedCompany && user.user_type === 'superadmin' && (
				<LabeledField label={'Company Status'}>
					<SelectWithSearch
						id={'status-selection'}
						triggerType={'chevron'}
						itemType={'no-item-icons'}
						className={cls.inputField}
						inputClassName={cls.selectText}
						molclassname={cls.industryMOL}
						moliclassname={cls.industryMOLI}
						onChange={val => {
							selectedCompany.status = val.value;
						}}
						defaultSelectedItem={companyStatusArray.find(i => i.value === selectedCompany.status)}
						items={companyStatusArray}
					/>
				</LabeledField>
			)}
			<br />
			{selectedCompany && user.user_type === 'superadmin' && (
				<LabeledField label={'Trial Date End'}>
			<DatePickerComp

// value={moment(companyData.founding_date ?? moment()).format('Do of MMMM YYYY')}
// selected={new Date(moment(companyData?.founding_date ?? moment().format('YYYY-MM-DD'), 'YYYY-MM-DD'))}



				value={moment(date).format('Do MMMM YYYY')}
				dateChangeHandler={date => setDate(date)}
				selected={new Date(moment(date, 'YYYY-MM-DD'))}
				iconClassName={cls.calendar}
				inputContainerClassName={cls.inputField}
				inputStyle={{
					textAlign: 'left',
					border: 'none',
					fontSize: '16px',
				}}
				hasCalendarIcon={true}
			/>
			</LabeledField>
			)}
			{Object.keys(selectedCompany?.misc_info ?? []).map((key, index) => {
				if (typeof selectedCompany?.misc_info[key] === 'string' && !(key === 'lock' || (key === 'map' && user.user_type !== 'superadmin'))) {
					return (
						<LabeledField key={index} label={key}>
							<input type="text" className={cls.inputField} value={selectedCompany?.misc_info[key]} onChange={e => setSelectedCompany({ ...selectedCompany, misc_info: { ...selectedCompany.misc_info, [key]: e.target.value } })} />
						</LabeledField>
					);
				}
			})}
			<br />

			{selectedCompany && user.user_type === 'superadmin' && (
				<LabeledField label={'Set Single User Position'}>
					<SelectWithSearch
						id={'position-selection'}
						triggerType={'chevron'}
						itemType={'no-item-icons'}
						className={cls.inputField}
						inputClassName={cls.selectText}
						molclassname={cls.industryMOL}
						moliclassname={cls.industryMOLI}
						onChange={val => {
							if (val) selectedCompany.core_values = { ...selectedCompany?.core_values, singleUser: val.value };
						}}
						defaultSelectedItem={(selectedCompany?.positions?.map(pos => {
							return { label: pos.name, value: pos.id, position: pos };
						}) ?? [])?.find(i => i.value == selectedCompany?.core_values?.singleUser)}

						items={
							selectedCompany?.positions?.map(pos => {
								return { label: pos.name, value: pos.id, position: pos };
							}) ?? []
						}
					/>
				</LabeledField>
			)}
			{selectedCompany && (
				<LabeledField label={'Status locked condition'}>
					<SelectWithSearch
						id={'status-selection'}
						triggerType={'chevron'}
						itemType={'no-item-icons'}
						className={cls.inputField}
						inputClassName={cls.selectText}
						molclassname={cls.industryMOL}
						moliclassname={cls.industryMOLI}
						onChange={val => {
							selectedCompany.misc_info = { ...selectedCompany?.misc_info, lock: val.value };
						}}
						defaultSelectedItem={statusArray.concat(selectedCompany?.misc_info?.status ?? []).find(i => i.label === selectedCompany.misc_info.lock)}
						items={statusArray.concat(selectedCompany?.misc_info?.status ?? [])}
					/>
				</LabeledField>
			)}

			{selectedCompany && (
				<LabeledField label={'Rule Type'}>
					<SelectWithSearch
						id={'position-selection'}
						triggerType={'chevron'}
						itemType={'no-item-icons'}
						className={cls.inputField}
						inputClassName={cls.selectText}
						molclassname={cls.industryMOL}
						moliclassname={cls.industryMOLI}
						onChange={val => {
							setRuleType(val.value);
						}}
						//defaultSelectedItem={companyPositionsArray?.find(i => i.value == companyData?.core_values?.singleUser)}

						items={rulesTypeArray}
					/>
				</LabeledField>
			)}
			{selectedCompany && (
				<LabeledField label={''}>
					<SelectWithSearch
						id={'position-selection'}
						triggerType={'chevron'}
						itemType={'no-item-icons'}
						className={cls.inputField}
						inputClassName={cls.selectText}
						molclassname={cls.industryMOL}
						moliclassname={cls.industryMOLI}
						onChange={val => {
							selectedCompany.coreValues = { ...selectedCompany?.coreValues, singleUser: val.value };
						}}
						items={selectedCompany?.misc_info?.rules
							?.filter(rul => rul.type === ruleType)
							?.map(rul => {
								return { label: rul.valueCondition, value: rul.valueCondition, rule: rul };
							})}
					/>
				</LabeledField>
			)}
			{selectedCompany && (
				<LabeledField label={'Templates'}>
					<SelectWithSearch
						id={'position-selection'}
						triggerType={'chevron'}
						itemType={'no-item-icons'}
						className={cls.inputField}
						inputClassName={cls.selectText}
						molclassname={cls.industryMOL}
						moliclassname={cls.industryMOLI}
						onChange={temp => {

							setTemplate(temp.value)
						}}
						items={selectedCompany?.misc_info?.templatesData
							?.map(rul => {
								return { label: rul.name, value: rul };
							}).concat([{ label: 'New Template', value: { name: 'New', key: {}, config: [], slist: [], tlist: [] } }])}
					/>
				</LabeledField>
			)}
			{template &&
				<LabeledField  >
					<textarea type="text" style={{ minHeight: '200px' }} className={cls.inputField} value={JSON.stringify(template)}
						onChange={(e) => {
							const newT = JSON.parse(e.target.value);
							if (newT?.name !== 'New Template') {
								selectedCompany.misc_info.templatesData = selectedCompany.misc_info.templatesData?.filter(temp => temp?.name !== template.name).concat([newT]);
								selectedCompany.misc_info.templates = [...new Set([...selectedCompany.misc_info.templates, newT?.name])]


							}
							setTemplate(newT);

						}} />
				</LabeledField>}
			{selectedCompany && (
				<Button
					loading={loading}
					title={'Update'}
					clicked={() => {
						setLoading(true);
						console.log('selectedCompqny', selectedCompany)
						updateCompanyDetailsById(selectedCompany)
							.pipe(first())
							.subscribe({
								next: data => {
									dispatch(setMessageBar({ show: true, message: `Success, ${data?.display_name} was updated.` })),
										setLoading(false)
								},
								error: () => setLoading(false),
							});
					}}
				/>
			)}
		</div>
	);
};
