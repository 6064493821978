import React, { useEffect, useRef, useState } from 'react';
import 'react-tabs/style/react-tabs.css';
import cls from './CheckoutPage.module.scss';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import Button from '../../common/UI/Button/Button';
import { useLocation } from 'react-router-dom';
import { subscriptionPayment, servicesPayment, createIntent, paymentMethod, attachIntent, setActive, checkout } from '../../../services/payments.service';
import { getCompanyDetails } from '../../../services/company.service';
import { first } from 'rxjs';
import { ModalR, RenderTitle, SelectWithSearch } from '../../common';
import { APP_NAME, country_list, generateNewUUID } from '../../../shared/utility';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import moment, { now } from 'moment';
import { FRONTEND_URL, PAYMONGO_KEY } from '../../../services/API_CONSTANTS';
import { setBillable, setPayable } from '../../../reduxStore/actions/flowActions';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { store } from '../../../reduxStore/store';

const CheckoutPage = props => {
	const { onSuccess, onClose = () => window.history.back(), subscriptionInfo = {}, companyData = { email: '' }, paymentPath = 'payments', withBack = true, label = 'Plan', onError = () => {} } = props;
	const { payable } = useSelector(state => state.flowReducer);
	const form = useRef();
	const dispatch = useDispatch();
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [address, setAddress] = useState('');
	const [city, setCity] = useState('');
	const [postalCode, setPostalCode] = useState('');
	const [country, setCountry] = useState('');
	const [state, setState] = useState('');
	const [vat, setVat] = useState('');
	const [mobile, setMobile] = useState('');
	const [cardName, setCardName] = useState('');
	const [cardNum, setCardNum] = useState('4343434343434345');
	const [cardExp, setCardExp] = useState('');
	const [cardCvv, setCardCvv] = useState('');
	const [email, setEmail] = useState('');
	const [loading, setLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');
	const [subscriptionDone, setSubscriptionDone] = useState(false);
	const [paymentReference, setPaymentReference] = useState('');
	const [formHide] = useState(false);
	const stripe = useStripe();
	const elements = useElements();
	const location = useLocation();

	const [billingInfo] = useState(location?.state?.subscriptionInfo ?? subscriptionInfo);
	const [company] = useState(location?.state?.companyData ?? companyData);

	const hasBillingInformation = () => {
		return !formHide && (firstName !== '' || lastName !== '' || address !== '' || city !== '' || postalCode !== '' || country !== '' || state !== '' || vat !== '' || mobile !== '');
	};

	const isCompleteBillingInformation = () => {
		return !(firstName === '' || lastName === '' || address === '' || city === '' || postalCode === '' || country === '' || state === '');
	};

	const handleServicesSubmit = () => {
		setErrorMsg('');
		setLoading(true);

		servicesPayment(location.state.service_name, location.state.price.price_code, location.state.price.duration ?? null)
			.pipe(first())
			.subscribe({
				next: data => {
					setPaymentReference(data.data.stripe_id);
					setSubscriptionDone(true);
					getCompanyDetails().pipe(first()).subscribe({});
					setLoading(false);
				},
				error: error => {
					if (error.data != undefined) {
						setErrorMsg(error.data.message.message);
					} else {
						setErrorMsg('Payment error. Please check your card.');
					}

					setLoading(false);
				},
			});
	};

	const handleSubmit = async event => {
		setErrorMsg('');

		if (PAYMONGO_KEY === '' && cardName === '') {
			setErrorMsg('Please enter card owner name.');
			return;
		}

		// if (PAYMONGO_KEY && (cardCvv === '' || cardExp === '' || cardNum === '')) {
		// 	setErrorMsg('Please enter card details.');
		// 	return;
		// }

		// We don't want to let default form submission happen here,
		// which would refresh the page.
		event.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			if (!PAYMONGO_KEY) return;
		}

		setLoading(true);
		setErrorMsg('');

		let errors = [];
		if (firstName === '') {
			errors.push('first name');
		}
		if (lastName === '') {
			errors.push('last name');
		}
		if (email === '') {
			errors.push('email');
		}
		if (address === '') {
			errors.push('full address');
		}
		if (city === '') {
			errors.push('city');
		}
		if (postalCode === '') {
			errors.push('postal Code');
		}

		if (mobile === '' && PAYMONGO_KEY === '') {
			errors.push('phone Number');
		}

		if (errors.length > 0) {
			errors = errors.map((error, index) => <li key={index}>{error}</li>);
			let ErrorMsg = () => (
				<>
					<span>{`Please fill in all input fields:`}</span>
					<ul>{errors}</ul>
				</>
			);
			setLoading(false);
			setErrorMsg(ErrorMsg);
			return;
		}

		const getBillingDetails = () => {
			if (hasBillingInformation()) {
				return {
					name: firstName + ' ' + lastName,
					address: {
						city: city,
						country: country,
						line1: address,
						postal_code: postalCode,
						state: state,
					},
					phone: mobile,
					email: company.email,
				};
			}
			return {};
		};

		if (PAYMONGO_KEY === '') {
			// Stripe
			const paymentMethodObj = {
				type: 'card',
				card: elements.getElement(CardElement),
				billing_details: getBillingDetails(),
			};

			const paymentMethodResult = await stripe.createPaymentMethod(paymentMethodObj);
			if (paymentMethodResult.error !== undefined) {
				setLoading(false);
				setErrorMsg(paymentMethodResult.error?.message ?? 'Invalid card details');
				return;
			}

			subscriptionPayment(billingInfo?.billable_users.length, billingInfo?.price_code, paymentMethodResult.paymentMethod?.id)
				.pipe(first())
				.subscribe({
					next: data => {
						if (data.message === 'Add Subscription successful') {
							setPaymentReference(data.data.stripe_id);
							setSubscriptionDone(true);
							getCompanyDetails().pipe(first()).subscribe({});
						}
					},
					complete: () => {},
					error: error => {
						if (error.data != undefined) {
							setErrorMsg(error.data.message.message);
						} else {
							setErrorMsg('Payment error. Please check your card');
						}

						setLoading(false);
					},
				});
		} else {
			// Paymongo
			const id = subscriptionInfo?.ref ?? generateNewUUID();
			dispatch(
				setBillable({
					id: id,
					subscriptionInfo: billingInfo,
				})
			);

			checkout(
				`[{"amount":${billingInfo?.total * 100},"currency":"PHP","description":"${billingInfo?.plan ?? 'Subscription'}","name":"${billingInfo?.plan ?? 'Subscription'}","quantity":1}]`,
				`{"address":{"line1":"${address}","city":"${city}","state":"${state}","postal_code":"${postalCode}","country": "PH"},"name":"${firstName} ${lastName}","email":"${email}"}`,
				`"${FRONTEND_URL}#/${paymentPath}/${id}"`,
				`"${id}"`
			)
				.pipe(first())
				.subscribe({
					next: data => {
						if (data.data?.attributes) {
							//set pay object payable array, to be retrieved back during paymongo callback
							store.dispatch(
								setPayable([
									...payable,
									{
										user_id: billingInfo.user_id,
										amount: billingInfo?.total,
										currency: 'PHP',
										paymentID: id,
										id: billingInfo?.id,
										product: billingInfo?.plan,
									},
								])
							);

							window.location.replace(data.data.attributes.checkout_url);
						}
					},
					complete: () => {},
					error: error => {
						setLoading(false);
						setErrorMsg('Payment error.Please contact your administrator');
						onError('Payment error.Please contact your administrator');
					},
				});
		}
	};

	return (
		<div className={cls.checkoutPageHost}>
			{withBack && (
				<div className={cls.checkoutTop}>
					<Button clicked={onClose} title={'Back'} />
				</div>
			)}
			<div className={cls.checkoutContent} style={{ padding: isMobile ? '15px' : '' }}>
				<div className={cls.checkoutLeftContainer} style={{ width: isMobile ? '100%' : '', display: billingInfo ? '' : 'none' }}>
					<p className={cls.pageTitle}>Complete your Purchase</p>
					<div style={{ display: PAYMONGO_KEY === '' ? '' : 'none' }}>
						<p className={cls.sectionTitle} style={{ marginTop: '0px' }}>
							Payment Information
						</p>
						<div className={cls.inputRow}>
							<div className={cls.inputContainer}>
								<RenderTitle title={'Name on Card *'} />
								<input value={cardName} onChange={e => setCardName(e.target.value)} type="text" name="state" className={cls.inputField} />
							</div>
						</div>
						<div className={cls.inputRow}>
							{!PAYMONGO_KEY ? (
								<div className={cls.inputContainer}>
									<RenderTitle title={'Card Number *'} />
									<CardElement id="cc-number" className={cls.stripeCardNumberContainer} />
								</div>
							) : (
								<div className={cls.inputContainer} style={{ display: 'flex' }}>
									<div className={cls.cardContainer}>
										<RenderTitle title={'Card Number'} />
										<input value={cardNum} required minLength={16} maxLength={16} onChange={e => setCardNum(e.target.value)} type="text" name="state" className={cls.inputField} />
									</div>
									<div className={cls.cardContainer} style={{ width: '30%' }}>
										<RenderTitle title={'Expiry'} />
										<input value={cardExp} required placeholder={'MM / YY'} onChange={e => setCardExp(e.target.value)} type="month" name="state" className={cls.inputField} />
									</div>
									<div className={cls.cardContainer} style={{ width: '10%', marginRight: '0px' }}>
										<RenderTitle title={'CVC'} />
										<input value={cardCvv} required minLength={3} maxLength={3} placeholder={'CVC'} onChange={e => setCardCvv(e.target.value)} type="integer" name="state" className={cls.inputField} />
									</div>
								</div>
							)}
						</div>
					</div>
					<p className={cls.sectionTitle} style={{ marginTop: '0px' }}>
						{'Payment Information'}
					</p>
					<form ref={form} style={{ display: formHide === true ? 'none' : '' }}>
						<div className={cls.inputRow} style={{ flexDirection: isMobile ? 'column' : 'row' }}>
							<div className={cls.inputContainer}>
								<RenderTitle title={'First Name'} />
								<input value={firstName} onChange={e => setFirstName(e.target.value)} style={{ marginRight: '30px' }} type="text" name="first-name" className={cls.inputField} />
							</div>
							<div className={cls.inputContainer}>
								<RenderTitle title={'Last Name'} />
								<input value={lastName} onChange={e => setLastName(e.target.value)} type="text" name="last-name" className={cls.inputField} />
							</div>
						</div>
						<div className={cls.inputRow} style={{ flexDirection: isMobile ? 'column' : 'row' }}>
							<div className={cls.inputContainer}>
								<RenderTitle title={'Email'} />
								<input value={email} onChange={e => setEmail(e.target.value)} style={{ marginRight: '30px' }} type="text" name="email" className={cls.inputField} />
							</div>
						</div>
						<div className={cls.inputRow} style={{ flexDirection: isMobile ? 'column' : 'row' }}>
							<div className={cls.inputContainer}>
								<RenderTitle title={'Full Address'} />
								<input value={address} onChange={e => setAddress(e.target.value)} style={{ marginRight: '30px' }} type="text" name="full-address" className={cls.inputField} />
							</div>
						</div>
						<div className={cls.inputRow} style={{ flexDirection: isMobile ? 'column' : 'row' }}>
							<div className={cls.inputContainer}>
								<RenderTitle title={'City'} />
								<input value={city} onChange={e => setCity(e.target.value)} style={{ marginRight: '30px' }} type="text" name="city" className={cls.inputField} />
							</div>
							<div className={cls.inputContainer}>
								<RenderTitle title={'Postal Code'} />
								<input value={postalCode} onChange={e => setPostalCode(e.target.value)} type="text" name="postal-code" className={cls.inputField} />
							</div>
						</div>
						<div className={cls.inputRow} style={{ flexDirection: isMobile ? 'column' : 'row' }}>
							<div className={cls.inputContainer}>
								<RenderTitle title={'Country'} />
								<input value={country} onChange={e => setCountry(e.target.value)} type="text" name="country" className={cls.inputField} />
							</div>
							<div className={cls.inputContainer}>
								<RenderTitle title={'State'} />
								<input value={state} onChange={e => setState(e.target.value)} type="text" name="state" className={cls.inputField} />
							</div>
						</div>
						<div className={cls.inputRow} style={{ display: PAYMONGO_KEY === '' ? '' : 'none' }}>
							<div className={cls.inputContainer}>
								<RenderTitle title={'VAT/TAX ID'} />

								<input value={vat} onChange={e => setVat(e.target.value)} style={{ marginRight: '30px' }} type="text" name="vat" className={cls.inputField} />
							</div>
							<div className={cls.inputContainer}>
								<RenderTitle title={'Phone'} />

								<div className={cls.phoneContainer}>
									<PhoneInput country={'ph'} value={mobile} onChange={e => setMobile(e)} inputClass={cls.phoneIInput} specialLabel={''} placeholder={'Enter number here...'} inputStyle={{ border: 'none', width: '100%' }} />
								</div>
							</div>
						</div>
					</form>
				</div>
				<div className={cls.checkoutRightContainer}>
					<div className={cls.productsContainer}>
						<p className={cls.products}>Products</p>
						<div className={cls.planContainer}>
							<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
								{billingInfo ? <p className={cls.planTitle}>{billingInfo?.plan}</p> : <p className={cls.planTitle}>{location.state.price.description}</p>}
								{billingInfo ? (
									<p className={cls.price}>
										{billingInfo?.currency} {billingInfo?.plan_price}
									</p>
								) : (
									<p className={cls.price}>
										{location.state.price.currency} {location.state.price.price}
									</p>
								)}
							</div>
							<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px', marginBottom: '5px' }}>
								{billingInfo && <p className={cls.planDetails}>{label}</p>}
								{billingInfo && <p className={cls.priceDetails}>{billingInfo?.dlabel}</p>}
							</div>
						</div>
						<hr style={{ borderTop: '1px solid #D2D2D2', margin: '5%' }} />
						<div style={{ display: billingInfo?.billable_users?.length === undefined ? 'none' : 'flex', justifyContent: 'space-between', margin: '5%', marginBottom: '0px' }}>
							<p className={cls.planTitle}>Number of users to bill</p>
							<p className={cls.price}>{billingInfo?.billable_users.length}</p>
						</div>
						<div style={{ display: 'flex', justifyContent: 'space-between', margin: '5%', marginBottom: '20px' }}>
							<p className={cls.planTitleBlue}>Total (due today)</p>
							{billingInfo ? (
								<p className={cls.planTitleBlue}>
									{billingInfo?.currency} {billingInfo?.total}
								</p>
							) : (
								<p className={cls.planTitleBlue}>
									{location.state.price.currency} {location.state.price.price}
								</p>
							)}
						</div>
						<Button className={cls.payButton} disabled={loading} title={'Pay Now'} clicked={billingInfo ? handleSubmit : handleServicesSubmit} loading={loading} style={{ backgroundColor: isCompleteBillingInformation() ? '' : 'black' }} />
						<div className={cls.endingMessage} style={{ display: billingInfo?.billable_users?.length === undefined ? 'none' : 'flex' }}>
							<p>{PAYMONGO_KEY !== '' ? `Payments by` : `You can cancel your plan anytime. Your yearly recurrent payment starts on ${moment(new Date(now())).add(1, 'years').format('MMMM Do, YYYY')}. You will be billed monthly for the number of Employees in your account.`}</p>
							<img src={'https://files.readme.io/7586db8-small-paymongo_api_white.png'} style={{ height: '40px', width: '160px' }} />
						</div>

						<div style={{ display: 'flex', justifyContent: 'space-between', margin: '5%', marginBottom: '0px', color: 'red' }}>{errorMsg && <div>{errorMsg}</div>}</div>
					</div>
				</div>
			</div>
			{subscriptionDone && <RenderCompletedModal message={paymentReference} onSuccess={onSuccess} />}
		</div>
	);
};

export default CheckoutPage;

const RenderCompletedModal = props => {
	const { setCompletedModal, message, onSuccess } = props;

	return (
		<ModalR isOpen={true} shadowedContainerStyle={{ width: '35%', minWidth: '500px', minHeight: '400px' }} onClose={() => setCompletedModal(false)} scrollable={true} blured={true}>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<p
					style={{
						marginTop: '50px',
						fontSize: '32px',
						color: '#4A4A4A',
						marginBottom: '5px',
						textAlign: 'center',
					}}
				>
					{`Your subscription to ${APP_NAME} was successful!`}
				</p>
				<p
					style={{
						width: '300px',
						fontFamily: 'var(--app-text-main-font)',
						fontSize: '16px',
						color: '#4A4A4A',
						textAlign: 'center',
						marginBottom: '20px',
					}}
				>
					{`Your payment reference is ${message}`}
					<br />
					<br />
					{'You can look for this in your invoices list in the billing page '}
					<br />
					<br />
					<span style={{ fontWeight: '700' }}>{'Thank you for your purchase!'}</span>
				</p>
				<Button
					style={{ width: '200px' }}
					clicked={() => {
						onSuccess(message);
					}}
					title={'View Invoices'}
				/>
			</div>
		</ModalR>
	);
};
