import React, { useRef, useState, useEffect } from 'react';

import cls from './AccordionModule.module.scss';
import { textIcon } from '../../../assets/images/icons';
import FieldRow from '../../common/UI/CustomFields/FieldRow/FieldRow';

import classNames from 'classnames';

import MessageContainer from '../ProcessesComps/MessageContainer/MessageContainer';
import { useProcessTaskContext } from '../../../context/processTask.context';

const AccordionProduct = props => {
	const { component, isMandatoryError, nextButtonClicked, setIsMandatoryError } = props;
	const [processTaskContext] = useProcessTaskContext();

	const [message] = useState('');
	const content = useRef(null);

	useEffect(() => {
		checkIfAreMandatoryErrored();
	}, [nextButtonClicked]);

	const checkIfAreMandatoryErrored = () => {
		if (component?.selected_fields?.some(elem => elem.mandatory === true && elem.visible === true && (!elem.value || elem?.value?.length < 1))) {
			setIsMandatoryError(true);
		} else setIsMandatoryError(false);
	};

	const handleInputChange = (value, index) => {
		if (index == 0) {
			processTaskContext.newProduct = { name: value, product_code: processTaskContext?.newProduct?.product_code };
		} else {
			processTaskContext.newProduct = { product_code: value, name: processTaskContext?.newProduct?.name };
		}
	};

	return (
		<div id="accordion" className={cls.section}>
			<div className={classNames(cls.accordionContainer, true)} app-variant-has-error={message ? 'error' : ''}>
				<div style={{ display: 'flex' }}>
					<p className={cls.title} style={{ color: isMandatoryError && nextButtonClicked ? 'var(--app-color-error)' : '' }}>
						{'New Product Details'}
					</p>
				</div>
				<div ref={content} style={{ maxHeight: '', display: 'flex', padding: '0px 0px 20px 0px' }} className={cls.content}>
					<FieldRow fieldIcon={textIcon} fieldLabel={'Product Name'} isProcess visible={true} mandatory={true} customIconBgColor={'white'}>
						<div className={cls.fieldRowContainer}>
							<input
								//onBlur={e => validate(field.data_type_name, e.target.value, indexInContextArray, index, processTaskContext)}
								type={'text'}
								onChange={e => {
									handleInputChange(e.target.value, 0);
								}}
								autoComplete="none"
								//value={component.selected_fields[0].value}
								//placeholder={'Enter information'}
								className={cls.fieldTypeInput}
								name={'field.field_name'}
							/>
						</div>
					</FieldRow>
					<div style={{ width: '10px' }} />
					<FieldRow fieldIcon={textIcon} fieldLabel={'Product Code'} isProcess visible={true} mandatory={true} customIconBgColor={'white'}>
						<div className={cls.fieldRowContainer}>
							<input
								//onBlur={e => validate(field.data_type_name, e.target.value, indexInContextArray, index, processTaskContext)}
								type={'text'}
								onChange={e => {
									handleInputChange(e.target.value, 1);
								}}
								autoComplete="none"
								//value={component.selected_fields[0].value}
								//placeholder={'Enter information'}
								className={cls.fieldTypeInput}
								name={'field.field_name'}
							/>
						</div>
					</FieldRow>
				</div>
			</div>
			{message && message?.errors?.title && <MessageContainer message={message.errors.title} />}
			{message && message?.message && <MessageContainer message={message.message} />}
		</div>
	);
};

export default AccordionProduct;
