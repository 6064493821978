import React, { ElementConfig, useEffect, useRef, useState } from 'react';
import { components } from 'react-select';
import { isEmail } from 'validator';
import { alphabetical, searchIcon, vectorDown, CircleMark, RectangleBox } from '../assets/images/icons';
import roundArrow from '../components/OnboardingEntries/CompanyOrganizationalChart/roundArrow.svg';
import './utility.scss';
export { useMountedState } from 'react-use';
import { v4 as uuidV4 } from 'uuid';
import { validate as isValidUUID } from 'uuid';

export const isValidUID = id => isValidUUID(id);

export const uncachedUrl = urlString => `${urlString}?time=${new Date()}`;
export const generateNewUUID = () => uuidV4();
import * as R from 'ramda';

export const isFocusable = (element: HTMLElement): boolean => element?.tabindex != -1 || element?.getAttribute('tabindex') != null;
export const updateObject = (oldObject, updatedProperties) => {
	return {
		...oldObject,
		...updatedProperties,
	};
};

export const checkValidity = (value, rules) => {
	let isValid = true;
	if (!rules) {
		return true;
	}

	if (rules.required) {
		isValid = value.trim() !== '' && isValid;
	}

	if (rules.minLength) {
		isValid = value.length >= rules.minLength && isValid;
	}

	if (rules.maxLength) {
		isValid = value.length <= rules.maxLength && isValid;
	}

	if (rules.isEmail) {
		const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
		isValid = pattern.test(value) && isValid;
	}

	if (rules.isNumeric) {
		const pattern = /^\d+$/;
		isValid = pattern.test(value) && isValid;
	}

	return isValid;
};

//for select component from react-select
export const DropdownIndicator = (props: ElementConfig<typeof components.DropdownIndicator>) => {
	const {
		selectProps: { menuIsOpen },
	} = props;
	const [rotate, setRotate] = useState('');
	const settingRotate = val => setRotate(val);
	useEffect(() => {
		settingRotate(menuIsOpen ? 'accordion__icon rotate' : 'accordion__icon');
	}, [menuIsOpen]);
	return (
		<components.DropdownIndicator {...props}>
			<img alt="drdw" className={`${rotate}`} src={vectorDown} />
		</components.DropdownIndicator>
	);
};
export const SearchIndicator = (props: ElementConfig<typeof components.DropdownIndicator>) => {
	const {
		selectProps: { menuIsOpen },
	} = props;
	const [rotate, setRotate] = useState('');
	const settingRotate = val => setRotate(val);
	useEffect(() => {
		settingRotate(menuIsOpen ? 'accordion__icon rotate90' : 'accordion__icon');
	}, [menuIsOpen]);
	return (
		<components.DropdownIndicator {...props}>
			<img alt="drdw" className={`${rotate}`} src={searchIcon} />
		</components.DropdownIndicator>
	);
};
export const defaultSelectStyles = {
	container: (provided, _state) => ({ ...provided, width: '100%' }),
	control: (provided, _state) => ({
		...provided,
		boxShadow: 'none',
		position: 'relative',
		flex: '1 1 100%',
		minWidth: '250px',
		maxWidth: '100%',
		width: '100%',
		fontFamily: 'var(--app-text-main-font)',
		fontSize: '18px',
		lineHeight: '20px',
		color: 'var(--app-color-gray-light)',
		border: 'none',
		outline: 'none',
		textAlign: 'left',
		justifyContent: 'center',
		alignItems: 'stretch',
		alignContent: 'center',
		alignSelf: 'stretch',
		margin: '0px 0 0 0 ',
		padding: '0 0px 0 10px',
	}),
	input: (provided, _state) => ({
		...provided,
		margin: '0px 0 0px 0px ',
		fontFamily: 'var(--app-text-main-font)',
		fontSize: '20px',
		lineHeight: '20px',
		color: 'var(--app-color-gray-dark)',
		border: 'none',
		outline: 'none',
		alignSelf: 'center',
		padding: '0',
		width: '100%',
		textAlign: 'left',
	}),
	menu: (provided, { _data, _isDisabled, _isFocused, isSelected }) => ({
		...provided,
		justifyContent: 'center',
		alignItems: 'center',
		verticalTextAlign: 'center',
		alignContent: 'center',
		color: isSelected ? 'var(--app-color-gray-light)' : '#4A4A4A',
	}),
	menulist: (provided, { _data, _isDisabled, _isFocused, _isSelected }) => ({
		...provided,
		justifyContent: 'center',
		alignItems: 'center',
		verticalTextAlign: 'center',
		alignContent: 'center',
		backgroundColor: 'transparent',
	}),
	option: (provided, { _data, _isDisabled, isFocused, isSelected }) => ({
		...provided,
		minHeight: '20px',
		paddingTop: '10px',
		fontSize: '18px',
		verticalTextAlign: 'center',
		justifyContent: 'center',
		alignItems: 'center',
		alignContent: 'center',
		color: isSelected ? '#4A4A4A' : isFocused ? 'white' : 'var(--app-color-gray-light)',
		backgroundColor: isSelected ? '#D2D2D2' : isFocused ? '#4A4A4A' : 'transparent',
		//borderRadius: '8px',
	}),
	placeholder: (provided, _state) => ({ ...provided, fontSize: '18px', lineHeight: '20px', color: 'var(--app-color-gray-light)', fontWeight: 400, overflow: 'hidden' }),
	singleValue: (provided, state) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = 'opacity 300ms';
		return { ...provided, opacity, transition, marginLeft: '0px', fontSize: '18px', lineHeight: '20px', color: '#4A4A4A' };
	},
	dropdownIndicator: (styles, { _data }) => ({ ...styles, color: 'var(--app-color-gray-light)', size: 6, ':hover': { color: 'gray' }, width: '45px' /*  display: 'none' */ }),
	indicatorsContainer: (styles, { _data }) => ({ ...styles, color: 'var(--app-color-gray-light)', size: 7, display: 'flex', justifyContent: 'flex-end', alignItems: 'center', alignSelf: 'flex-end', ':hover': { color: 'gray' }, width: '65px' /*  display: 'none' */ }),
};
export const ValueContainer = ({ children, ...props }) => {
	return (
		components.ValueContainer && (
			<components.ValueContainer {...props}>
				{!!children && <img alt="valc" src={alphabetical} style={{ position: 'absolute', left: 6, width: '16px' /* height:'10px' */ }} />}
				{children}
			</components.ValueContainer>
		)
	);
};
export const CustomOption = ({ innerProps, isDisabled, label }) =>
	!isDisabled ? (
		<div {...innerProps} style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
			<div style={{ width: '20px', height: '20px' }}>
				<img src={roundArrow} alt=">" style={{ width: '20px', height: '20px' }} />
			</div>
			<div style={{ margin: '0 0 0 10px', flex: 1 }}>
				<p>{label}</p>
			</div>
		</div>
	) : null;
export const CustomOptionHighlight = ({ provided, isSelected, isFocused, innerProps, isDisabled, label }) =>
	!isDisabled ? (
		<div
			{...innerProps}
			style={{
				cursor: 'pointer',
				display: 'flex',
				flexDirection: 'row',
				...provided,
				minHeight: '10px',
				verticalTextAlign: 'center',
				justifyContent: 'center',
				alignItems: 'center',
				alignContent: 'center',
				color: isSelected ? '#4A4A4A' : isFocused ? 'white' : 'var(--app-color-gray-light)',
				backgroundColor: isSelected ? '#D2D2D2' : isFocused ? '#4A4A4A' : 'transparent',
				borderRadius: '8px',
			}}
		>
			<div style={{ width: '20px', height: '20px' }}>
				<img src={roundArrow} alt=">" style={{ width: '20px', height: '20px' }} />
			</div>
			<div
				style={{
					margin: '0 0 0 10px',
					flex: 1,
					...provided,
					fontSize: '1em',
					verticalTextAlign: 'center',
					justifyContent: 'center',
					alignItems: 'center',
					alignContent: 'center',
					color: isSelected ? '#4A4A4A' : isFocused ? 'white' : 'var(--app-color-gray-light)',
					backgroundColor: isSelected ? '#D2D2D2' : isFocused ? '#4A4A4A' : 'transparent',
					borderRadius: '8px',
				}}
			>
				<p style={{ padding: '5px', margin: '5px' }}>{label}</p>
			</div>
		</div>
	) : null;
export const useStateRef = (initialValue: any): Array<any> => {
	const [value, setValue] = useState(initialValue);
	const ref = useRef(value);
	useEffect(() => {
		ref.current = value;
	}, [value]);
	return [value, setValue, ref];
};

export const required = value => {
	if (!value) {
		return (
			<p style={{ fontFamily: 'var(--app-text-main-font)', fontSize: '1rem' /* '16px' */, lineHeight: '22px', color: 'var(--app-color-error)', margin: '1px 0 2px 0', padding: '0 0 0 1px' }} role="alert">
				{'This field is required!'}
			</p>
		);
	}
};

export const verifyEmail = value => {
	if (!isEmail(value)) {
		return (
			<p style={{ fontFamily: 'var(--app-text-main-font)', fontSize: '1rem' /* '16px' */, lineHeight: '22px', color: 'var(--app-color-error)', margin: '1px 0 2px 0', padding: '0 0 0 1px' }} role="alert">
				{'Please enter a valid email adress!'}
			</p>
		);
	}
};

export const neutralizeBack = callback => {
	window.navigateState(null, '', window.location.href);
	window.onpopstate = () => {
		window.navigateState(null, '', window.location.href);
		callback();
	};
};
export const revivalBack = () => {
	window.onpopstate = undefined;
	window.history.back();
};

export const numberOfEmployees = [
	{ value: '1-10', label: '1-10 employees', id: 0 },
	{ value: '11-50', label: '11-50 employees', id: 1 },
	{ value: '51-200', label: '51-200 employees', id: 2 },
	{ value: '201-500', label: '201-500 employees', id: 3 },
	{ value: '501+', label: '501+ employees', id: 3 },
];
export const positions = [
	{ value: 'front-end-developer', label: 'Front End Developer', id: 0 },
	{ value: 'back-end-developer', label: 'Back End Developer', id: 1 },
	{ value: 'ui-ux-designer', label: 'UI/UX Designer', id: 2 },
	{ value: 'project-manager', label: 'Project Manager', id: 3 },
];

export const colleagues = [
	{ name: 'Moga B.', position: 'Frontend Developer', id: '0' },
	{ name: 'Daniel IP.', position: 'Frontend Developer', id: '1' },
	{ name: 'Daniel R.', position: 'Backend Developer', id: '2' },
	{ name: 'Ugur K.', position: 'Dev Ops', id: '3' },
	{ name: 'Daniel D.', position: '', id: '4' },
	{ name: 'Bogdan G.', position: 'Project Manager', id: '5' },
	{ name: 'Andreea T.', position: 'QA Tester', id: '6' },
	{ name: 'Grant', position: '', id: '7' },
];

export const sortColleaguesValues = [];
export const sortByValues = [{ value: 'Sort by: A-Z', label: 'Sort by: A-Z', id: 0 }];

export const displayColleaguesTypes = [
	{ value: 'block', label: 'Display: block', id: 0 },
	{ value: 'inline', label: 'Display: inline', id: 1 },
	{ value: 'table', label: 'Display: table', id: 2 },
];

export const numberElementOptions = [
	{
		value: 'value',
		label: (
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '20px' }}>
				<p style={{ fontFamily: ' var(--app-text-main-font)', fontSize: '1.5rem', color: 'var(--app-color-gray-light)', paddingRight: '10px' }}>123</p>
				<p style={{ fontFamily: ' var(--app-text-main-font)', fontSize: '1rem', color: '#4A4A4A', paddingLeft: '10px' }}>Value</p>
			</div>
		),
	},
	{
		value: 'phone-number',
		label: (
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '20px' }}>
				<p style={{ fontFamily: ' var(--app-text-main-font)', fontSize: '1.5rem', color: 'var(--app-color-gray-light)', paddingRight: '10px' }}>+0</p>
				<p style={{ fontFamily: ' var(--app-text-main-font)', fontSize: '1rem', color: '#4A4A4A', paddingLeft: '10px' }}>Phone Number</p>
			</div>
		),
	},
	{
		value: 'currency',
		label: (
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '20px' }}>
				<p style={{ fontFamily: ' var(--app-text-main-font)', fontSize: '1.5rem', color: 'var(--app-color-gray-light)', paddingRight: '10px' }}>$€</p>
				<p style={{ fontFamily: ' var(--app-text-main-font)', fontSize: '1rem', color: '#4A4A4A', paddingLeft: '10px' }}>Currency</p>
			</div>
		),
	},
	{
		value: 'percentage',
		label: (
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '20px' }}>
				<p style={{ fontFamily: ' var(--app-text-main-font)', fontSize: '1.5rem', color: 'var(--app-color-gray-light)', paddingRight: '10px' }}>%</p>
				<p style={{ fontFamily: ' var(--app-text-main-font)', fontSize: '1rem', color: '#4A4A4A', paddingLeft: '15px' }}>Percentage</p>
			</div>
		),
	},
];

export const minSecOptions = [
	{ label: '1', value: '1' },
	{ label: '2', value: '2' },
	{ label: '3', value: '3' },
	{ label: '4', value: '4' },
	{ label: '5', value: '5' },
	{ label: '6', value: '6' },
	{ label: '7', value: '7' },
	{ label: '8', value: '8' },
	{ label: '9', value: '9' },
	{ label: '10', value: '10' },
	{ label: '11', value: '11' },
	{ label: '12', value: '12' },
	{ label: '13', value: '13' },
	{ label: '14', value: '14' },
	{ label: '15', value: '15' },
	{ label: '16', value: '16' },
	{ label: '17', value: '17' },
	{ label: '18', value: '18' },
	{ label: '19', value: '19' },
	{ label: '20', value: '20' },
	{ label: '21', value: '21' },
	{ label: '22', value: '22' },
	{ label: '23', value: '23' },
	{ label: '24', value: '24' },
	{ label: '25', value: '25' },
	{ label: '26', value: '26' },
	{ label: '27', value: '27' },
	{ label: '28', value: '28' },
	{ label: '29', value: '29' },
	{ label: '30', value: '30' },
	{ label: '31', value: '31' },
	{ label: '32', value: '32' },
	{ label: '33', value: '33' },
	{ label: '34', value: '34' },
	{ label: '35', value: '35' },
	{ label: '36', value: '36' },
	{ label: '37', value: '37' },
	{ label: '38', value: '38' },
	{ label: '39', value: '39' },
	{ label: '40', value: '40' },
	{ label: '41', value: '41' },
	{ label: '42', value: '42' },
	{ label: '43', value: '43' },
	{ label: '44', value: '44' },
	{ label: '45', value: '45' },
	{ label: '46', value: '46' },
	{ label: '47', value: '47' },
	{ label: '48', value: '48' },
	{ label: '49', value: '49' },
	{ label: '50', value: '50' },
	{ label: '51', value: '51' },
	{ label: '52', value: '52' },
	{ label: '53', value: '53' },
	{ label: '54', value: '54' },
	{ label: '55', value: '55' },
	{ label: '56', value: '56' },
	{ label: '57', value: '57' },
	{ label: '58', value: '58' },
	{ label: '59', value: '59' },
	{ label: '60', value: '60' },
];
export const hoursOptions = [
	{ label: '1', value: '1' },
	{ label: '2', value: '2' },
	{ label: '3', value: '3' },
	{ label: '4', value: '4' },
	{ label: '5', value: '5' },
	{ label: '6', value: '6' },
	{ label: '7', value: '7' },
	{ label: '8', value: '8' },
	{ label: '9', value: '9' },
	{ label: '10', value: '10' },
	{ label: '11', value: '11' },
	{ label: '12', value: '12' },
	{ label: '13', value: '13' },
	{ label: '14', value: '14' },
	{ label: '15', value: '15' },
	{ label: '16', value: '16' },
	{ label: '17', value: '17' },
	{ label: '18', value: '18' },
	{ label: '19', value: '19' },
	{ label: '20', value: '20' },
	{ label: '21', value: '21' },
	{ label: '22', value: '22' },
	{ label: '23', value: '23' },
	{ label: '24', value: '24' },
];
export const daysOptions = [
	{ label: '1', value: '1' },
	{ label: '2', value: '2' },
	{ label: '3', value: '3' },
	{ label: '4', value: '4' },
	{ label: '5', value: '5' },
	{ label: '6', value: '6' },
	{ label: '7', value: '7' },
	{ label: '8', value: '8' },
	{ label: '9', value: '9' },
	{ label: '10', value: '10' },
	{ label: '11', value: '11' },
	{ label: '12', value: '12' },
	{ label: '13', value: '13' },
	{ label: '14', value: '14' },
	{ label: '15', value: '15' },
	{ label: '16', value: '16' },
	{ label: '17', value: '17' },
	{ label: '18', value: '18' },
	{ label: '19', value: '19' },
	{ label: '20', value: '20' },
	{ label: '21', value: '21' },
	{ label: '22', value: '22' },
	{ label: '23', value: '23' },
	{ label: '24', value: '24' },
	{ label: '25', value: '25' },
	{ label: '26', value: '26' },
	{ label: '27', value: '27' },
	{ label: '28', value: '28' },
	{ label: '29', value: '29' },
	{ label: '30', value: '30' },
	{ label: '31', value: '31' },
];
export const timeUnitsOptions = [
	{ label: 'sec', value: 1 },
	{ label: 'min', value: 60 },
	{ label: 'hours', value: 3600 },
	{ label: 'days', value: 86400 },
	/* { label: "months", value: "5" }, { label: "years", value: "6" }, */
];
export const transformSecondsInNumber = seconds => {
	const minutes = +seconds / 60;
	let hours = 0,
		days = 0;

	if (minutes > 60) hours = minutes / 60;
	if (hours > 24) days = hours / 24;

	if (days == 0 && hours > 0) return { value: hours, unit: 'hours' };
	if (hours == 0) return { value: minutes, unit: 'min' };
	return { value: days, unit: 'days' };
};
export const transformSecondsInInteger = seconds => {
	const minutes = +seconds / 60;
	let hours = 0,
		days = 0;

	if (minutes > 60) hours = minutes / 60;
	if (hours > 24) days = hours / 24;
	const isInt = n => Number(n) === n && n % 1 === 0;
	if (isInt(days) && days > 0) return { value: days, unit: 'days' };
	else if (isInt(hours) && hours > 0) return { value: hours, unit: 'hours' };
	else if (isInt(minutes) && minutes > 0) return { value: minutes, unit: 'min' };
	else if (isInt(+seconds) && +seconds > 0) return { value: seconds, unit: 'sec' };
};
export function isInteger(n) {
	return Number(n) === n && n % 1 === 0;
}
export const transformSecondsInString = seconds => {
	const minutes = +seconds / 60;
	let hours = 0,
		days = 0;
	if (minutes > 60) hours = minutes / 60;
	if (hours > 24) days = hours / 24;
	if (hours == 0) return minutes?.toFixed(0) + ' min';
	if (days == 0) return hours?.toFixed(0) + ' hours';
	return days?.toFixed(2) + ' days';
};

export function debounce(func, wait, immediate) {
	let timeout;
	return function executedFunction() {
		const vm = this;
		const args = arguments;
		const later = function () {
			timeout = null;
			if (!immediate) func.apply(vm, args);
		};
		const callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(vm, args);
	};
}

export const currency_list = [
	{ label: 'Philippine Peso', value: 'PHP', symbol: '₱' },
	{ label: 'US Dollar', value: 'USD', symbol: '$' },
	{ label: 'Afghan Afghani', value: 'AFA', symbol: '؋' },
	{ label: 'Albanian Lek', value: 'ALL', symbol: 'Lek' },
	{ label: 'Algerian Dinar', value: 'DZD', symbol: 'دج' },
	{ label: 'Angolan Kwanza', value: 'AOA', symbol: 'Kz' },
	{ label: 'Argentine Peso', value: 'ARS', symbol: '$' },
	{ label: 'Armenian Dram', value: 'AMD', symbol: '֏' },
	{ label: 'Aruban Florin', value: 'AWG', symbol: 'ƒ' },
	{ label: 'Australian Dollar', value: 'AUD', symbol: '$' },
	{ label: 'Azerbaijani Manat', value: 'AZN', symbol: 'm' },
	{ label: 'Bahamian Dollar', value: 'BSD', symbol: 'B$' },
	{ label: 'Bahraini Dinar', value: 'BHD', symbol: '.د.ب' },
	{ label: 'Bangladeshi Taka', value: 'BDT', symbol: '৳' },
	{ label: 'Barbadian Dollar', value: 'BBD', symbol: 'Bds$' },
	{ label: 'Belarusian Ruble', value: 'BYR', symbol: 'Br' },
	{ label: 'Belgian Franc', value: 'BEF', symbol: 'fr' },
	{ label: 'Belize Dollar', value: 'BZD', symbol: '$' },
	{ label: 'Bermudan Dollar', value: 'BMD', symbol: '$' },
	{ label: 'Bhutanese Ngultrum', value: 'BTN', symbol: 'Nu.' },
	{ label: 'Bitcoin', value: 'BTC', symbol: '฿' },
	{ label: 'Bolivian Boliviano', value: 'BOB', symbol: 'Bs.' },
	{ label: 'Bosnia-Herzegovina Convertible Mark', value: 'BAM', symbol: 'KM' },
	{ label: 'Botswanan Pula', value: 'BWP', symbol: 'P' },
	{ label: 'Brazilian Real', value: 'BRL', symbol: 'R$' },
	{ label: 'British Pound Sterling', value: 'GBP', symbol: '£' },
	{ label: 'Brunei Dollar', value: 'BND', symbol: 'B$' },
	{ label: 'Bulgarian Lev', value: 'BGN', symbol: 'Лв.' },
	{ label: 'Burundian Franc', value: 'BIF', symbol: 'FBu' },
	{ label: 'Cambodian Riel', value: 'KHR', symbol: 'KHR' },
	{ label: 'Canadian Dollar', value: 'CAD', symbol: '$' },
	{ label: 'Cape Verdean Escudo', value: 'CVE', symbol: '$' },
	{ label: 'Cayman Islands Dollar', value: 'KYD', symbol: '$' },
	{ label: 'CFA Franc BCEAO', value: 'XOF', symbol: 'CFA' },
	{ label: 'CFA Franc BEAC', value: 'XAF', symbol: 'FCFA' },
	{ label: 'CFP Franc', value: 'XPF', symbol: '₣' },
	{ label: 'Chilean Peso', value: 'CLP', symbol: '$' },
	{ label: 'Chinese Yuan', value: 'CNY', symbol: '¥' },
	{ label: 'Colombian Peso', value: 'COP', symbol: '$' },
	{ label: 'Comorian Franc', value: 'KMF', symbol: 'CF' },
	{ label: 'Congolese Franc', value: 'CDF', symbol: 'FC' },
	{ label: 'Costa Rican ColÃ³n', value: 'CRC', symbol: '₡' },
	{ label: 'Croatian Kuna', value: 'HRK', symbol: 'kn' },
	{ label: 'Cuban Convertible Peso', value: 'CUC', symbol: '$, CUC' },
	{ label: 'Czech Republic Koruna', value: 'CZK', symbol: 'Kč' },
	{ label: 'Danish Krone', value: 'DKK', symbol: 'Kr.' },
	{ label: 'Djiboutian Franc', value: 'DJF', symbol: 'Fdj' },
	{ label: 'Dominican Peso', value: 'DOP', symbol: '$' },
	{ label: 'East Caribbean Dollar', value: 'XCD', symbol: '$' },
	{ label: 'Egyptian Pound', value: 'EGP', symbol: 'ج.م' },
	{ label: 'Eritrean Nakfa', value: 'ERN', symbol: 'Nfk' },
	{ label: 'Estonian Kroon', value: 'EEK', symbol: 'kr' },
	{ label: 'Ethiopian Birr', value: 'ETB', symbol: 'Nkf' },
	{ label: 'Euro', value: 'EUR', symbol: '€' },
	{ label: 'Falkland Islands Pound', value: 'FKP', symbol: '£' },
	{ label: 'Fijian Dollar', value: 'FJD', symbol: 'FJ$' },
	{ label: 'Gambian Dalasi', value: 'GMD', symbol: 'D' },
	{ label: 'Georgian Lari', value: 'GEL', symbol: 'ლ' },
	{ label: 'German Mark', value: 'DEM', symbol: 'DM' },
	{ label: 'Ghanaian Cedi', value: 'GHS', symbol: 'GH₵' },
	{ label: 'Gibraltar Pound', value: 'GIP', symbol: '£' },
	{ label: 'Greek Drachma', value: 'GRD', symbol: '₯, Δρχ, Δρ' },
	{ label: 'Guatemalan Quetzal', value: 'GTQ', symbol: 'Q' },
	{ label: 'Guinean Franc', value: 'GNF', symbol: 'FG' },
	{ label: 'Guyanaese Dollar', value: 'GYD', symbol: '$' },
	{ label: 'Haitian Gourde', value: 'HTG', symbol: 'G' },
	{ label: 'Honduran Lempira', value: 'HNL', symbol: 'L' },
	{ label: 'Hong Kong Dollar', value: 'HKD', symbol: '$' },
	{ label: 'Hungarian Forint', value: 'HUF', symbol: 'Ft' },
	{ label: 'Icelandic KrÃ³na', value: 'ISK', symbol: 'kr' },
	{ label: 'Indian Rupee', value: 'INR', symbol: '₹' },
	{ label: 'Indonesian Rupiah', value: 'IDR', symbol: 'Rp' },
	{ label: 'Iranian Rial', value: 'IRR', symbol: '﷼' },
	{ label: 'Iraqi Dinar', value: 'IQD', symbol: 'د.ع' },
	{ label: 'Israeli New Sheqel', value: 'ILS', symbol: '₪' },
	{ label: 'Italian Lira', value: 'ITL', symbol: 'L,£' },
	{ label: 'Jamaican Dollar', value: 'JMD', symbol: 'J$' },
	{ label: 'Japanese Yen', value: 'JPY', symbol: '¥' },
	{ label: 'Jordanian Dinar', value: 'JOD', symbol: 'ا.د' },
	{ label: 'Kazakhstani Tenge', value: 'KZT', symbol: 'лв' },
	{ label: 'Kenyan Shilling', value: 'KES', symbol: 'KSh' },
	{ label: 'Kuwaiti Dinar', value: 'KWD', symbol: 'ك.د' },
	{ label: 'Kyrgystani Som', value: 'KGS', symbol: 'лв' },
	{ label: 'Laotian Kip', value: 'LAK', symbol: '₭' },
	{ label: 'Latvian Lats', value: 'LVL', symbol: 'Ls' },
	{ label: 'Lebanese Pound', value: 'LBP', symbol: '£' },
	{ label: 'Lesotho Loti', value: 'LSL', symbol: 'L' },
	{ label: 'Liberian Dollar', value: 'LRD', symbol: '$' },
	{ label: 'Libyan Dinar', value: 'LYD', symbol: 'د.ل' },
	{ label: 'Lithuanian Litas', value: 'LTL', symbol: 'Lt' },
	{ label: 'Macanese Pataca', value: 'MOP', symbol: '$' },
	{ label: 'Macedonian Denar', value: 'MKD', symbol: 'ден' },
	{ label: 'Malagasy Ariary', value: 'MGA', symbol: 'Ar' },
	{ label: 'Malawian Kwacha', value: 'MWK', symbol: 'MK' },
	{ label: 'Malaysian Ringgit', value: 'MYR', symbol: 'RM' },
	{ label: 'Maldivian Rufiyaa', value: 'MVR', symbol: 'Rf' },
	{ label: 'Mauritanian Ouguiya', value: 'MRO', symbol: 'MRU' },
	{ label: 'Mauritian Rupee', value: 'MUR', symbol: '₨' },
	{ label: 'Mexican Peso', value: 'MXN', symbol: '$' },
	{ label: 'Moldovan Leu', value: 'MDL', symbol: 'L' },
	{ label: 'Mongolian Tugrik', value: 'MNT', symbol: '₮' },
	{ label: 'Moroccan Dirham', value: 'MAD', symbol: 'MAD' },
	{ label: 'Mozambican Metical', value: 'MZM', symbol: 'MT' },
	{ label: 'Myanmar Kyat', value: 'MMK', symbol: 'K' },
	{ label: 'Namibian Dollar', value: 'NAD', symbol: '$' },
	{ label: 'Nepalese Rupee', value: 'NPR', symbol: '₨' },
	{ label: 'Netherlands Antillean Guilder', value: 'ANG', symbol: 'ƒ' },
	{ label: 'New Taiwan Dollar', value: 'TWD', symbol: '$' },
	{ label: 'New Zealand Dollar', value: 'NZD', symbol: '$' },
	{ label: 'Nicaraguan CÃ³rdoba', value: 'NIO', symbol: 'C$' },
	{ label: 'Nigerian Naira', value: 'NGN', symbol: '₦' },
	{ label: 'North Korean Won', value: 'KPW', symbol: '₩' },
	{ label: 'Norwegian Krone', value: 'NOK', symbol: 'kr' },
	{ label: 'Omani Rial', value: 'OMR', symbol: '.ع.ر' },
	{ label: 'Pakistani Rupee', value: 'PKR', symbol: '₨' },
	{ label: 'Panamanian Balboa', value: 'PAB', symbol: 'B/.' },
	{ label: 'Papua New Guinean Kina', value: 'PGK', symbol: 'K' },
	{ label: 'Paraguayan Guarani', value: 'PYG', symbol: '₲' },
	{ label: 'Peruvian Nuevo Sol', value: 'PEN', symbol: 'S/.' },
	{ label: 'Polish Zloty', value: 'PLN', symbol: 'zł' },
	{ label: 'Qatari Rial', value: 'QAR', symbol: 'ق.ر' },
	{ label: 'Romanian Leu', value: 'RON', symbol: 'RON' },
	{ label: 'Russian Ruble', value: 'RUB', symbol: '₽' },
	{ label: 'Rwandan Franc', value: 'RWF', symbol: 'FRw' },
	{ label: 'Salvadoran Colón', value: 'SVC', symbol: '₡' },
	{ label: 'Samoan Tala', value: 'WST', symbol: 'SAT' },
	{ label: 'Saudi Riyal', value: 'SAR', symbol: '﷼' },
	{ label: 'Serbian Dinar', value: 'RSD', symbol: 'din' },
	{ label: 'Seychellois Rupee', value: 'SCR', symbol: 'SRe' },
	{ label: 'Sierra Leonean Leone', value: 'SLL', symbol: 'Le' },
	{ label: 'Singapore Dollar', value: 'SGD', symbol: '$' },
	{ label: 'Slovak Koruna', value: 'SKK', symbol: 'Sk' },
	{ label: 'Solomon Islands Dollar', value: 'SBD', symbol: 'Si$' },
	{ label: 'Somali Shilling', value: 'SOS', symbol: 'Sh.so.' },
	{ label: 'South African Rand', value: 'ZAR', symbol: 'R' },
	{ label: 'South Korean Won', value: 'KRW', symbol: '₩' },
	{ label: 'Special Drawing Rights', value: 'XDR', symbol: 'SDR' },
	{ label: 'Sri Lankan Rupee', value: 'LKR', symbol: 'Rs' },
	{ label: 'St. Helena Pound', value: 'SHP', symbol: '£' },
	{ label: 'Sudanese Pound', value: 'SDG', symbol: '.س.ج' },
	{ label: 'Surivaluese Dollar', value: 'SRD', symbol: '$' },
	{ label: 'Swazi Lilangeni', value: 'SZL', symbol: 'E' },
	{ label: 'Swedish Krona', value: 'SEK', symbol: 'kr' },
	{ label: 'Swiss Franc', value: 'CHF', symbol: 'CHf' },
	{ label: 'Syrian Pound', value: 'SYP', symbol: 'LS' },
	{ label: 'São Tomé and Príncipe Dobra', value: 'STD', symbol: 'Db' },
	{ label: 'Tajikistani Somoni', value: 'TJS', symbol: 'SM' },
	{ label: 'Tanzanian Shilling', value: 'TZS', symbol: 'TSh' },
	{ label: 'Thai Baht', value: 'THB', symbol: '฿' },
	{ label: "Tongan Pa'anga", value: 'TOP', symbol: '$' },
	{ label: 'Trinidad & Tobago Dollar', value: 'TTD', symbol: '$' },
	{ label: 'Tunisian Dinar', value: 'TND', symbol: 'ت.د' },
	{ label: 'Turkish Lira', value: 'TRY', symbol: '₺' },
	{ label: 'Turkmenistani Manat', value: 'TMT', symbol: 'T' },
	{ label: 'Ugandan Shilling', value: 'UGX', symbol: 'USh' },
	{ label: 'Ukrainian Hryvnia', value: 'UAH', symbol: '₴' },
	{ label: 'United Arab Emirates Dirham', value: 'AED', symbol: 'إ.د' },
	{ label: 'Uruguayan Peso', value: 'UYU', symbol: '$' },
	{ label: 'Uzbekistan Som', value: 'UZS', symbol: 'лв' },
	{ label: 'Vanuatu Vatu', value: 'VUV', symbol: 'VT' },
	{ label: 'Venezuelan BolÃvar', value: 'VEF', symbol: 'Bs' },
	{ label: 'Vietnamese Dong', value: 'VND', symbol: '₫' },
	{ label: 'Yemeni Rial', value: 'YER', symbol: '﷼' },
	{ label: 'Zambian Kwacha', value: 'ZMK', symbol: 'ZK' },
];
export const fileExtensions = [
	{ label: 'Adobe Acrobat Reader (.pdf)', value: '.pdf' },
	{ label: 'Microsoft Word (.doc)', value: '.doc' },
	{ label: 'Microsoft Word (.docx)', value: '.docx' },
	{ label: 'Microsoft Excel (.xls)', value: '.xls' },
	{ label: 'Microsoft Excel (.xlsx)', value: '.xlsx' },
	{ label: 'Microsoft Power Point file (.ppt)', value: '.ppt' },
	{ label: 'Microsoft Power Point file (.pptx)', value: '.pptx' },
	{ label: 'Image file (.jpg)', value: '.jpg' },
	{ label: 'Image file (.jpeg)', value: '.jpeg' },
	{ label: 'Image file (.png)', value: '.pbg' },
	{ label: 'JSON file (.json)', value: '.json' },
];
export const dropdownComponentSelectionType = [
	{ label: 'Single', value: 'single', icon: <CircleMark /> },
	{ label: 'Multiple', value: 'multiple', icon: <RectangleBox /> },
	{ label: 'Decision', value: 'decision', icon: <FontAwesomeIcon icon={faDiamond} /> },
	{ label: 'Status', value: 'status', icon: <FontAwesomeIcon icon={faTags} /> },
	{ label: 'Modules', value: 'modules', icon: <FontAwesomeIcon icon={faTags} /> },
];

export const timezones1 = [
	{ value: '(GMT-11:00) Pago Pago', label: 'Pacific/Pago_Pago' },
	{ value: '(GMT-10:00) Hawaii Time', label: 'Pacific/Honolulu' },
	{ value: '(GMT-08:00) Pacific Time', label: 'America/Los_Angeles' },
	{ value: '(GMT-08:00) Pacific Time - Tijuana', label: 'America/Tijuana' },
	{ value: '(GMT-07:00) Mountain Time', label: 'America/Denver' },
	{ value: '(GMT-07:00) Mountain Time - Arizona', label: 'America/Phoenix' },
	{ value: '(GMT-07:00) Mountain Time - Chihuahua}, Mazatlan', label: 'America/Mazatlan' },
	{ value: '(GMT-06:00) Central Time', label: 'America/Chicago' },
	{ value: '(GMT-06:00) Central Time - Mexico City', label: 'America/Mexico_City' },
	{ value: '(GMT-06:00) Central Time - Regina', label: 'America/Regina' },
	{ value: '(GMT-06:00) Guatemala', label: 'America/Guatemala' },
	{ value: '(GMT-05:00) Bogota', label: 'America/Bogota' },
	{ value: '(GMT-05:00) Eastern Time', label: 'America/New_York' },
	{ value: '(GMT-05:00) Lima', label: 'America/Lima' },
	{ value: '(GMT-04:30) Caracas', label: 'America/Caracas' },
	{ value: '(GMT-04:00) Atlantic Time - Halifax', label: 'America/Halifax' },
	{ value: '(GMT-04:00) Guyana', label: 'America/Guyana' },
	{ value: '(GMT-04:00) La Paz', label: 'America/La_Paz' },
	{ value: '(GMT-03:00) Buenos Aires', label: 'America/Argentina/Buenos_Aires' },
	{ value: '(GMT-03:00) Godthab', label: 'America/Godthab' },
	{ value: '(GMT-03:00) Montevideo', label: 'America/Montevideo' },
	{ value: '(GMT-03:30) Newfoundland Time - St. Johns', label: 'America/St_Johns' },
	{ value: '(GMT-03:00) Santiago', label: 'America/Santiago' },
	{ value: '(GMT-02:00) Sao Paulo', label: 'America/Sao_Paulo' },
	{ value: '(GMT-02:00) South Georgia', label: 'Atlantic/South_Georgia' },
	{ value: '(GMT-01:00) Azores', label: 'Atlantic/Azores' },
	{ value: '(GMT-01:00) Cape Verde', label: 'Atlantic/Cape_Verde' },
	{ value: '(GMT+00:00) Casablanca', label: 'Africa/Casablanca' },
	{ value: '(GMT+00:00) Dublin', label: 'Europe/Dublin' },
	{ value: '(GMT+00:00) Lisbon', label: 'Europe/Lisbon' },
	{ value: '(GMT+00:00) London', label: 'Europe/London' },
	{ value: '(GMT+00:00) Monrovia', label: 'Africa/Monrovia' },
	{ value: '(GMT+01:00) Algiers', label: 'Africa/Algiers' },
	{ value: '(GMT+01:00) Amsterdam', label: 'Europe/Amsterdam' },
	{ value: '(GMT+01:00) Berlin', label: 'Europe/Berlin' },
	{ value: '(GMT+01:00) Brussels', label: 'Europe/Brussels' },
	{ value: '(GMT+01:00) Budapest', label: 'Europe/Budapest' },
	{ value: '(GMT+01:00) Central European Time - Belgrade', label: 'Europe/Belgrade' },
	{ value: '(GMT+01:00) Central European Time - Prague', label: 'Europe/Prague' },
	{ value: '(GMT+01:00) Copenhagen', label: 'Europe/Copenhagen' },
	{ value: '(GMT+01:00) Madrid', label: 'Europe/Madrid' },
	{ value: '(GMT+01:00) Paris', label: 'Europe/Paris' },
	{ value: '(GMT+01:00) Rome', label: 'Europe/Rome' },
	{ value: '(GMT+01:00) Stockholm', label: 'Europe/Stockholm' },
	{ value: '(GMT+01:00) Vienna', label: 'Europe/Vienna' },
	{ value: '(GMT+01:00) Warsaw', label: 'Europe/Warsaw' },
	{ value: '(GMT+02:00) Athens', label: 'Europe/Athens' },
	{ value: '(GMT+02:00) Bucharest', label: 'Europe/Bucharest' },
	{ value: '(GMT+02:00) Cairo', label: 'Africa/Cairo' },
	{ value: '(GMT+02:00) Jerusalem', label: 'Asia/Jerusalem' },
	{ value: '(GMT+02:00) Johannesburg', label: 'Africa/Johannesburg' },
	{ value: '(GMT+02:00) Helsinki', label: 'Europe/Helsinki' },
	{ value: '(GMT+02:00) Kiev', label: 'Europe/Kiev' },
	{ value: '(GMT+02:00) Moscow-01 - Kaliningrad', label: 'Europe/Kaliningrad' },
	{ value: '(GMT+02:00) Riga', label: 'Europe/Riga' },
	{ value: '(GMT+02:00) Sofia', label: 'Europe/Sofia' },
	{ value: '(GMT+02:00) Tallinn', label: 'Europe/Tallinn' },
	{ value: '(GMT+02:00) Vilnius', label: 'Europe/Vilnius' },
	{ value: '(GMT+03:00) Istanbul', label: 'Europe/Istanbul' },
	{ value: '(GMT+03:00) Baghdad', label: 'Asia/Baghdad' },
	{ value: '(GMT+03:00) Nairobi', label: 'Africa/Nairobi' },
	{ value: '(GMT+03:00) Minsk', label: 'Europe/Minsk' },
	{ value: '(GMT+03:00) Riyadh', label: 'Asia/Riyadh' },
	{ value: '(GMT+03:00) Moscow+00 - Moscow', label: 'Europe/Moscow' },
	{ value: '(GMT+03:30) Tehran', label: 'Asia/Tehran' },
	{ value: '(GMT+04:00) Baku', label: 'Asia/Baku' },
	{ value: '(GMT+04:00) Moscow+01 - Samara', label: 'Europe/Samara' },
	{ value: '(GMT+04:00) Tbilisi', label: 'Asia/Tbilisi' },
	{ value: '(GMT+04:00) Yerevan', label: 'Asia/Yerevan' },
	{ value: '(GMT+04:30) Kabul', label: 'Asia/Kabul' },
	{ value: '(GMT+05:00) Karachi', label: 'Asia/Karachi' },
	{ value: '(GMT+05:00) Moscow+02 - Yekaterinburg', label: 'Asia/Yekaterinburg' },
	{ value: '(GMT+05:00) Tashkent', label: 'Asia/Tashkent' },
	{ value: '(GMT+05:30) Colombo', label: 'Asia/Colombo' },
	{ value: '(GMT+06:00) Almaty', label: 'Asia/Almaty' },
	{ value: '(GMT+06:00) Dhaka', label: 'Asia/Dhaka' },
	{ value: '(GMT+06:30) Rangoon', label: 'Asia/Rangoon' },
	{ value: '(GMT+07:00) Bangkok', label: 'Asia/Bangkok' },
	{ value: '(GMT+07:00) Jakarta', label: 'Asia/Jakarta' },
	{ value: '(GMT+07:00) Moscow+04 - Krasnoyarsk', label: 'Asia/Krasnoyarsk' },
	{ value: '(GMT+08:00) China Time - Beijing', label: 'Asia/Shanghai' },
	{ value: '(GMT+08:00) Hong Kong', label: 'Asia/Hong_Kong' },
	{ value: '(GMT+08:00) Kuala Lumpur', label: 'Asia/Kuala_Lumpur' },
	{ value: '(GMT+08:00) Moscow+05 - Irkutsk', label: 'Asia/Irkutsk' },
	{ value: '(GMT+08:00) Singapore', label: 'Asia/Singapore' },
	{ value: '(GMT+08:00) Taipei', label: 'Asia/Taipei' },
	{ value: '(GMT+08:00) Ulaanbaatar', label: 'Asia/Ulaanbaatar' },
	{ value: '(GMT+08:00) Western Time - Perth', label: 'Australia/Perth' },
	{ value: '(GMT+09:00) Moscow+06 - Yakutsk', label: 'Asia/Yakutsk' },
	{ value: '(GMT+09:00) Seoul', label: 'Asia/Seoul' },
	{ value: '(GMT+09:00) Tokyo', label: 'Asia/Tokyo' },
	{ value: '(GMT+09:30) Central Time - Darwin', label: 'Australia/Darwin' },
	{ value: '(GMT+10:00) Eastern Time - Brisbane', label: 'Australia/Brisbane' },
	{ value: '(GMT+10:00) Guam', label: 'Pacific/Guam' },
	{ value: '(GMT+10:00) Moscow+07 - Magadan', label: 'Asia/Magadan' },
	{ value: '(GMT+10:00) Moscow+07 - Yuzhno-Sakhalinsk', label: 'Asia/Vladivostok' },
	{ value: '(GMT+10:00) Port Moresby', label: 'Pacific/Port_Moresby' },
	{ value: '(GMT+10:30) Central Time - Adelaide', label: 'Australia/Adelaide' },
	{ value: '(GMT+11:00) Eastern Time - Hobart', label: 'Australia/Hobart' },
	{ value: '(GMT+11:00) Eastern Time - Melbourne}, Sydney', label: 'Australia/Sydney' },
	{ value: '(GMT+11:00) Guadalcanal', label: 'Pacific/Guadalcanal' },
	{ value: '(GMT+11:00) Noumea', label: 'Pacific/Noumea' },
	{ value: '(GMT+12:00) Majuro', label: 'Pacific/Majuro' },
	{ value: '(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy', label: 'Asia/Kamchatka' },
	{ value: '(GMT+13:00) Auckland', label: 'Pacific/Auckland' },
	{ value: '(GMT+13:00) Fakaofo', label: 'Pacific/Fakaofo' },
	{ value: '(GMT+13:00) Fiji', label: 'Pacific/Fiji' },
	{ value: '(GMT+13:00) Tongatapu', label: 'Pacific/Tongatapu' },
	{ value: '(GMT+14:00) Apia', label: 'Pacific/Apia' },
];

export const timezones = [
	{
		label: '(GMT-11:00) Pago Pago',
		value: 'Pacific/Pago_Pago',
	},
	{
		label: '(GMT-10:00) Hawaii Time',
		value: 'Pacific/Honolulu',
	},
	{
		label: '(GMT-08:00) Pacific Time',
		value: 'America/Los_Angeles',
	},
	{
		label: '(GMT-08:00) Pacific Time - Tijuana',
		value: 'America/Tijuana',
	},
	{
		label: '(GMT-07:00) Mountain Time',
		value: 'America/Denver',
	},
	{
		label: '(GMT-07:00) Mountain Time - Arizona',
		value: 'America/Phoenix',
	},
	{
		label: '(GMT-07:00) Mountain Time - Chihuahua}, Mazatlan',
		value: 'America/Mazatlan',
	},
	{
		label: '(GMT-06:00) Central Time',
		value: 'America/Chicago',
	},
	{
		label: '(GMT-06:00) Central Time - Mexico City',
		value: 'America/Mexico_City',
	},
	{
		label: '(GMT-06:00) Central Time - Regina',
		value: 'America/Regina',
	},
	{
		label: '(GMT-06:00) Guatemala',
		value: 'America/Guatemala',
	},
	{
		label: '(GMT-05:00) Bogota',
		value: 'America/Bogota',
	},
	{
		label: '(GMT-05:00) Eastern Time',
		value: 'America/New_York',
	},
	{
		label: '(GMT-05:00) Lima',
		value: 'America/Lima',
	},
	{
		label: '(GMT-04:30) Caracas',
		value: 'America/Caracas',
	},
	{
		label: '(GMT-04:00) Atlantic Time - Halifax',
		value: 'America/Halifax',
	},
	{
		label: '(GMT-04:00) Guyana',
		value: 'America/Guyana',
	},
	{
		label: '(GMT-04:00) La Paz',
		value: 'America/La_Paz',
	},
	{
		label: '(GMT-03:00) Buenos Aires',
		value: 'America/Argentina/Buenos_Aires',
	},
	{
		label: '(GMT-03:00) Godthab',
		value: 'America/Godthab',
	},
	{
		label: '(GMT-03:00) Montevideo',
		value: 'America/Montevideo',
	},
	{
		label: '(GMT-03:30) Newfoundland Time - St. Johns',
		value: 'America/St_Johns',
	},
	{
		label: '(GMT-03:00) Santiago',
		value: 'America/Santiago',
	},
	{
		label: '(GMT-02:00) Sao Paulo',
		value: 'America/Sao_Paulo',
	},
	{
		label: '(GMT-02:00) South Georgia',
		value: 'Atlantic/South_Georgia',
	},
	{
		label: '(GMT-01:00) Azores',
		value: 'Atlantic/Azores',
	},
	{
		label: '(GMT-01:00) Cape Verde',
		value: 'Atlantic/Cape_Verde',
	},
	{
		label: '(GMT+00:00) Casablanca',
		value: 'Africa/Casablanca',
	},
	{
		label: '(GMT+00:00) Dublin',
		value: 'Europe/Dublin',
	},
	{
		label: '(GMT+00:00) Lisbon',
		value: 'Europe/Lisbon',
	},
	{
		label: '(GMT+00:00) London',
		value: 'Europe/London',
	},
	{
		label: '(GMT+00:00) Monrovia',
		value: 'Africa/Monrovia',
	},
	{
		label: '(GMT+01:00) Algiers',
		value: 'Africa/Algiers',
	},
	{
		label: '(GMT+01:00) Amsterdam',
		value: 'Europe/Amsterdam',
	},
	{
		label: '(GMT+01:00) Berlin',
		value: 'Europe/Berlin',
	},
	{
		label: '(GMT+01:00) Brussels',
		value: 'Europe/Brussels',
	},
	{
		label: '(GMT+01:00) Budapest',
		value: 'Europe/Budapest',
	},
	{
		label: '(GMT+01:00) Central European Time - Belgrade',
		value: 'Europe/Belgrade',
	},
	{
		label: '(GMT+01:00) Central European Time - Prague',
		value: 'Europe/Prague',
	},
	{
		label: '(GMT+01:00) Copenhagen',
		value: 'Europe/Copenhagen',
	},
	{
		label: '(GMT+01:00) Madrid',
		value: 'Europe/Madrid',
	},
	{
		label: '(GMT+01:00) Paris',
		value: 'Europe/Paris',
	},
	{
		label: '(GMT+01:00) Rome',
		value: 'Europe/Rome',
	},
	{
		label: '(GMT+01:00) Stockholm',
		value: 'Europe/Stockholm',
	},
	{
		label: '(GMT+01:00) Vienna',
		value: 'Europe/Vienna',
	},
	{
		label: '(GMT+01:00) Warsaw',
		value: 'Europe/Warsaw',
	},
	{
		label: '(GMT+02:00) Athens',
		value: 'Europe/Athens',
	},
	{
		label: '(GMT+02:00) Bucharest',
		value: 'Europe/Bucharest',
	},
	{
		label: '(GMT+02:00) Cairo',
		value: 'Africa/Cairo',
	},
	{
		label: '(GMT+02:00) Jerusalem',
		value: 'Asia/Jerusalem',
	},
	{
		label: '(GMT+02:00) Johannesburg',
		value: 'Africa/Johannesburg',
	},
	{
		label: '(GMT+02:00) Helsinki',
		value: 'Europe/Helsinki',
	},
	{
		label: '(GMT+02:00) Kiev',
		value: 'Europe/Kiev',
	},
	{
		label: '(GMT+02:00) Moscow-01 - Kaliningrad',
		value: 'Europe/Kaliningrad',
	},
	{
		label: '(GMT+02:00) Riga',
		value: 'Europe/Riga',
	},
	{
		label: '(GMT+02:00) Sofia',
		value: 'Europe/Sofia',
	},
	{
		label: '(GMT+02:00) Tallinn',
		value: 'Europe/Tallinn',
	},
	{
		label: '(GMT+02:00) Vilnius',
		value: 'Europe/Vilnius',
	},
	{
		label: '(GMT+03:00) Istanbul',
		value: 'Europe/Istanbul',
	},
	{
		label: '(GMT+03:00) Baghdad',
		value: 'Asia/Baghdad',
	},
	{
		label: '(GMT+03:00) Nairobi',
		value: 'Africa/Nairobi',
	},
	{
		label: '(GMT+03:00) Minsk',
		value: 'Europe/Minsk',
	},
	{
		label: '(GMT+03:00) Riyadh',
		value: 'Asia/Riyadh',
	},
	{
		label: '(GMT+03:00) Moscow+00 - Moscow',
		value: 'Europe/Moscow',
	},
	{
		label: '(GMT+03:30) Tehran',
		value: 'Asia/Tehran',
	},
	{
		label: '(GMT+04:00) Baku',
		value: 'Asia/Baku',
	},
	{
		label: '(GMT+04:00) Moscow+01 - Samara',
		value: 'Europe/Samara',
	},
	{
		label: '(GMT+04:00) Tbilisi',
		value: 'Asia/Tbilisi',
	},
	{
		label: '(GMT+04:00) Yerevan',
		value: 'Asia/Yerevan',
	},
	{
		label: '(GMT+04:30) Kabul',
		value: 'Asia/Kabul',
	},
	{
		label: '(GMT+05:00) Karachi',
		value: 'Asia/Karachi',
	},
	{
		label: '(GMT+05:00) Moscow+02 - Yekaterinburg',
		value: 'Asia/Yekaterinburg',
	},
	{
		label: '(GMT+05:00) Tashkent',
		value: 'Asia/Tashkent',
	},
	{
		label: '(GMT+05:30) Colombo',
		value: 'Asia/Colombo',
	},
	{
		label: '(GMT+06:00) Almaty',
		value: 'Asia/Almaty',
	},
	{
		label: '(GMT+06:00) Dhaka',
		value: 'Asia/Dhaka',
	},
	{
		label: '(GMT+06:30) Rangoon',
		value: 'Asia/Rangoon',
	},
	{
		label: '(GMT+07:00) Bangkok',
		value: 'Asia/Bangkok',
	},
	{
		label: '(GMT+07:00) Jakarta',
		value: 'Asia/Jakarta',
	},
	{
		label: '(GMT+07:00) Moscow+04 - Krasnoyarsk',
		value: 'Asia/Krasnoyarsk',
	},
	{
		label: '(GMT+08:00) China Time - Beijing',
		value: 'Asia/Shanghai',
	},
	{
		label: '(GMT+08:00) Hong Kong',
		value: 'Asia/Hong_Kong',
	},
	{
		label: '(GMT+08:00) Kuala Lumpur',
		value: 'Asia/Kuala_Lumpur',
	},
	{
		label: '(GMT+08:00) Moscow+05 - Irkutsk',
		value: 'Asia/Irkutsk',
	},
	{
		label: '(GMT+08:00) Singapore',
		value: 'Asia/Singapore',
	},
	{
		label: '(GMT+08:00) Taipei',
		value: 'Asia/Taipei',
	},
	{
		label: '(GMT+08:00) Ulaanbaatar',
		value: 'Asia/Ulaanbaatar',
	},
	{
		label: '(GMT+08:00) Western Time - Perth',
		value: 'Australia/Perth',
	},
	{
		label: '(GMT+09:00) Moscow+06 - Yakutsk',
		value: 'Asia/Yakutsk',
	},
	{
		label: '(GMT+09:00) Seoul',
		value: 'Asia/Seoul',
	},
	{
		label: '(GMT+09:00) Tokyo',
		value: 'Asia/Tokyo',
	},
	{
		label: '(GMT+09:30) Central Time - Darwin',
		value: 'Australia/Darwin',
	},
	{
		label: '(GMT+10:00) Eastern Time - Brisbane',
		value: 'Australia/Brisbane',
	},
	{
		label: '(GMT+10:00) Guam',
		value: 'Pacific/Guam',
	},
	{
		label: '(GMT+10:00) Moscow+07 - Magadan',
		value: 'Asia/Magadan',
	},
	{
		label: '(GMT+10:00) Moscow+07 - Yuzhno-Sakhalinsk',
		value: 'Asia/Vladivostok',
	},
	{
		label: '(GMT+10:00) Port Moresby',
		value: 'Pacific/Port_Moresby',
	},
	{
		label: '(GMT+10:30) Central Time - Adelaide',
		value: 'Australia/Adelaide',
	},
	{
		label: '(GMT+11:00) Eastern Time - Hobart',
		value: 'Australia/Hobart',
	},
	{
		label: '(GMT+11:00) Eastern Time - Melbourne}, Sydney',
		value: 'Australia/Sydney',
	},
	{
		label: '(GMT+11:00) Guadalcanal',
		value: 'Pacific/Guadalcanal',
	},
	{
		label: '(GMT+11:00) Noumea',
		value: 'Pacific/Noumea',
	},
	{
		label: '(GMT+12:00) Majuro',
		value: 'Pacific/Majuro',
	},
	{
		label: '(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy',
		value: 'Asia/Kamchatka',
	},
	{
		label: '(GMT+13:00) Auckland',
		value: 'Pacific/Auckland',
	},
	{
		label: '(GMT+13:00) Fakaofo',
		value: 'Pacific/Fakaofo',
	},
	{
		label: '(GMT+13:00) Fiji',
		value: 'Pacific/Fiji',
	},
	{
		label: '(GMT+13:00) Tongatapu',
		value: 'Pacific/Tongatapu',
	},
	{
		label: '(GMT+14:00) Apia',
		value: 'Pacific/Apia',
	},
];

/**
 * Returns true if the object is a function.
 * @param value The value to check
 */
export const isFunction = value => {
	return typeof value === 'function';
};
export function tcWrapper(f) {
	return function () {
		try {
			return f.apply(this, arguments);
		} catch (e) {
			console.log(e);
		}
	};
}
export function controlledUnsubscription(fn) {
	try {
		return fn?.unsubscribe?.();
	} catch (error) {
		console.warn(error);
	}
}
export const industryFields = [
	{
		label: 'Agriculture/Farming',
		value: '620100745dd99b4ce205f40f',
		id: '620100745dd99b4ce205f40f',
	},

	{
		label: 'Arts/Culture/Entertainment',
		value: '620100745dd99b4ce205f385',
		id: '620100745dd99b4ce205f385',
	},

	{
		label: 'Construction',
		value: '620100745dd99b4ce205f397',
		id: '620100745dd99b4ce205f397',
	},

	{
		label: 'Food',
		value: '620100745dd99b4ce205f3ac',
		id: '620100745dd99b4ce205f3ac',
	},

	{
		label: 'Manufacturing',
		value: '620100745dd99b4ce205f37f',
		id: '620100745dd99b4ce205f37f',
	},

	{
		label: 'Health',
		value: '620100745dd99b4ce205f380',
		id: '620100745dd99b4ce205f380',
	},

	{
		label: 'Information Technology',
		value: '620100745dd99b4ce205f37e',
		id: '620100745dd99b4ce205f37e',
	},

	{
		label: 'Government',
		value: '620100745dd99b4ce205f381',
		id: '620100745dd99b4ce205f381',
	},

	{
		label: 'Consumer',
		value: '620100745dd99b4ce205f382',
		id: '620100745dd99b4ce205f382',
	},

	{
		label: 'Computer & Electronics',
		value: '620100745dd99b4ce205f384',
		id: '620100745dd99b4ce205f384',
	},

	{
		label: 'Finance/Banking',
		value: '620100745dd99b4ce205f388',
		id: '620100745dd99b4ce205f388',
	},

	{
		label: 'Others',
		value: '620100745dd99b4ce205f3af',
		id: '620100745dd99b4ce205f3af',
	},
];

export const ipsum =
	'Lorem ipsum dolor sit amet. Ad placeat voluptatem in omnis animi et quibusdam voluptatem sit sequi atque. Et obcaecati quidem non reiciendis inventore quo repellat voluptatem sed odit dolores et dolores unde et doloribus tempora aut debitis quis. </p><p>A quia adipisci sit repellendus autem sed molestiae reprehenderit nam laudantium quidem At porro enim nam rerum consequatur. Ut eveniet nemo et iste voluptatem et accusantium asperiores. </p><p>Eum ullam iure nam perferendis rerum aut ipsam quaerat et fugiat corrupti in consequuntur quam ad libero eligendi. Et atque facilis sed illum totam ut veniam totam est aliquid praesentium et laboriosam distinctio qui tempore deserunt aut quia magni? Est error reiciendis ea sunt modi in illum necessitatibus? Vel reiciendis eveniet sit commodi voluptas eum facere dolores et explicabo eius vel voluptatem tenetur et soluta sunt';

export const freescoresheet =
	'This is a FIBA rules compatible basketball scoresheet. This is a free to use web page for recording statistics and play-by-play action for the game of basketball. The games recorded here are saved locally on your computer and not on the cloud. Game data are deleted once you clear your cache or press the CLEAR GAME button. To start using this scoresheet, enter at least five players for two teams by clicking the add player buttons, input the player number and name. Set the starting roster by checking the box besides the player number. Once you save your starting lineup, the scoresheet will appear. Recording begins when you select the 1st quarter in the dropdown box on the upper left side of the screen. You may begin to record manually the statistics by entering the appropriate values for each statistic. You can also use the play-by-play mode which will only require you to point and click the appropriate buttons. Do note that we are not responsible for any data lost when using this free-to-use software. We have a subscription-based model for users which requires more advanced tournament settings and cloud storage. You can help us maintain this website by becoming a supporter on our Patreon page. Thank you very much'
export const ourVisionMock = ipsum;
export const coreValuesMock = [ipsum];
export const ourMissionMock = ipsum;
export const companyStandardsMock = [ipsum];

//import Path from 'path';

import htmlfile from './email.html';
import cssfile from './utility.scss';
import ReactDOMServer from 'react-dom/server';
import moment, { now } from 'moment';
import { object } from 'yup';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiamond, faTags } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

const STYLE_TAG = '%STYLE%';
const CONTENT_TAG = '%CONTENT%';

export const createEmail = component => {
	//const emailElement = React.createElement(component);
	const content = ReactDOMServer.renderToString(component);

	// Replace the template tags with the content
	let emailHTML = htmlfile;
	emailHTML = emailHTML.replace(CONTENT_TAG, content);
	emailHTML = emailHTML.replace(STYLE_TAG, cssfile);

	return emailHTML;
};

export const country_list = [
	{
		value: 'AF',
		label: 'Afghanistan',
	},
	{
		value: 'AX',
		label: 'Åland Islands',
	},
	{
		value: 'AL',
		label: 'Albania',
	},
	{
		value: 'DZ',
		label: 'Algeria',
	},
	{
		value: 'AS',
		label: 'American Samoa',
	},
	{
		value: 'AD',
		label: 'Andorra',
	},
	{
		value: 'AO',
		label: 'Angola',
	},
	{
		value: 'AI',
		label: 'Anguilla',
	},
	{
		value: 'AQ',
		label: 'Antarctica',
	},
	{
		value: 'AG',
		label: 'Antigua and Barbuda',
	},
	{
		value: 'AR',
		label: 'Argentina',
	},
	{
		value: 'AM',
		label: 'Armenia',
	},
	{
		value: 'AW',
		label: 'Aruba',
	},
	{
		value: 'AU',
		label: 'Australia',
	},
	{
		value: 'AT',
		label: 'Austria',
	},
	{
		value: 'AZ',
		label: 'Azerbaijan',
	},
	{
		value: 'BS',
		label: 'Bahamas',
	},
	{
		value: 'BH',
		label: 'Bahrain',
	},
	{
		value: 'BD',
		label: 'Bangladesh',
	},
	{
		value: 'BB',
		label: 'Barbados',
	},
	{
		value: 'BY',
		label: 'Belarus',
	},
	{
		value: 'BE',
		label: 'Belgium',
	},
	{
		value: 'BZ',
		label: 'Belize',
	},
	{
		value: 'BJ',
		label: 'Benin',
	},
	{
		value: 'BM',
		label: 'Bermuda',
	},
	{
		value: 'BT',
		label: 'Bhutan',
	},
	{
		value: 'BO',
		label: 'Bolivia, Plurinational State of',
	},
	{
		value: 'BQ',
		label: 'Bonaire, Sint Eustatius and Saba',
	},
	{
		value: 'BA',
		label: 'Bosnia and Herzegovina',
	},
	{
		value: 'BW',
		label: 'Botswana',
	},
	{
		value: 'BV',
		label: 'Bouvet Island',
	},
	{
		value: 'BR',
		label: 'Brazil',
	},
	{
		value: 'IO',
		label: 'British Indian Ocean Territory',
	},
	{
		value: 'BN',
		label: 'Brunei Darussalam',
	},
	{
		value: 'BG',
		label: 'Bulgaria',
	},
	{
		value: 'BF',
		label: 'Burkina Faso',
	},
	{
		value: 'BI',
		label: 'Burundi',
	},
	{
		value: 'CV',
		label: 'Cabo Verde',
	},
	{
		value: 'KH',
		label: 'Cambodia',
	},
	{
		value: 'CM',
		label: 'Cameroon',
	},
	{
		value: 'CA',
		label: 'Canada',
	},
	{
		value: 'KY',
		label: 'Cayman Islands',
	},
	{
		value: 'CF',
		label: 'Central African Republic',
	},
	{
		value: 'TD',
		label: 'Chad',
	},
	{
		value: 'CL',
		label: 'Chile',
	},
	{
		value: 'CN',
		label: 'China',
	},
	{
		value: 'CX',
		label: 'Christmas Island',
	},
	{
		value: 'CC',
		label: 'Cocos (Keeling) Islands',
	},
	{
		value: 'CO',
		label: 'Colombia',
	},
	{
		value: 'KM',
		label: 'Comoros',
	},
	{
		value: 'CG',
		label: 'Congo',
	},
	{
		value: 'CD',
		label: 'Congo, Democratic Republic of the',
	},
	{
		value: 'CK',
		label: 'Cook Islands',
	},
	{
		value: 'CR',
		label: 'Costa Rica',
	},
	{
		value: 'HR',
		label: 'Croatia',
	},
	{
		value: 'CU',
		label: 'Cuba',
	},
	{
		value: 'CW',
		label: 'Curaçao',
	},
	{
		value: 'CY',
		label: 'Cyprus',
	},
	{
		value: 'CZ',
		label: 'Czechia',
	},
	{
		value: 'CI',
		label: "Côte d'Ivoire",
	},
	{
		value: 'DK',
		label: 'Denmark',
	},
	{
		value: 'DJ',
		label: 'Djibouti',
	},
	{
		value: 'DM',
		label: 'Dominica',
	},
	{
		value: 'DO',
		label: 'Dominican Republic',
	},
	{
		value: 'EC',
		label: 'Ecuador',
	},
	{
		value: 'EG',
		label: 'Egypt',
	},
	{
		value: 'SV',
		label: 'El Salvador',
	},
	{
		value: 'GQ',
		label: 'Equatorial Guinea',
	},
	{
		value: 'ER',
		label: 'Eritrea',
	},
	{
		value: 'EE',
		label: 'Estonia',
	},
	{
		value: 'SZ',
		label: 'Eswatini',
	},
	{
		value: 'ET',
		label: 'Ethiopia',
	},
	{
		value: 'FK',
		label: 'Falkland Islands (Malvinas)',
	},
	{
		value: 'FO',
		label: 'Faroe Islands',
	},
	{
		value: 'FJ',
		label: 'Fiji',
	},
	{
		value: 'FI',
		label: 'Finland',
	},
	{
		value: 'FR',
		label: 'France',
	},
	{
		value: 'GF',
		label: 'French Guiana',
	},
	{
		value: 'PF',
		label: 'French Polynesia',
	},
	{
		value: 'TF',
		label: 'French Southern Territories',
	},
	{
		value: 'GA',
		label: 'Gabon',
	},
	{
		value: 'GM',
		label: 'Gambia',
	},
	{
		value: 'GE',
		label: 'Georgia',
	},
	{
		value: 'DE',
		label: 'Germany',
	},
	{
		value: 'GH',
		label: 'Ghana',
	},
	{
		value: 'GI',
		label: 'Gibraltar',
	},
	{
		value: 'GR',
		label: 'Greece',
	},
	{
		value: 'GL',
		label: 'Greenland',
	},
	{
		value: 'GD',
		label: 'Grenada',
	},
	{
		value: 'GP',
		label: 'Guadeloupe',
	},
	{
		value: 'GU',
		label: 'Guam',
	},
	{
		value: 'GT',
		label: 'Guatemala',
	},
	{
		value: 'GG',
		label: 'Guernsey',
	},
	{
		value: 'GN',
		label: 'Guinea',
	},
	{
		value: 'GW',
		label: 'Guinea-Bissau',
	},
	{
		value: 'GY',
		label: 'Guyana',
	},
	{
		value: 'HT',
		label: 'Haiti',
	},
	{
		value: 'HM',
		label: 'Heard Island and McDonald Islands',
	},
	{
		value: 'VA',
		label: 'Holy See',
	},
	{
		value: 'HN',
		label: 'Honduras',
	},
	{
		value: 'HK',
		label: 'Hong Kong',
	},
	{
		value: 'HU',
		label: 'Hungary',
	},
	{
		value: 'IS',
		label: 'Iceland',
	},
	{
		value: 'IN',
		label: 'India',
	},
	{
		value: 'ID',
		label: 'Indonesia',
	},
	{
		value: 'IR',
		label: 'Iran, Islamic Republic of',
	},
	{
		value: 'IQ',
		label: 'Iraq',
	},
	{
		value: 'IE',
		label: 'Ireland',
	},
	{
		value: 'IM',
		label: 'Isle of Man',
	},
	{
		value: 'IL',
		label: 'Israel',
	},
	{
		value: 'IT',
		label: 'Italy',
	},
	{
		value: 'JM',
		label: 'Jamaica',
	},
	{
		value: 'JP',
		label: 'Japan',
	},
	{
		value: 'JE',
		label: 'Jersey',
	},
	{
		value: 'JO',
		label: 'Jordan',
	},
	{
		value: 'KZ',
		label: 'Kazakhstan',
	},
	{
		value: 'KE',
		label: 'Kenya',
	},
	{
		value: 'KI',
		label: 'Kiribati',
	},
	{
		value: 'KP',
		label: "Korea, Democratic People's Republic of",
	},
	{
		value: 'KR',
		label: 'Korea, Republic of',
	},
	{
		value: 'KW',
		label: 'Kuwait',
	},
	{
		value: 'KG',
		label: 'Kyrgyzstan',
	},
	{
		value: 'LA',
		label: "Lao People's Democratic Republic",
	},
	{
		value: 'LV',
		label: 'Latvia',
	},
	{
		value: 'LB',
		label: 'Lebanon',
	},
	{
		value: 'LS',
		label: 'Lesotho',
	},
	{
		value: 'LR',
		label: 'Liberia',
	},
	{
		value: 'LY',
		label: 'Libya',
	},
	{
		value: 'LI',
		label: 'Liechtenstein',
	},
	{
		value: 'LT',
		label: 'Lithuania',
	},
	{
		value: 'LU',
		label: 'Luxembourg',
	},
	{
		value: 'MO',
		label: 'Macao',
	},
	{
		value: 'MG',
		label: 'Madagascar',
	},
	{
		value: 'MW',
		label: 'Malawi',
	},
	{
		value: 'MY',
		label: 'Malaysia',
	},
	{
		value: 'MV',
		label: 'Maldives',
	},
	{
		value: 'ML',
		label: 'Mali',
	},
	{
		value: 'MT',
		label: 'Malta',
	},
	{
		value: 'MH',
		label: 'Marshall Islands',
	},
	{
		value: 'MQ',
		label: 'Martinique',
	},
	{
		value: 'MR',
		label: 'Mauritania',
	},
	{
		value: 'MU',
		label: 'Mauritius',
	},
	{
		value: 'YT',
		label: 'Mayotte',
	},
	{
		value: 'MX',
		label: 'Mexico',
	},
	{
		value: 'FM',
		label: 'Micronesia, Federated States of',
	},
	{
		value: 'MD',
		label: 'Moldova, Republic of',
	},
	{
		value: 'MC',
		label: 'Monaco',
	},
	{
		value: 'MN',
		label: 'Mongolia',
	},
	{
		value: 'ME',
		label: 'Montenegro',
	},
	{
		value: 'MS',
		label: 'Montserrat',
	},
	{
		value: 'MA',
		label: 'Morocco',
	},
	{
		value: 'MZ',
		label: 'Mozambique',
	},
	{
		value: 'MM',
		label: 'Myanmar',
	},
	{
		value: 'NA',
		label: 'Namibia',
	},
	{
		value: 'NR',
		label: 'Nauru',
	},
	{
		value: 'NP',
		label: 'Nepal',
	},
	{
		value: 'NL',
		label: 'Netherlands',
	},
	{
		value: 'NC',
		label: 'New Caledonia',
	},
	{
		value: 'NZ',
		label: 'New Zealand',
	},
	{
		value: 'NI',
		label: 'Nicaragua',
	},
	{
		value: 'NE',
		label: 'Niger',
	},
	{
		value: 'NG',
		label: 'Nigeria',
	},
	{
		value: 'NU',
		label: 'Niue',
	},
	{
		value: 'NF',
		label: 'Norfolk Island',
	},
	{
		value: 'MK',
		label: 'North Macedonia',
	},
	{
		value: 'MP',
		label: 'Northern Mariana Islands',
	},
	{
		value: 'NO',
		label: 'Norway',
	},
	{
		value: 'OM',
		label: 'Oman',
	},
	{
		value: 'PK',
		label: 'Pakistan',
	},
	{
		value: 'PW',
		label: 'Palau',
	},
	{
		value: 'PS',
		label: 'Palestine, State of',
	},
	{
		value: 'PA',
		label: 'Panama',
	},
	{
		value: 'PG',
		label: 'Papua New Guinea',
	},
	{
		value: 'PY',
		label: 'Paraguay',
	},
	{
		value: 'PE',
		label: 'Peru',
	},
	{
		value: 'PH',
		label: 'Philippines',
	},
	{
		value: 'PN',
		label: 'Pitcairn',
	},
	{
		value: 'PL',
		label: 'Poland',
	},
	{
		value: 'PT',
		label: 'Portugal',
	},
	{
		value: 'PR',
		label: 'Puerto Rico',
	},
	{
		value: 'QA',
		label: 'Qatar',
	},
	{
		value: 'RO',
		label: 'Romania',
	},
	{
		value: 'RU',
		label: 'Russian Federation',
	},
	{
		value: 'RW',
		label: 'Rwanda',
	},
	{
		value: 'RE',
		label: 'Réunion',
	},
	{
		value: 'BL',
		label: 'Saint Barthélemy',
	},
	{
		value: 'SH',
		label: 'Saint Helena, Ascension and Tristan da Cunha',
	},
	{
		value: 'KN',
		label: 'Saint Kitts and Nevis',
	},
	{
		value: 'LC',
		label: 'Saint Lucia',
	},
	{
		value: 'MF',
		label: 'Saint Martin (French part)',
	},
	{
		value: 'PM',
		label: 'Saint Pierre and Miquelon',
	},
	{
		value: 'VC',
		label: 'Saint Vincent and the Grenadines',
	},
	{
		value: 'WS',
		label: 'Samoa',
	},
	{
		value: 'SM',
		label: 'San Marino',
	},
	{
		value: 'ST',
		label: 'Sao Tome and Principe',
	},
	{
		value: 'SA',
		label: 'Saudi Arabia',
	},
	{
		value: 'SN',
		label: 'Senegal',
	},
	{
		value: 'RS',
		label: 'Serbia',
	},
	{
		value: 'SC',
		label: 'Seychelles',
	},
	{
		value: 'SL',
		label: 'Sierra Leone',
	},
	{
		value: 'SG',
		label: 'Singapore',
	},
	{
		value: 'SX',
		label: 'Sint Maarten (Dutch part)',
	},
	{
		value: 'SK',
		label: 'Slovakia',
	},
	{
		value: 'SI',
		label: 'Slovenia',
	},
	{
		value: 'SB',
		label: 'Solomon Islands',
	},
	{
		value: 'SO',
		label: 'Somalia',
	},
	{
		value: 'ZA',
		label: 'South Africa',
	},
	{
		value: 'GS',
		label: 'South Georgia and the South Sandwich Islands',
	},
	{
		value: 'SS',
		label: 'South Sudan',
	},
	{
		value: 'ES',
		label: 'Spain',
	},
	{
		value: 'LK',
		label: 'Sri Lanka',
	},
	{
		value: 'SD',
		label: 'Sudan',
	},
	{
		value: 'SR',
		label: 'Suriname',
	},
	{
		value: 'SJ',
		label: 'Svalbard and Jan Mayen',
	},
	{
		value: 'SE',
		label: 'Sweden',
	},
	{
		value: 'CH',
		label: 'Switzerland',
	},
	{
		value: 'SY',
		label: 'Syrian Arab Republic',
	},
	{
		value: 'TW',
		label: 'Taiwan, Province of China',
	},
	{
		value: 'TJ',
		label: 'Tajikistan',
	},
	{
		value: 'TZ',
		label: 'Tanzania, United Republic of',
	},
	{
		value: 'TH',
		label: 'Thailand',
	},
	{
		value: 'TL',
		label: 'Timor-Leste',
	},
	{
		value: 'TG',
		label: 'Togo',
	},
	{
		value: 'TK',
		label: 'Tokelau',
	},
	{
		value: 'TO',
		label: 'Tonga',
	},
	{
		value: 'TT',
		label: 'Trinidad and Tobago',
	},
	{
		value: 'TN',
		label: 'Tunisia',
	},
	{
		value: 'TR',
		label: 'Turkey',
	},
	{
		value: 'TM',
		label: 'Turkmenistan',
	},
	{
		value: 'TC',
		label: 'Turks and Caicos Islands',
	},
	{
		value: 'TV',
		label: 'Tuvalu',
	},
	{
		value: 'UG',
		label: 'Uganda',
	},
	{
		value: 'UA',
		label: 'Ukraine',
	},
	{
		value: 'AE',
		label: 'United Arab Emirates',
	},
	{
		value: 'GB',
		label: 'United Kingdom',
	},
	{
		value: 'UM',
		label: 'United States Minor Outlying Islands',
	},
	{
		value: 'US',
		label: 'United States',
	},
	{
		value: 'UY',
		label: 'Uruguay',
	},
	{
		value: 'UZ',
		label: 'Uzbekistan',
	},
	{
		value: 'VU',
		label: 'Vanuatu',
	},
	{
		value: 'VE',
		label: 'Venezuela, Bolivarian Republic of',
	},
	{
		value: 'VN',
		label: 'Viet Nam',
	},
	{
		value: 'VG',
		label: 'Virgin Islands, British',
	},
	{
		value: 'VI',
		label: 'Virgin Islands, U.S.',
	},
	{
		value: 'WF',
		label: 'Wallis and Futuna',
	},
	{
		value: 'EH',
		label: 'Western Sahara',
	},
	{
		value: 'YE',
		label: 'Yemen',
	},
	{
		value: 'ZM',
		label: 'Zambia',
	},
	{
		value: 'ZW',
		label: 'Zimbabwe',
	},
];
export const priorityArray = [
	{ label: 'High', value: '100', icon: '!' },
	{ label: 'Medium', value: '50', icon: '' },
	{ label: 'Low', value: '10', icon: '' },
];

export const computeFormula = (options, arr, arr2, items = [], arr3 = []) => {
	if (options?.operator === 'sum') {
		return arr.reduce((a, b) => a + parseInt(b?.value ?? 0), 0).toFixed(2);
	} else if (options?.operator === 'freesum') {
		let sum = 0;
		
		options.list.map(li=>{
			const value = arr2.find(ar=> ar.selected_fields.find(sf=> sf.field_name === li && sf.value))?.selected_fields?.find(sf=> sf.field_name === li && sf.value)?.value ?? 0;
			sum = sum + +value;
		})
		
		return sum.toFixed(2);
	} else if (options?.operator === 'difference') {
		return arr.reduce((a, b) => a - parseInt(b?.value ?? 0), 0).toFixed(2);
	} else if (options?.operator === 'datesdiff') {
		if (arr.length == 2) {
			const value = moment(new Date(arr[1].value), 'DD/MM/YYYY').diff(moment(new Date(arr[0].value), 'DD/MM/YYYY'), 'days');
			return value < 0 ? '' : value;
		} else {
			return '';
		}
	} else if (options?.operator === 'datenowdiff') {
		if (arr.length == 1) {
			const value = moment(new Date(now()), 'DD/MM/YYYY').diff(moment(new Date(arr[0].value), 'DD/MM/YYYY'), 'days');
			return value < 0 ? '' : value;
		} else {
			return '';
		}
	} else if (options?.operator === 'countdateocc') {
		const dateV = getDateForm(options?.searchtext);

		return arr.filter(obj => obj.component_fields.filter(cf => options?.list.includes(cf.field_name) && moment(new Date(cf.value)).format('DD MMM YYYY') === moment(dateV).format('DD MMM YYYY')).length > 0).length;
	} else if (options?.operator === 'multiply') {
		const result = arr.reduce((a, b) => a * +(b?.value ?? 0), 1);
		return Number.isInteger(result) ? result : result.toFixed(2);

	} else if (options?.operator === 'divide') {
		const result = arr.reduce((a, b) => a / +(b?.value ?? 0), 1);
		return Number.isInteger(result) ? result : result.toFixed(2);

	} else if (options?.operator === 'tdiff') {
		return arr2.reduce((a, b) => {
			const found = b?.selected_fields?.find(sf => sf.field_name === options.list[0]);
			const result = a - (found === undefined || found === '' ? 0 : +found.value);
			return Number.isInteger(result) ? result : result.toFixed(2);

		}, 0);
	} else if (options?.operator === 'tsum') {
		return arr2.reduce((a, b) => {
			const found = b?.selected_fields?.find(sf => sf.field_name === options.list[0]) ?? 0;

			const result = a + (found === '' ? 0 : +found.value);
			return Number.isInteger(result) ? result : result.toFixed(2);


		}, 0);
	} else if (options?.list[0] === 'qty' && options?.operator === 'itemdiff') {
		return items.reduce((a, b) => {
			const found = b?.quantity ?? 0;
			return a - parseInt(found === '' ? 0 : `${found}`);
		}, 0);
	} else if (options?.list[0] === 'qty' && options?.operator === 'itemsum') {
		return items.reduce((a, b) => {
			const found = b?.quantity ?? 0;
			return a + parseInt(found === '' ? 0 : `${found}`);
		}, 0);
	} else if (options?.list[0] === 'total' && options?.operator === 'itemdiff') {
		return items.reduce((a, b) => {
			const found = b?.total ?? 0;
			return a - parseInt(found === '' ? 0 : `${found}`);
		}, 0);
	} else if (options?.list[0] === 'total' && options?.operator === 'itemsum') {
		return items.reduce((a, b) => {
			const found = b?.total ?? 0;
			return a + parseInt(found === '' ? 0 : `${found}`);
		}, 0);
	} else if (options?.list[0] === 'value' && options?.operator === 'itemsum') {
		return items.reduce((a, b) => {
			const found = b?.value ?? 0;
			return a + parseInt(found === '' ? 0 : `${found}`);
		}, 0);
	} else if (options?.list[0] === 'value' && options?.operator === 'itemdiff') {
		return items.reduce((a, b) => {
			const found = b?.value ?? 0;
			return a - parseInt(found === '' ? 0 : `${found}`);
		}, 0);
	} else if (options?.list[0] === 'altvalue' && options?.operator === 'itemsum') {
		return items.reduce((a, b) => {
			const found = b?.alt_value ?? 0;
			return a + parseInt(found === '' ? 0 : `${found}`);
		}, 0);
	} else if (options?.list[0] === 'altvalue' && options?.operator === 'itemdiff') {
		return items.reduce((a, b) => {
			const found = b?.alt_value ?? 0;
			return a - parseInt(found === '' ? 0 : `${found}`);
		}, 0);
	} else if (options?.operator === 'countocc') {
		return arr.filter(obj => obj.component_fields?.filter(cf => options?.list.includes(cf.field_name) && cf.value === options?.searchtext).length > 0).length;
	} else if (options?.operator === 'cmpdistance') {
		const arrA = R.flatten(arr2.map(a => {
			return a.selected_fields;
		})).filter(cf => cf?.value && options?.list.includes(cf.field_name));
		
		return arrA
			.reduce((a, b) => {
				const i = arrA.findIndex(fi => fi.id === b.id);
				if (i == 0) {
					return a;
				} else {
					const loc1 = JSON.parse(arrA[i].value);
					const loc2 = JSON.parse(arrA[i - 1].value);
					return a + getDistanceBetweenPoints(loc1.source.lat, loc1.source.lng, loc2.source.lat, loc2.source.lng)
				}
			}, 0);
	}
	return 0;
};

export const getDateForm = type => {
	if (type === 'today') {
		return new Date(moment(now()));
	} else if (type === 'tomorrow') {
		return new Date(moment(now()).add(1, 'days'));
	} else if (type === 'week') {
		return new Date(moment(now()).add(1, 'weeks'));
	} else if (type === 'month') {
		return new Date(moment(now()).add(1, 'months'));
	}
	return new Date(moment(now()));
};

export const clientData = {
	clientName: 'BMW',
	Product: 'Bike',
	valueChain: {
		'BMW Smart Car': {
			cost: 9776,
			label: 'BMW Smart Car',
			childOf: null,
			currency: 'USD',
			emission: '72 tCO2',
		},
		elit: {
			cost: 6955,
			label: 'elit',
			childOf: 'BMW Smart Car',
			currency: 'USD',
			emission: '85 tCO2',
		},

		sunt: {
			cost: 6624,
			label: 'sunt',
			childOf: 'adipisicing',
			currency: 'USD',
			emission: '74 tCO2',
		},
		nisi: {
			cost: 5103,
			label: 'nisi',
			childOf: 'sunt',
			currency: 'USD',
			emission: '93 tCO2',
		},
		quis: {
			cost: 5629,
			label: 'quis',
			childOf: 'sunt',
			currency: 'USD',
			emission: '76 tCO2',
		},
		adipisicing: {
			cost: 7815,
			label: 'adipisicing',
			childOf: 'aliqua',
			currency: 'USD',
			emission: '32 tCO2',
		},
		aliqua: {
			cost: 3717,
			label: 'aliqua',
			childOf: 'ullamco',
			currency: 'USD',
			emission: '81 tCO2',
		},
		ullamco: {
			cost: 2691,
			label: 'ullamco',
			childOf: 'labore',
			currency: 'USD',
			emission: '78 tCO2',
		},

		labore: {
			cost: 5493,
			label: 'labore',
			childOf: 'dolore',
			currency: 'USD',
			emission: '75 tCO2',
		},
		dolore: {
			cost: 6632,
			label: 'dolore',
			childOf: 'quis',
			currency: 'USD',
			emission: '5 tCO2',
		},

		anim: {
			cost: 7576,
			label: 'anim',
			childOf: 'ullamco',
			currency: 'USD',
			emission: '66 tCO2',
		},

		dolor: {
			cost: 1862,
			label: 'dolor',
			childOf: 'dolor',
			currency: 'USD',
			emission: '50 tCO2',
		},
		ipsum: {
			cost: 8805,
			label: 'ipsum',
			childOf: 'labore',
			currency: 'USD',
			emission: '72 tCO2',
		},
		nulla: {
			cost: 1368,
			label: 'nulla',
			childOf: 'nulla',
			currency: 'USD',
			emission: '7 tCO2',
		},

		cillum: {
			cost: 3723,
			label: 'cillum',
			childOf: 'cillum',
			currency: 'USD',
			emission: '96 tCO2',
		},

		proident: {
			cost: 832,
			label: 'proident',
			childOf: 'cillum',
			currency: 'USD',
			emission: '89 tCO2',
		},
	},
};

export const operatorArray = [
	{ label: 'Sum of the fields (Number Field+B)', id: 0, value: 'sum', type: 'fields' },
	{ label: 'Difference between fields (B-Number Field)', id: 1, value: 'difference', type: 'fields' },
	{ label: 'Replace with new value (with B-Number Field)', id: 3, value: 'replace', type: 'fields' },
	{ label: 'Read only (Display value only)', id: 4, value: 'display', type: 'fields' },
];
export const itemArray = [
	{ label: 'Quantity', value: 'quantity' },
	// {label: 'Alt Value', value: 'alt_value'},
	// {label: 'Total', value: 'total'},
];

export const actionsArray = [
	{ label: 'Save current user', value: 'user' },
	{ label: 'Save user that created', value: 'creator' },
	{ label: 'Save selected status', value: 'status' },
	{ label: 'Save current date', value: 'date' },
	{ label: 'Save current location', value: 'location' },
];
/**
 * Converts degrees to radians.
 *
 * @param degrees Number of degrees.
 */
function degreesToRadians(degrees) {
	return (degrees * Math.PI) / 180;
}

/**
 * Returns the distance between 2 points of coordinates in Google Maps
 *
 * @see https://stackoverflow.com/a/1502821/4241030
 * @param lat1 Latitude of the point A
 * @param lng1 Longitude of the point A
 * @param lat2 Latitude of the point B
 * @param lng2 Longitude of the point B
 */
export function getDistanceBetweenPoints(lat1, lng1, lat2, lng2) {
	// The radius of the planet earth in meters
	let R = 6378137;
	let dLat = degreesToRadians(lat2 - lat1);
	let dLong = degreesToRadians(lng2 - lng1);
	let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(degreesToRadians(lat1)) * Math.cos(degreesToRadians(lat1)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);

	let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	let distance = (R * c) / 1000;

	return distance < 1 ? 1 : distance;
}
export function isUserAuthorized(status, authorized, selectedComponent, usr={id:'', positions:[]}, begunModule, companyData) {
	
	if (!usr?.positions)
		return false;

	return (
		((status === undefined && authorized?.filter(a => (usr?.positions ?? []).map(pos => pos.id).indexOf(a) > -1).length > 0) ||
			(status !== undefined &&
				!(status === 'Hide' || status === 'Closed' || status === companyData?.misc_info?.lock) &&
				authorized?.filter(a => usr.positions.map(pos => pos.id).indexOf(a) > -1).length > 0 &&
				(begunModule?.processModule?.module?.options?.strict != true || selectedComponent?.created_by?.id !== usr?.id))) &&
		selectedComponent.created_by?.id !== usr?.id
	);
}

export const groupings = group => {
	const found = (group ?? '').slice(0, 1);
	if (found >= 'A' && found <= 'D') return 'Components [A-D]';
	else if (found >= 'E' && found <= 'K') return 'Components [E-K]';
	else if (found >= 'L' && found <= 'O') return 'Components [L-O]';
	else if (found >= 'P' && found <= 'T') return 'Components [P-T]';
	return 'Components [U-Z]';
};

export function roomUrlFromPageUrl() {
	const match = window.location.search.match(/roomUrl=([^&]+)/i);
	return match && match[1] ? decodeURIComponent(match[1]) : null;
  }
  
  export function pageUrlFromRoomUrl(roomUrl) {
	return (
	  window.location.href.split('?')[0] + (roomUrl ? `?roomUrl=${encodeURIComponent(roomUrl)}` : '')
	);
  }
  
export const FLOWHERO = (size = '20px') => (
	<div className="headerTopBarContainer" style={{ fontSize: size }}>
		FLOW<span style={{ color: 'orange', fontWeight: 'normal' }}> HERO</span>™
	</div>
);

export const BYFLOWHERO = (size = '20px') => (
	<div className="headerTopBarContainer" style={{ fontSize: size }}>
		BY FLOW<span style={{ color: 'orange', fontWeight: 'normal' }}> HERO</span>™
	</div>
);

export const APP_NAME = 'FlowHero™';
export const COMPANY_NAME = `ADPGxSoftware Solutions`;
export const PROMO_VIDEO = 'https://youtu.be/yKxQI7OoPJ0';
export const WHAT_ARE_WORKFLOWS = 'https://www.youtube.com/watch?v=xV-bOHvfbPQ';

export const APPEND_TO_DATA = 0;
export const CLEAR_TO_SET_DATA = 1;
export const LIST_ONLY = true;
export const FORCED = true;
export const page_default = 10;
export const dashboard_page_default = 5;
export const pagination = 10;
export const no_pagination = 0;
export const pagination_max = 10;
export const app_max = 10000;
