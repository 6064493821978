import React from 'react';
import cls from './GeneralCard.module.scss';

const GeneralCard = props => {
	const { cardClicked, title, subtitle, children, description, customClass = cls.userCard, customStyle } = props;
	return (
		<div className={customClass} onClick={cardClicked} style={customStyle}>
			<div className={cls.userCardContainer}>
				<div className={cls.userCardBottomContainer}>
					<p className={cls.fullName}>{title}</p>
					<p className={cls.email}>{description}</p>
					<p className={cls.position}>{subtitle}</p>
					{children}
				</div>
			</div>
		</div>
	);
};

export default GeneralCard;
