import { useCallback, useEffect, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BackIcon, EditDisabled, EditEnabled } from '../../../assets/images/icons';
import { pagedModalClose } from '../../../reduxStore/actions/modalsActions';
import { FramedListItems, FramedTextArea, ModalR } from '../../common';
import { useListItemsRenderedContext } from '../../../context/appRender.context';

import cls from './PagedModal.module.scss';
import { setCompanyDetails } from '../../../services/company.service';
import { first } from 'rxjs/operators';
import * as R from 'ramda';
const fieldType = {
	'Our Vision': 'vision_text',
	'Our Mission': 'mission_text',
	'Core Values': 'core_values',
	'Company Standards': 'company_standards',
};
const PagedModal = memo(() => {
	const { pagedModal } = useSelector(store => store.modalsReducer);
	const { companyData } = useSelector(store => store.flowReducer);

	const {
		isOpen,
		options: { title, isListContent, onCancel },
	} = pagedModal;
	const dispatch = useDispatch();
	const [, setRenderedValues] = useListItemsRenderedContext();

	const [editing, setEditing] = useState(false);
	const [compantDetails_apiError, setCompantDetails_apiError] = useState('');

	const computeArrayFromResponse = useCallback(() => {
		let items = [...R.values(R.omit('key', companyData[fieldType[title]]))];
		if (Array.isArray(items[1])) {
			items = [...R.values(R.omit('key', companyData[fieldType[title]]?.array_type))];
		}
		return { items };
	}, [companyData, title]);

	const initialStating = useCallback(() => {
		const { items } = computeArrayFromResponse();
		isListContent && setRenderedValues(c => ({ ...c, items: [...(items ?? [])] }));
		setCompantDetails_apiError('');
		setEditing(false);
	}, [companyData, title]);

	useEffect(() => {
		isOpen ? initialStating() : isListContent && setRenderedValues(c => ({ ...c, items: [] }));
	}, [isOpen]);

	useEffect(() => {
		if (editing) setCompantDetails_apiError('');
	}, [editing]);

	const modalClosing = () => {
		onCancel?.();
		isOpen && dispatch(pagedModalClose());
	};

	const BackButton = () => {
		return (
			<div className={cls.columnHeader}>
				<div onClick={modalClosing} className={cls.backArCont}>
					<BackIcon className={cls.backAr} />
				</div>
				<div className={cls.titleCont}>
					<h1 className={cls.title}>{title} </h1>
				</div>
			</div>
		);
	};

	const onRegisterChanges = useCallback(
		sendableValue => {
			console.log('onRegisterChanges: ', sendableValue);
			setCompantDetails_apiError('');
			setCompanyDetails({ [fieldType[title]]: sendableValue })
				.pipe(first())
				.subscribe({
					next: data => {
						console.log('setCompanyDetails, [data]', data);
					},
					error: error => {
						const se = error?.data?.errors;
						const specificError = se?.vision_text ?? se?.mission_text ?? se?.core_values ?? se?.company_standards;
						setCompantDetails_apiError(error?.data?.message + `${specificError ? '\n' : ''}` + specificError);
						setEditing(false);
					},
				});
		},
		[title]
	);

	return (
		<ModalR blured={true} scrollable={true} isOpen={isOpen} onClose={modalClosing} modalWrapperStyle={{ overflow: 'hidden' }} shadowedContainerStyle={{ minWidth: '650px', width: '100%', height: '100vh', minHeight: '100%', paddingTop: 0 }}>
			<div className={cls.host}>
				<div className={cls.target}>
					<div className={cls.column}>
						<BackButton />
						<div onClick={() => setEditing(true)} className={cls.optionContainer} style={{ opacity: 1, color: editing ? 'var(--app-color-gray-lighter)' : '#4A4A4A' }}>
							{editing ? <EditDisabled /> : <EditEnabled />}
							<p>{'Edit'}</p>
						</div>
						<div style={{ width: 'auto', height: !isListContent ? 'calc(40vh - 110px)' : 'calc(60vh - 110px)' }} />
					</div>
					<div className={cls.content}>
						{isListContent ? (
							<FramedListItems
								title={'Text'}
								toogleEdit={editing}
								addFiledPlaceholder={'(SHIFT+ENTER to add new item)								'}
								onListChanged={_list => {
									setCompantDetails_apiError('');
								}}
								onListSaved={list => {
									console.log('onListSaved: ', list);
									onRegisterChanges(list);
								}}
							/>
						) : (
							<FramedTextArea title={'Text'} tooltipText={'Edit text then press save button in top right of container'} text={companyData[fieldType[title]]} placeholder={`Enter ${title} description here...`} toogleEdit={editing} onTextChanged={onRegisterChanges} />
						)}
						<div>{compantDetails_apiError?.length > 0 && <span className={cls.errorContainer}>{compantDetails_apiError} </span>}</div>
					</div>
				</div>
			</div>
		</ModalR>
	);
});

// const mapStateToProps = store => {
// 	const {
// 		pagedModal: { isOpen, options },
// 	} = store.modalsReducer;
// 	return { isOpen, options };
// };
// const mapDispatchToProps = dispatch => {
// 	return {
// 		onPagedModalClose: () => dispatch(pagedModalClose()),
// 	};
// };
export default PagedModal;
