import React from 'react';

function Chevron(props) {
	return (
		<svg className={props.className} width={props?.width ?? '14'} height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M1 1L7 7L13 1" stroke="#333333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
}

export default Chevron;
