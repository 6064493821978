require('dotenv').config();

import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Routes, Navigate, Route, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setOnBoardingVisible } from './appFiles/reduxStore/actions/modalsActions';
import Layout, { Divider, Left, Right, Top } from './appFiles/hoc/Layout/Layout';
import { OnboardingModals } from './appFiles/components/OnboardingEntries';
import { ErrorsModal, InfoModal } from './appFiles/components/common';
import ChangePassword from './appFiles/components/Auth/ChangePassword';
import { TheProcessTaskContext } from './appFiles/context/processTask.context';
import { TheBeginProcessContext } from './appFiles/context/beginProcess.context';
import ModulesMenu from './appFiles/components/SideMenuEntries/ModulesMenu';
import { isMobile } from 'react-device-detect';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { TheProcessContext, TheProcessStepContext } from './appFiles/context/processStep.context';
import { TheModulesContext } from './appFiles/context/modules.context';
import { TheBlueprintComponentsContext } from './appFiles/context/blueprintsComponents.context';

const Home = React.lazy(() => import('./appFiles/components/Home/Pages/Home'));
const Legal = React.lazy(() => import('./appFiles/components/Home/Pages/Legal'));
const Appointment = React.lazy(() => import('./appFiles/components/Home/Pages/Appointment'));

const Login = React.lazy(() => import('./appFiles/components/Auth/Login'));
const ResetPassword = React.lazy(() => import('./appFiles/components/Auth/ResetPassword'));
const AcceptInvitation = React.lazy(() => import('./appFiles/components/Auth/AcceptInvitation'));

const Register = React.lazy(() => import('./appFiles/components/SideMenuEntries/Register/Register'));
const RegisterUser = React.lazy(() => import('./appFiles/components/SideMenuEntries/Register/RegisterUser'));

const Dashboard = React.lazy(() => import('./appFiles/components/SideMenuEntries/Dashboard'));
const DashboardMobile = React.lazy(() => import('./appFiles/components/SideMenuEntries/DashboardMobile'));

const Company = React.lazy(() => import('./appFiles/components/SideMenuEntries/Company/Company'));
const CompanyHome = React.lazy(() => import('./appFiles/components/SideMenuEntries/Company/CompanyHome'));

const Users = React.lazy(() => import('./appFiles/components/SideMenuEntries/Company/Users'));
const PageNotFound = React.lazy(() => import('./appFiles/components/OthersGlobals/PageNotFound'));
const EmptyPage = React.lazy(() => import('./appFiles/components/OthersGlobals/EmptyPage'));

const EditOrganisationalChart = React.lazy(() => import('./appFiles/components/OnboardingEntries/CompanyOrganizationalChart/EditComOrgChart'));
const RoleInformation = React.lazy(() => import('./appFiles/components/OnboardingEntries/RoleInformation'));
const MyProfile = React.lazy(() => import('./appFiles/components/TopBar/MyProfile/MyProfile'));

const ProcessInformation = React.lazy(() => import('./appFiles/components/SideMenuEntries/BlueprintsComps/ProcessesTab/ProcessInfo'));
const CompanySettings = React.lazy(() => import('./appFiles/components/TopBar/CompanySettings/CompanySettings'));
const BillingInfo = React.lazy(() => import('./appFiles/components/SideMenuEntries/BillingInfo/BillingInfo'));
const Success = React.lazy(() => import('./appFiles/components/SideMenuEntries/BillingInfo/Success'));
const Cancel = React.lazy(() => import('./appFiles/components/SideMenuEntries/BillingInfo/Cancel'));
const Checkout = React.lazy(() => import('./appFiles/components/SideMenuEntries/CheckoutPage/CheckoutPage'));
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const BeginModule = React.lazy(() => import('./appFiles/components/SideMenuEntries/ModulesComp/BeginModule'));
const ModuleLists = React.lazy(() => import('./appFiles/components/SideMenuEntries/ModulesComp/ModuleLists'));
const Flowhero = React.lazy(() => import('./appFiles/components/Home/Pages/Flowhero'));
const CustomBasketball = React.lazy(()=> import('./appFiles/components/Custom/CustomBasketball'));

const App = () => {
	const dispatch = useDispatch();

	const { authenticatedUser } = useSelector(store => store.authReducer);
	const { authenticated } = useSelector(store => store.sessionReducer);
	const { onBoardingCompleted, companyData } = useSelector(store => store.flowReducer);

	const { onBoardingVisible, isOnboarding } = useSelector(store => store.modalsReducer);

	const [showOnboardingModals, setShowOnboardingModals] = useState(false);
	const [routes, setRoutes] = useState(null);
	const [showTop, setShowTop] = useState(true);

	const settingRoutes = routes => setRoutes(routes);

	const handleNavigation = e => {
		// const window = e.currentTarget;
		// if (window.scrollY == 0) {
		// 	setShowTop(true);
		// } else {
		// 	setShowTop(false);
		// }
	};

	useEffect(() => {
		window.addEventListener('scroll', e => handleNavigation(e));
	}, []);

	useEffect(() => {
		const showPrivateRoutes = authenticated && companyData?.onboarding_status == true && onBoardingCompleted;
		const isSingleApp = authenticatedUser.isSingleApp && isMobile;

		let routes = (
			<Routes>
				<Route path="/pageNotFound" element={<PageNotFound />} />

				<Route path="/stripe/webhooks" element={<EmptyPage />} />
				<Route path="/login" element={<Login />} />
				<Route path="/home" element={<Home />} />
				<Route path="/register" element={<Register />} />
				<Route path="/user" element={<RegisterUser />} />
				<Route path="/legal" element={<Legal />} />
				<Route path="/appointment" element={<Appointment />} />
				<Route path="/version" element={<div>{`Version ${process.env.REACT_APP_VERSION}`}</div>} />
				<Route path="/invitations/accept/:hash" element={<AcceptInvitation />} />
				<Route path="/reset-password" element={<ResetPassword />} />
				<Route path="/flowhero/home/:slug" element={<CompanyHome />} />
				<Route path="/flowhero/sports" element={<CompanyHome />} />

				{authenticated && <Route path="/role" element={<RoleInformation />} />}
				{authenticated && <Route path="/chart" element={<EditOrganisationalChart />} />}
				{authenticated && <Route path="/workflow" element={<ProcessInformation />} />}
				{authenticated && <Route path="/task/:hash" element={<Dashboard />} />}

				{authenticated && <Route path="/company" element={<Company />} />}
				{authenticated && <Route path="/users" element={<Users />} />}
				{authenticated && <Route path="/my-profile" element={<MyProfile />} />}
				{authenticated && <Route path="/user-profile" element={<MyProfile />} />}
				{authenticated && <Route path="/company-settings" element={<CompanySettings />} />}

				{showPrivateRoutes && <Route path="/billing-info" element={<BillingInfo />} />}
				{showPrivateRoutes && <Route path="/payments/:hash" element={<BillingInfo />} />}
				{showPrivateRoutes && <Route path="/cancel" element={<Cancel />} />}
				{showPrivateRoutes && <Route path="/success" element={<Success />} />}
				{showPrivateRoutes && (
					<Route
						path="/checkout"
						element={
							<Elements stripe={stripePromise}>
								{' '}
								<Checkout hasCloseIcon />
							</Elements>
						}
					/>
				)}

				{authenticated && <Route path="/transactions/:transactionID" element={<Dashboard />} />}
				{authenticated && <Route path="/transactions/:transactionID/:newStatus" element={<Dashboard />} />}

				{authenticated && <Route path="/modules/:moduleID" element={<BeginModule />} />}
				{authenticated && <Route path="/begin-module" element={<BeginModule />} />}
				{authenticated && <Route path="/begin-module/:paymentID" element={<BeginModule />} />}
				{authenticated && <Route path="/begin-mobile/:paymentID" element={<DashboardMobile />} />}
				{authenticated && <Route path="/wallet/:walletID" element={isSingleApp ? <DashboardMobile /> : <Dashboard />} />}

				{authenticated && <Route path="/module-lists" element={<ModuleLists />} />}
				{showPrivateRoutes && <Route path="/auth/password/reset" element={<ChangePassword />} />}

				{authenticated && <Route path="/dashboard" element={isSingleApp ? <DashboardMobile /> : <Dashboard />} />}
				{authenticated && <Route path="/mobile" element={<DashboardMobile />} />}

				{authenticated && onBoardingCompleted ? <Route path="/" element={isSingleApp ? <DashboardMobile /> : <Dashboard />} /> : <Route path="/" element={<Home />} />}
				{authenticated && onBoardingCompleted ? <Route path="/flowhero" element={isSingleApp ? <DashboardMobile /> : <Dashboard />} /> : <Route path="/flowhero" element={<Flowhero />} />}

				<Route path="*" element={<Navigate to="/" />} />
			</Routes>
		);

		settingRoutes(routes);
	}, [authenticated, authenticatedUser, companyData?.onboarding_status, onBoardingCompleted]);

	const getModalsVisible = useCallback(() => authenticatedUser.id?.length > 0 && authenticatedUser.is_admin && onBoardingVisible && isOnboarding === true && onBoardingCompleted === false, [authenticatedUser, onBoardingVisible, isOnboarding, onBoardingCompleted]);

	useEffect(() => {
		(() => {
			setShowOnboardingModals(getModalsVisible());
		})();
	}, [isOnboarding, onBoardingCompleted, authenticatedUser]);

	const onboarding = useMemo(
		() => (
			<TheModulesContext>
				<TheProcessContext>
					<TheProcessStepContext>
						<TheBlueprintComponentsContext>
							<OnboardingModals onClose={() => dispatch(setOnBoardingVisible(false))} />
						</TheBlueprintComponentsContext>
					</TheProcessStepContext>
				</TheProcessContext>
			</TheModulesContext>
		),
		[showOnboardingModals]
	);

	return (
		<Elements stripe={stripePromise}>
			<TheProcessTaskContext>
				<TheBeginProcessContext>
					<Layout>
						{showTop && <Top />}
						<Divider >
							<Right>
								{routes}
								{onBoardingVisible && onboarding}
								<InfoModal />
								<ErrorsModal />
							</Right>
						</Divider>
					</Layout>
				</TheBeginProcessContext>
			</TheProcessTaskContext>
		</Elements>
	);
};

export default App;
